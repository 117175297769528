<template>
    <div class="min-h-screen relative">

        <Navigation />
        <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

        <div class="min-h-screen w-full container-default-1209px w-container">

            <!-- <p class="my-3">
            My Courses
                </p>  -->

            <!-- <div class="w-full my-4 h-auto">
            <section class="w-full h-full flex items-center mx-auto">
                <div class="py-1 bg-gray-100 border border-gray-300 bg-opacity-70 flex items-center w-full gap-4 md:px-4  rounded-lg relative h-auto">
                    <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='Courses'}" @click="this.selectedcontent='Courses'">My Courses</button>
                    <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='profile'}" @click="this.selectedcontent='profile'">My Profile</button>
                    <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='community'}" @click="this.selectedcontent='community'">Community</button> 
                    <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='Settings'}" @click="this.selectedcontent='Settings'">Payment info</button> 
                </div>
            </section>
        </div> -->

            <!-- User Profile Details -->

            <section id="UserProfile" v-if="profileInfo"
                class="w-full py-6 mt-10 flex flex-col md:flex-row gap-6 items-center justify-center">
                <!-- firsthalf -->
                <div class="bg-white shadow-md rounded-lg p-6 text-center w-80 right-10">
                    <div class="flex flex-col items-center">
                        <div v-if="profileInfo.data.profile == ''">
                            <p
                                class="border rounded-full w-24 h-24 flex items-center justify-center text-4xl text-black capitalize bg-gray-200 font-semibold">
                                {{ profileInfo.data.user.slice(0, 1) }}</p>
                        </div>
                        <div v-else>
                            <img :src="profileInfo.data.profile" alt="Profile Picture" class="w-24 h-24 rounded-full">
                        </div>
                    </div>
                    <div class="mt-4">
                        <p class="text-lg font-bold capitalize">{{ profileInfo.data.user }}</p>
                        <p class="text-sm text-gray-500">{{ profileInfo.data.designation }}</p>
                    </div>
                    <div class="mt-4 flex justify-center gap-5">
                        <a :href="profileInfo.data.github" target="_blank" title="GitHub">
                            <img src="../assets/githubprofile.svg" class="h-6 w-6 cursor-pointer" alt="GitHub">
                        </a>
                        <a :href="profileInfo.data.twitter" target="_blank" title="Twitter"
                            class="text-gray-500 hover:text-blue-400 py-1">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                class="w-6 h-6" viewBox="0 0 24 24">
                                <path
                                    d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">
                                </path>
                            </svg>
                        </a>
                        <a :href="profileInfo.data.instagram" target="_blank" title="Instagram"
                            class="text-gray-500 hover:text-pink-600">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                            </svg>
                        </a>
                        <a :href="profileInfo.data.linkedin" target="_blank" title="LinkedIn"
                            class="text-gray-500 hover:text-blue-500">
                            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="0" class="w-6 h-6" viewBox="0 0 24 24">
                                <path stroke="none"
                                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
                                </path>
                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                            </svg>
                        </a>
                    </div>
                </div>


                <!-- secound half -->
                <div class="h-3/4 w-full flex justify-center bg-white shadow-md rounded-2xl p-4 "
                    style="height: 450px !important; width: 750px !important;">
                    <div class="h-1/2 flex flex-col  md:w-4/6">
                        <div class="flex justify-between p-4">
                            <div class="text-black text-xl right-4 font-bold" style="color: #0085DB !important;">
                                Personal Details
                            </div>
                            <div @click="ProfileEdit" class="mt-2 relative">
                                <button class="primary-color px-5 rounded border"
                                    style="color: white !important; background-color: #0F172A !important;">Edit</button>
                            </div>
                        </div>
                        <div class="flex flex-col gap-5  justify-center">


                            <div class="overflow-x-auto">
                                <table class="min-w-full bg-white  border-gray-300">
                                    <tbody>
                                        <tr>
                                            <td class="py-2 px-4 font-medium text-gray-800">Full Name</td>
                                            <td class="py-2 px-4  text-gray-600">: {{ profileInfo.data.user }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="py-2 px-4  font-medium text-gray-800">Email</td>
                                            <td class="py-2 px-4  text-gray-600">: {{ profileInfo.data.Email }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="py-2 px-4  font-medium text-gray-800">Mobile</td>
                                            <td class="py-2 px-4  text-gray-600">: {{ profileInfo.data.mobile }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="py-2 px-4  font-medium text-gray-800">Address</td>
                                            <td class="py-2 px-4  text-gray-600">: {{ profileInfo.data.address }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="py-2 px-4  font-medium text-gray-800">Descriptions</td>
                                            <td class="py-2 px-4  text-gray-600">
                                                : {{ profileInfo.data.description.slice(0, 180) }}
                                                <p v-if="profileInfo.data.description.length > 180">...More</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-4 flex justify-center rounded-xl">
                                <router-link
                                    :to="{ name: 'password', params: { keyword: 'change', keyval: userInfo.slug_id } }"
                                    class="flex items-center gap-2 p-2 text-white bg-[#0085DB] hover:bg-[#0071C2] transition"
                                    style="width: 250px; height: 36px; border-radius: 5px;">
                                    <img src="../assets/new/hugeicons_reset-password.png" alt="Reset Password"
                                        class="h-5 w-5" />
                                    <span class="text-sm font-medium px-8 py-2"
                                        style="color: white!important; background-color: #0085DB !important;">Change
                                        Password</span>
                                </router-link>
                            </div>

                        </div>

                    </div>

                </div>

            </section>

        </div>

        <!-- popUp page -->
        <div v-if="popUp && updateDetails != null" class="fixed w-full h-full bg-black bg-opacity-75 top-0"
            style="z-index:5000">
            <div class="w-full md:w-5/6 lg:w-4/6 xl:w-3/6 2xl:w-2/6 h-full mx-auto flex flex-col justify-center ">
                <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">

                    <div class="flex justify-between ">
                        <h2 class="text-2xl font-bold mb-6">Edit Details</h2>
                        <img class='h-5 w-5 md:h-8 md:w-8' @click="popUp = false" src="@/assets/closebutton.svg" />
                    </div>
                    <form>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Destination *</label>
                            <input type="text" v-model="updateDetails.designation"
                                class="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Student"
                                required>
                        </div>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Mobile *</label>
                            <input type="text" v-model="updateDetails.mobile"
                                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                placeholder="54684646545" required>
                        </div>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Address *</label>
                            <input type="text" v-model="updateDetails.address"
                                class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                placeholder="21/2, Interting street, Common city - 365986" required>
                        </div>
                        <!-- <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">Description *</label>
                            <textarea class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                placeholder="My name is this you can ask me what event it sear tire"
                                required></textarea>
                        </div> -->
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">GitHub *</label>
                            <input type="text" v-model="updateDetails.github"
                                class="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Link"
                                required>
                        </div>
                        <div class="mb-4">
                            <label class="block text-sm font-medium text-gray-700">LinkedIn *</label>
                            <input type="text" v-model="updateDetails.linkedin"
                                class="mt-1 block w-full p-2 border border-gray-300 rounded-md" placeholder="Link"
                                required>
                        </div>
                        <div class="flex justify-center ">
                            <button @click="SubmitProfile()" type="submit"
                                class="w-full bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                style="color: white!important; background-color: #0085DB !important; width: 130px; height: 38px;">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex";
import Navigation from "@/components/Navigation.vue"
export default {
    data() {
        return {
            profileInfo: null,
            popUp: false,
            updateDetails: null,
            selectedFile: null,
            tnxDetails: "",
            profile: false,
            messages: {
                text: "",
                type: 'null',
            },
            selectedcontent: "Courses",
            courseDetails: null,
            boughtcourse: null,

        }
    },

    components: {
        Navigation,
        FlashMessage

    },

    // mounted(){
    //     this.selectedcontent = userId;
    // },

    computed: {
        ...mapGetters(["userInfo"]),
        // userId() {
        //     return this.$route.query.name;
        // },
    },

    async created() {

        await this.openProcess()
        let tempvar_courselist = []
        await axios.get(`${process.env.VUE_APP_API}/courses/?format=json`)
            .then(response => this.courseDetails = response.data.data)
        await axios.get(`${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.boughtcourse = response.data.data
            })
        this.boughtcourse.forEach(element => {
            tempvar_courselist.push(element.course_id)
        });

        this.courseDetails = this.courseDetails.filter(course => {
            if (tempvar_courselist.includes(course.id)) {
                return course
            }
        })

    },

    methods: {
        async imageHandles() {
            this.profile = false;
            let headerprofile = new FormData();
            headerprofile.append("id", this.userInfo.user.id)
            headerprofile.append("profile", document.querySelector("#image_uploads").files[0])

            await axios.put(`${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`, headerprofile)
                .then(response => {

                    if (response.data.message == 'profile Data Updated successfully') {
                        this.messages = {
                            text: "Profile Uploaded successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
                .catch(error => {

                    this.messages = {
                        text: error.response.data.error,
                        type: 'error',
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.openProcess()
            }, 3000);

        },

        onFileChanged(event) {

            this.selectedFile = event.target.files[0]

        },

        async onUpload() {
            // upload file
            await axios.post('my-domain.com/file-upload', this.selectedFile)
        },

        async openProcess() {
            axios.get(`${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`)
                .then(res => {
                    console.log(res)
                    this.tnxDetails = res.data
                })
            let token = this.userInfo.token_key;
            let slug_id = this.userInfo.slug_id;

            await axios.get(`${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`)
                .then(response => (this.profileInfo = response.data))
        },

        async ProfileEdit() {
            let details_id = this.profileInfo.data.id;
            let token = this.userInfo.token_key;
            this.popUp = true

            await axios.get(`${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`)
                .then(response => this.updateDetails = response.data)

        },

        async SubmitProfile() {
            let details_id = this.profileInfo.data.id;
            let token = this.userInfo.token_key;
            let update = this.updateDetails
            this.popUp = false
            // console.log("updateDetails",updateDetails)

            await axios.put(`${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`, update)
                .then(response => {
                    if (response.data.message == 'Profile updated successfully') {
                        this.messages = {
                            text: "Profile Uploaded successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.openProcess()
            }, 3000);

        }
    },

}
</script>

<style scoped>
#image_uploads {
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px black;
    width: 350px;
    outline: none;
}
</style>
