import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/style.css";
import VCalendar from 'v-calendar';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUser,
    faUsers,
    faBook,
    faClock,
    faMap,
    faVideo,
    faBars,
    faCheckCircle,
    faTimesCircle,
    faPlus,
    faMinus,
    faGraduationCap,
    faTrashAlt,
    faAngleDown,
    faAngleRight,
    faSearch,
    faCommentDots,
    faCheckSquare,
    faThumbsUp,
    faReply,
    faDownload,
    faTimes,
    faEnvelope,
    faSignOutAlt



} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';

// import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import hljs from 'highlight.js';
// import Prism from 'prismjs';

// markdown preview
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import '../catalyft-ui-templates/css/normalize.css'
import '../catalyft-ui-templates/css/webflow.css'
import '../catalyft-ui-templates/css/catalyft-17bdd2.webflow.css'
// import '../catalyft-ui-templates/js/jquery-3.5.1.min.js'
// import '../catalyft-ui-templates/js/webflow.js'

VMdPreview.use(githubTheme, {
    Hljs: hljs,
});

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";




VueMarkdownEditor.use(githubTheme, { Hljs: hljs, });

VueMarkdownEditor.lang.use('en-US', enUS);

library.add(faUser, faBook, faUsers, faClock, faMap, faVideo, faBars, faCheckCircle, faTimesCircle, faPlus, faMinus, faGraduationCap, faTrashAlt, faAngleDown, faAngleRight, faSearch, faCommentDots, faCheckSquare, faThumbsUp, faReply, faDownload, faTimes, faSignOutAlt);
const app = createApp(App)

//Base setup for vue
app.use(store).use(router).use(VCalendar, {})
app.component('font-awesome-icon', FontAwesomeIcon)
app.component("QuillEditor", QuillEditor);
app.use(VueMarkdownEditor);
app.use(VMdPreview);
app.mount('#app')

app.config.globalError = {
    loginError: null
}

app.config.devtools = true;
// We can set global read-only variable instance with the below method
app.config.globalProperties.apiHost = `${process.env.VUE_APP_API}`