<template>
    <!-- eslint-plugin-vue -->
    <!-- <Navigation active="login" /> -->
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="min-h-screen flex flex-col lg:flex-row">
        <div class="w-full lg:w-4/5 h-72 lg:h-full overflow-hidden" style="">
            <img src="../assets/new/login.png" alt="" class="max-w-full max-h-full object-contain">
        </div>
        <div
            class="container w-full 2xl:w-3/4 px-5 py-5 lg:py-24 mx-auto flex flex-col-reverse lg:flex-row justify-left items-center md:mb-36">

            <div id="signIn" class="w-full lg:w-3/5 md:m-10">
                <form @submit.prevent="handleSubmit">
                    <h2 class="title">Sign in</h2>
                    <h2 class="subtitle text-sm">Please fill your information below</h2>
                    <div class="mb-4">
                        <label class="leading-7 text-sm text-gray-600">Email</label>
                        <input @change="emptyspaceremove" ref="input" type="email" id="email" name="email"
                            class="w-full bg-white rounded border border-gray-300 focus:primary-border focus:ring-2 focus:primary-border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            placeholder="Email" required />
                    </div>

                    <div class="mb-4">
                        <label class="leading-7 text-sm text-gray-600">Password</label>
                        <input type="password" id="password" name="password"
                            class="w-full bg-white rounded border border-gray-300 focus:primary-border focus:ring-2 focus:primary-border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                            placeholder="Password" required />
                        <img v-if="passwordType == 'password'" src="../assets/eyeslash.svg"
                            @click="hideShow('password')" alt="" class=" cursor-pointer inline-block z-50"
                            style="margin-left: -30px; ">
                        <img v-else src="../assets/eye.svg" @click="hideShow('password')" alt=""
                            class=" cursor-pointer inline-block z-50" style="margin-left: -30px; ">
                    </div>
                    <h1 v-if="loginError" class="text-red-700 font-semibold p-2 text-base">
                        {{ loginError }}
                    </h1>
                    <h1 v-if="credentialsError" class="text-red-700 font-semibold p-2 text-base">
                        {{ credentialsError }}
                    </h1>

                    <button type="button" @click="forgetpassWord()"
                        class="text-left px-2 py-1 text-sm text-sky-600 hover:text-red-500">Forgot Password?</button>

                    <div class="flex justify-end mt-4 bottom-2">
                        <button v-if="!loading" type="submit"
                            class="bg-black text-white py-2 px-6 text-sm rounded-lg hover:bg-blue-700 transition relative" style="background-color: #0F172A !important; ">
                            Login
                        </button>
                        <button v-else type="submit" class="bg-blue-600 text-white py-2 px-6 rounded-lg">
                            <img class="mx-auto w-12 h-5" src="../assets/Pulse.gif" alt="">
                        </button>
                        <img src="@/assets/new/arrow.png" alt="">
                    </div>
                </form>
            </div>
            <!-- <div id="forgetPassword" class="hidden w-full lg:w-3/5 lg:mt-0">
                <form @submit.prevent="resetMail()"
                    class="flex justify-center items-center min-h-screen bg-white px-4">
                    <div id="forgetPasswordEl"
                        class="relative w-full max-w-lg border bg-white p-6 lg:p-8 rounded-xl shadow-lg flex flex-col justify-center">
                        <h1 class="text-lg lg:text-2xl font-semibold text-gray-800 mb-4">Reset Your Password</h1>

                        <button type="button" @click="backtologin"
                            class="absolute top-4 right-4 text-sky-600 hover:text-sky-500 text-sm font-medium">
                            Back to Login
                        </button>

                        <div class="mb-5">
                            <label for="resetMailID" class="block text-sm font-medium text-gray-600 mb-2">Email
                                Address</label>
                            <input type="email" id="resetMailID"
                                class="block w-full px-4 py-2 text-sm border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-sky-400 focus:border-sky-500 focus:outline-none"
                                placeholder="Enter your email" required />
                        </div>

                        <div class="mt-4">
                            <button v-if="!loading" type="submit"
                                class="w-full bg-sky-600 hover:bg-sky-700 text-white font-medium py-2.5 rounded-lg transition shadow-md">
                                Send Reset Link
                            </button>
                            <button v-else type="submit"
                                class="w-full button-primary header-button w-button text-white font-medium py-2.5 rounded-lg flex justify-center items-center shadow-md">
                                <img class="h-5" src="../assets/Pulse.gif" alt="Loading..." />
                            </button>
                        </div>

                        <p id="alert" class="hidden text-red-500 text-center text-sm font-medium mt-4">
                            Enter a valid email address
                        </p>
                        <p id="mailDone" class="hidden text-green-600 text-center text-sm font-medium mt-4">
                            Mail sent to the entered email address
                        </p>
                    </div>
                </form>
            </div> -->

            <div id="forgetPassword" class="hidden w-full lg:w-1/2 mt-5 lg:mt-0 ">
                <form @submit.prevent="resetMail()">
                    <div id="forgetPasswordEl"
                        class="relative p-6 flex flex-col w-full  lg:w-5/6  justify-center ">
                        <h1 class="text-md lg:text-lg py-1 mb-0">Reset Your Password</h1> <button type='button'
                            @click="backtologin"
                            class="absolute top-6 right-6 text-sky-700  hover:text-sky-500 py-1">Back to Login</button>
                        <label>Email Id</label>
                        <input type='email' id='resetMailID'
                            class="border leading-7 text-sm text-gray-600 border-gray-300 placeh" placeholder="Enter your email" required />
                        <button v-if="!loading" type="submit"
                            class="button-primary header-button w-button my-4">Send</button>
                        <button v-else type="submit" class=" button-primary header-button w-button my-4">
                            <img class="mx-auto h-5" src="../assets/Pulse.gif" alt="">
                        </button>
                        <span id='alert' class='hidden text-red-500 rounded text-center text-sm font-semibold'>Enter
                            Vaild Mail Id</span>

                        <span id='mailDone' class='hidden text-green-700 rounded text-center text-sm font-semibold'>Mail
                            Sent to Entered email id</span>
                    </div>
                </form>
            </div>


        </div>

    </div>

    <div class="fixed top-0 h-screen w-full bg-black bg-opacity-70 flex justify-center items-center"
        v-if="varificationPopup == false" style="z-index:5000">
        <div id="resendMail"
            class="relative w-full px-2 h-1/2 lg:w-1/2 lg:h-1/2 2xl:w-1/3 2xl:h-1/3 bg-white flex flex-col justify-center items-center gap-4 rounded text-base  md:text-xl">
            <div class="text-sm xl:text-base"> Your e-mail address is still not verified, please check your mail and
                activate your account</div>
            <p class="text-sm xl:text-base">If you lost your Activation mail link please use this <span
                    class="text-teal-600"> Resend </span> option</p>
            <button class="gradient-btn" @click="resendActivationMail">Resend</button>
            <img src="../assets/closebutton.svg" class="w-8 h-8 absolute top-10 right-10 cursor-pointer" alt=""
                @click="varificationPopup = true" />
        </div>
    </div>


</template>

<script>
import Navigation from "@/components/Navigation.vue";
import FlashMessage from "@/components/flashMessage.vue";
import axios from "axios"
import {
    mapActions,
    mapGetters
} from "vuex";
export default {
    data: function () {
        return {
            login: {
                username: "",
                password: ""
            },
            loading: false,
            passwordType: "password",
            varificationPopup: true,
            credentialsError: "",
            messages: {
                text: "",
                type: 'null',
            },

        };
    },
    components: {
        Navigation,
        FlashMessage,
    },
    computed: mapGetters(["userInfo", "loginError"]),
    mounted() {
        this.$refs.input.focus()
        document.querySelector("#mailDone").onclick = function () {
            this.$router.go()

        }
    },
    methods: {
        ...mapActions(["loginUser", "subcription", "instructordetails"]),
        hideShow(id) {

            const passwordField = document.querySelector(`#${id}`)
            passwordField.getAttribute('type') === 'password' ? passwordField.setAttribute('type', 'text') : passwordField.setAttribute('type', 'password')
            this.passwordType = document.querySelector(`#${id}`).getAttribute('type')
        },
        handleSubmit: async function (e) {
            let is_activation
            this.loading = true;

            let mailId = {
                email: e.target.elements.email.value.toLowerCase()
            }
            // console.log(mailId,'emailis')
            await axios.post(`${process.env.VUE_APP_API}/email_verification/`, mailId)
                .then(response => {
                    if (response.data.message == "Email already verified") {
                        is_activation = true
                        // console.log("Email already verified")
                    } else if (response.data.message == "Invalid Email credentials") {
                        // console.log(" invalid Email credentials") 
                        this.credentialsError = "Invalid credentials"
                        setTimeout(() => {
                            this.credentialsError = ""
                            this.loading = false;
                        }, 2000);
                    } else {
                        this.varificationPopup = false
                        is_activation = false
                        this.loading = false;
                        //    console.log("Email not verified")

                    }
                })
                .catch(error => {
                    console.log(error)
                })

            if (is_activation) {
                e.preventDefault();
                let data = {
                    username: (e.target.elements.email.value).toLowerCase(),
                    password: e.target.elements.password.value,
                }
                // console.log(data,'user details')
                await this.loginUser(data);
                setTimeout(() => {
                    let subdata = this.userInfo;
                    this.subcription(subdata);
                    this.instructordetails(subdata)
                    this.loading = false;
                }, 3000)

                setTimeout(() => {
                    if (this.userInfo.user.permission == 'student') {
                        this.$router.push({
                            name: 'Mycourse'
                        });
                        this.loading = false;
                    } else if (this.userInfo.user.permission == 'instructor') {

                        this.$router.push({
                            name: 'Instructorcourse-list'
                        })
                    } else {
                        this.$router.push({
                            name: 'organizationDashboard',
                            params: {
                                slug_id: this.userInfo.slug_id
                            }
                        });
                        this.loading = false;
                    }

                }, 3000)

            } else {
                console.log("MailID have a issue")
            }

        },

        resendActivationMail() {
            // console.log(document.querySelector("#email").value)
            this.varificationPopup = true
            let mailId = {
                email: document.querySelector("#email").value
            }
            axios.post(`${process.env.VUE_APP_API}/regenerate_token/`, mailId)
                .then(response => {
                    // console.log(response.data.message)
                    if (response.data.message == "Email send") {

                        this.messages = {
                            text: "Email send",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                    }, 2500)

                })
                .catch(error => {
                    console.log(error)
                })

        },

        forgetpassWord() {
            document.getElementById("signIn").style.display = "none";
            document.getElementById("forgetPassword").style.display = "block";
        },

        backtologin() {
            document.getElementById("forgetPassword").style.display = "none";
            document.getElementById("signIn").style.display = "block";
        },

        resetMail() {
            this.loading = true
            document.querySelector("#resetMailID").value;
            const resetMail = {
                "email": document.querySelector("#resetMailID").value
            }

            axios.post(`${process.env.VUE_APP_API}/api/password_reset/`, resetMail)
                .then(response => {
                    if (response.data.status == "OK") {
                        this.loading = false
                        document.querySelector("#resetMailID").value = ""
                        document.querySelector("#mailDone").classList.replace("hidden", "block")
                        setTimeout(() => {
                            document.querySelector("#mailDone").classList.replace("block", "hidden")
                            document.getElementById("signIn").style.display = "block";
                            document.getElementById("forgetPassword").style.display = "none";
                        }, 3500)
                    }
                })

                .catch(error => {
                    document.querySelector("#resetMailID").value = ""
                    document.querySelector("#alert").classList.replace("hidden", "block")
                    setTimeout(() => {
                        document.querySelector("#alert").classList.replace("block", "hidden")
                        this.loading = false

                    }, 2500)
                })
        },
        emptyspaceremove(el) {
            el.target.value = el.target.value.trim()
        }

    }

};
</script>

<style scoped>
.circle-wrap {

    overflow: hidden;
    -webkit-clip-path: circle(53% at 53% 28%);
    clip-path: circle(46% at 70% 36%);
    position: absolute;

}

.subtitle {
    color: #ADADAD !important;
}
</style>
