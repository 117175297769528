<template>

  <div v-if="is_popupview" style="background-color: white !important;" class="bg-white rounded-2xl"> 
    
    <div class="mx-auto relative m-4 p-8 rounded-md">
      <div
        class="w-full lg:p-4 mx-auto lg:w-12/12 relative text-center text-xl font-semibold"
      >
        {{ dataval.quiz.name }}
        <div class="absolute right-8 top-8">
          <div
            @click="quiz_data()"
            class="mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white"
          >
            <div class="text-sm text-gray-700 hover:text-teal-500" title="back">
              back
            </div>
            <div class="flex items-center">
              <img
                src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-screen overflow-y-auto pb-10">
      <div
        v-for="(el, index) in dataval.coursequizusersanswer_set"
        :key="el"
        class="border max-w-4xl mx-auto mt-4 text-start p-10 rounded-3xl"
      >
        <p class="py-2 font-bold">{{ index + 1 }}) {{ el.question.question_text }}</p>

        <div v-for="(al, index) in el.question.coursequizanswer_set" :key="al">
          <p class="" :class="[al.is_correct == true ? 'text-blue-900 font-bold' : '']">
            {{ index + 1 }}) {{ al.answer_text }} <br />
          </p>
        </div>

        <div class="px-6 mt-6 py-6 rounded-2xl border my-2">
          <h1
            class="px-5 lg:px-5 py-2 lg:py-4 items-center border-gray-200 bg-gray-100 text-xs font-semibold rounded-2xl text-gray-600 tracking-wider"
          >
            Your Answer :
          </h1>
          <p
            v-if="el.answer"
            class="px-4 text-sm font-bold"
            :class="[
              el.answer.is_correct == true ? 'text-green-700' : 'text-red-400',
            ]"
          >
            {{ el.answer.answer_text }}
          </p>
          <p v-else class="px-4 text-sm font-bold">Not attend</p>
        </div>
      </div>
    </div>

              <!-- <button
                v-if="currentQuestion > 0"
                class="gradient-btn"
                @click="pvsQt"
              >
                Previous
              </button>
              <button
                v-if="currentQuestion + 1 < this.questions.length"
                class="gradient-btn"
                @click="nxtQt"
              >
                Next
              </button> -->

  </div>

  <div v-else class="flex flex-wrap flex-row gap-4 justify-center bg-white h-full">
    <div
      class="overflow-y-scroll h-auto flex justify-center mt-6 md:mt-0 w-full"
      style="scrollbar-color: gray white"
    >
      <div class="w-11/12 p-0 md:p-4 flex justify-center">
        <div
          class="mt-4 inline-block shadow-lg rounded-3xl overflow-x-auto md:overflow-y-scroll h-full w-full"
        >
          <table class="leading-normal w-full">
            <thead>
              <tr>
                <th
                  class="px-2 lg:px-5 py-2 lg:py-8 items-center border-b border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                >
                  Sl.No
                </th>
                <th
                  class="px-2 lg:px-5 py-2 lg:py-8 border-b border-l border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                >
                  Assesment
                </th>
                <th
                  class="px-2 lg:px-5 py-2 lg:py-8 border-b border-l border-r border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                >
                  Score
                </th>
                <th
                  class="px-2 lg:px-5 py-2 lg:py-8 border-b border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody v-if="this.published">
              <tr v-for="(el, index) in this.published" :key="el">
                <td
                  class="px-2 lg:px-5 py-4 lg:py-8 border-b border-gray-200 bg-white text-xs lg:text-sm"
                >
                  <div class="flex items-center justify-center">
                    <div class="ml-3">
                      <p class="text-gray-900 whitespace-no-wrap">
                        {{ index + 1 }}
                      </p>
                    </div>
                  </div>
                </td>
                <td
                  class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm"
                >
                  <p
                    v-if="el.quiz"
                    class="text-center text-gray-900 whitespace-no-wrap"
                  >
                    {{ el.quiz.name }}
                  </p>
                  <p
                    v-else
                    class="text-center text-gray-900 whitespace-no-wrap"
                  >
                    empty
                  </p>
                </td>
                <td
                  v-if="el.quiz.quiztakers_set.score == 0"
                  class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center"
                >
                  -
                </td>
                <td
                  v-else
                  class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center"
                >
                  {{ el.quiz.quiztakers_set.score }}
                </td>
                <td
                  class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center"
                >
                  <div
                    v-if="
                      el.quiz.qualify_certificate == true &&
                      el.quiz.quiztakers_set.completed == true
                    "
                  >
                    <div
                      class="mx-10 mb-1 cursor-pointer rounded-lg flex justify-center gap-2 items-center py-3 bgblue"
                      @click="quizPreview(el.quiz.quiztakers_set.id)"
                    >
                      preview
                    </div>
                  </div>
                  <div
                    class="mb-1 mx-10 cursor-pointer rounded-lg font-bold flex justify-center gap-2 py-3 hover:text-teal-500 text-black" style="border: 1px solid #01B0F9;"
                    v-else-if="
                      el.quiz.qualify_certificate == true &&
                      el.quiz.quiztakers_set.completed != true
                    "
                  >
                    <router-link
                      :to="{
                        name: 'Quiztest',
                        params: { quiz_id: el.quiz.id },
                      }"
                    >
                      start
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td
                  class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm"
                >
                  empty
                </td>
                <td
                  class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm"
                >
                  empty
                </td>
                <td
                  class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center"
                >
                  empty
                </td>
                <td
                  class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center"
                >
                  empty
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import QuizPreview from "@/components/studentquizzespreview.vue";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Quiz",
  computed: mapGetters([
    "isAuthenticated",
    "userInfo",
    "profileInfo",
    "cartInfo",
    "courseTrackerInfo",
    "subscriptionInfo",
  ]),

  data() {
    return {
      dataval: "",
      is_popupview: false,
      published: "",
      quiz_id: 0,
      currentQuestion: 0,
      score: 0,
      percentage: 0,
      countDown: 30,
      timer: null,
      startindex: "",
      startQuiz: false,
      completed: false,
      message: "Congrats, Your are Completed Course Quiz.",
      HeaderVal: "",
      list_data: [],
      messages: {
        text: "",
        type: "null",
      },
      quiz_details: "",
    };
  },
  components: {
    QuizPreview,
    FlashMessage,
  },
  props: ["coursrid", "index_data"],
  async created() {
    console.log(this.coursrid);
    // console.log("quiz created")
    // console.table(this.quizData.quiz)
    // console.table("unit information: ", this.id)
    // console.log("this user: ", this.userInfo, this.isAuthenticated)
    // this.questions = this.quizData.quiz
    await this.getQuiz();
  },
  mounted() {
    // console.log(this.id);
  },
  methods: {
    quiz_data() {
      this.is_popupview = false;
    },
    async quizPreview(id) {
      this.is_popupview = true;

      let token = this.userInfo.token_key;
      await axios
        .get(
          `${process.env.VUE_APP_API}/quiztaker__quizzes_pk/${id}/?Key=${token}`
        )
        .then((response) => {
          this.dataval = response.data.data;
          console.log("response::", response.data.data);
        });
    },
    async getQuiz() {
      if (this.userInfo) {
        let token = this.userInfo.token_key;
        console.log(
          `${process.env.VUE_APP_API}/course_quizzes_detail/${this.coursrid}/?Key=${token}`
        );
        await axios
          .get(
            `${process.env.VUE_APP_API}/course_quizzes_detail/${this.coursrid}/?Key=${token}`
          )
          .then((response) => {
            if (response.data.error == "No Quiz found for this course") {
              console.log("Event Faild");
              this.messages = {
                text: "No Quiz found for this course",
                type: "error",
              };
            } else {
              this.published = response.data.data;
              // this.published = response.data.data[0].quiz.qualify_certificate
              this.quiz_details = response.data.data;
              console.log("index_data::", this.index_data);
              this.questions =
                response.data.data[0].quiz.coursequizquestion_set;
              console.log("this.questions", this.questions);

              console.log("response.data.data", response.data.data);
              console.log(
                "response.data.data",
                response.data.data[0].quiz.qualify_certificate
              );
              console.log("quiz values", this.quiz_details.quiz);
              if (response.data.data[0].quiz.quiztakers_set.completed == true) {
                this.completed = true;
              }
            }
          });
      }
    },
    startQuizFunc(index, quiz_title_data) {
      this.questions = this.quiz_details[index].quiz.coursequizquestion_set;
      this.quiz_title = quiz_title_data;
      this.startQuiz = true;
    },

    handleAnswerClick(question_id, answer_id, isCorrect) {
      console.log(question_id, answer_id, isCorrect);
      if (question_id != false) {
        this.list_data.push({
          question: question_id,
          answer: answer_id,
        });
      }

      clearTimeout(this.timer);
      let nextQuestion = this.currentQuestion + 1;
      if (isCorrect) {
        this.score = this.score + 1;
        console.log("this.score", this.score);
        // this.percentage = (this.score / this.questions.length) * 100;
      }
      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;

        this.countDown = 30;
        // this.countDownTimer();
      } else {
        this.completed = true;
        this.saveQuizResult();
      }
    },
    async saveQuizResult() {
      this.HeaderVal = {
        score: this.score,
        quiz: this.coursrid,
        quiz_title: this.quiz_title,
        usersanswer: this.list_data,
        completed: true,
      };
      console.log("post_befer __ this.HeaderVal:::", this.HeaderVal);
      if (this.userInfo) {
        let token = this.userInfo.token_key;
        console.log(
          `${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${token}`,
          this.HeaderVal
        );
        await axios
          .patch(
            `${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${token}`,
            this.HeaderVal
          )
          .then((response) => {
            console.log("response", response);
            console.log("response", response.data);
            if (response.data.message == "Course Answer updated successfully") {
              this.messages = {
                text: "Congrats, Your are Completed Course Quiz.",
                type: "success",
              };
            } else {
              this.messages = {
                text: " Event Faild",
                type: "error",
              };
            }
          });
        setTimeout(() => {
          this.messages = {
            text: " ",
            type: "null",
          };
          location.reload();
          this.getQuiz();
        }, 200);
      }

      console.log("user answer quiz saved");
    },
    // countDownTimer() {
    //   if (this.countDown > 0) {
    //     this.timer = setTimeout(() => {
    //       this.countDown -= 1;
    //       this.countDownTimer();
    //     }, 1000);
    //   } else {
    //     this.handleAnswerClick(false);
    //   }
    // },
  },
};
</script>

<style scoped> 

.bgblue{
  background-color: #01B0F9 !important;
  color: white;
}

.colorprimary{
  color: #01B0F9 !important;
}

.card {
  min-width: 100%;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.75);
}

.card-q {
  min-width: 60%;
}

.ans-option-btn {
  min-width: 50%;
  font-size: 16px;
  color: #ffffff;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  /* background-color: lightblue; */
}

.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  height: 350px;
}

.ans-option-btn {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  border: 5px solid #234668;
  cursor: pointer;
}

.score {
  color: #2b2b2b;
  font-weight: bold;
}

.message {
  font-size: 1.5rem !important;
}
</style>
