<template>
  <div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <confirmPopup
      v-if="popupwindow == true"
      :title="titlepass"
      @cancelClick="popupwindow = false"
      @confirmClick="deletecourse(idpass)"
    />
    <div class="container-default-1209px w-container">
      <div
        data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384278"
        style=""
        class="divider"
      ></div>
    </div>

    <div
      class="container-default-1209px w-container mx-auto px-4 md:px-6 lg:px-8 my-10"
    >
      <div
        class="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-8 rounded-3xl shadow-xl my-20"
      >
        <div
          class="lg:px-20 flex flex-col py-14 gap-4 justify-center lg:text-left px-10"
        >
          <h1 class="font-bold text-3xl lg:text-5xl prime">
            Welcome
            <span
              class="!text-[#105C8E] text-xl lg:text-3xl ml-2 lg:ml-5 text-blue-600"
            >
              {{ this.userInfo.user.display_name }}
            </span>
          </h1>
          <p class="content max-w-sm">
            Looking for information about Galecta LMS itself (features, use
            cases, or documentation). Referring to reusing or duplicating
            organizing content for it.
          </p>
          <div class="flex gap-1">
            <!-- <input
              type="text"
              placeholder="Search The Course"
              class="rounded-full px-8 py-3 w-full sm:w-auto border"
            /> -->
            <button class="bluesbg py-2 rounded-xl text-white px-8">
              Update Profile
            </button>
          </div>
          <!-- <div class="flex flex-row items-center">
            <input type="text" placeholder="Search The Course" class="rounded-full px-8 py-4 border" />
            <button class="bluesbg py-3 rounded-2xl text-white ml-10 px-10">Search</button>
          </div> -->
        </div>
        <!-- Image Section -->
        <div
          class="flex justify-center lg:justify-end gap-4 relative heightfull"
        >
          <!-- <img src="@/assets/new/blub.png" alt="" class="absolute hidden lg:block top-1/4 right-1/4 z-10" /> -->
          <img
            src="@/assets/new/boy.png"
            alt=""
            class="max-w-full h-auto rounded-r-3xl object-contain heightfull"
          />
        </div>
      </div>
    </div>

    <div
      class="min-h-screen container-default-1209px w-container mb-8 flex flex-col gap-20"
    >
      <div>
        <div class="flex flex-row items-center justify-between">
          <p class="my-6 blues text-4xl font-semibold">Your Courses</p>
          <div class="flex gap-3">
            <div class="my-6 blues text-xl font-medium">View All</div>
            <router-link :to="{ name: 'instructorCourse' }" class="inline-block">
              <img
                src="../assets/new/chevron-down.png"
                alt=""
                class="w-5 h-5 my-7 cursor-pointer"
              />
            </router-link>
          </div>
        </div>

        <div class="grid lg:grid-cols-3 gap-4 mt-10">
          <div
            v-for="course in this.courselist.slice(0, 3)"
            :key="course"
            class="heightc card1 p-6"
          >
            <router-link
              :to="{ name: 'VideoRender', params: { courseid: course.id } }">
              <div
                class="relative flex justify-center items-center"
                style="height: 60%"
              >
                <img
                  v-if="course.thumbnail"
                  class="object-cover object-center h-full w-full rounded-lg"
                  :src="course.thumbnail"
                  alt=""
                />
                <img
                  v-else
                  class="object-cover object-center h-24 w-52 rounded"
                  src="@/assets/coding.svg"
                  alt=""
                />
                <!-- <button
                  class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
                >
                  {{ course.category }}
                </button> -->
              </div>

              <div
                class="text-center flex flex-col gap-1 justify-center"
                style="height: 25%"
              >
                <p
                  class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0 mt-2"
                >
                  {{ course.title }}
                </p>
                <p class="text-xs text-gray-600 px-4 text-left truncate mb-0">
                  {{ course.sections.length }} Units
                </p>
                
                <!-- <div>
                  <p
                    class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"
                  >
                    Continue Learning
                  </p>
                </div> -->
              </div>
            </router-link> 

            <div class="p-3">
                  <div class="flex justify-between">
                    <div class="cursor-pointer" @click="pushRoute('UpdateLesson', course.id)">
                      <img src="@/assets/new/basil_add-outline.png" />
                    </div>

                    <div class="cursor-pointer" @click="pushRoute('EditCourse', course.id)">
                      <img
                      src="@/assets/new/mage_edit.png"
                    />
                    </div>
                    

                    <img
                    class="cursor-pointer"
                      src="@/assets/new/Vector.png"
                      @click="
                        titlepass = course.title;
                        popupwindow = true;
                        idpass = course.id;
                      "
                    />

                    <!-- <router-link :to="{name:'UpdateLesson',params:{courseId:course.id}}" class="">
                         <img src="@/assets/new/basil_add-outline.png" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                     </router-link>

                     <router-link :to="{name:'EditCourse',params:{course_id:course.id}}" class="">
                         <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                         Edit Course
                     </router-link>

                     <button class="" @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                      <img src="@/assets/new/Vector.png" alt="" srcset="">
                     </button> -->
                  </div>
                </div>

          </div>
        </div>
      </div>

      <!-- Featured Courses -->
      <div>
        <div class="flex flex-row items-center justify-between">
          <p class="my-6 blues text-4xl font-semibold">Your Blogs</p>
          <div class="flex gap-3">
            <div class="my-6 blues text-xl font-medium">View All</div>
            <router-link :to="{ name: 'instructorCourse' }" class="inline-block">
              <img
                src="../assets/new/chevron-down.png"
                alt=""
                class="w-5 h-5 my-7 cursor-pointer"
              />
            </router-link>
          </div>
        </div>

        <div class="grid lg:grid-cols-3 gap-4 mt-10">
          <div
            v-for="course in this.courselist.slice(0, 3)"
            :key="course"
            class="heightc card1 p-6"
          >
            <router-link
              :to="{ name: 'VideoRender', params: { courseid: course.id } }"
            >
              <div
                class="relative flex justify-center items-center"
                style="height: 60%"
              >
                
                <img
                  v-if="course.thumbnail"
                  class="object-cover object-center h-full w-full rounded-lg"
                  :src="course.thumbnail"
                  alt=""
                />

                <img
                  v-else
                  class="object-cover object-center h-24 w-52 rounded"
                  src="@/assets/coding.svg"
                  alt=""
                />
                <!-- <button
          class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
        >
          {{ course.category }}
        </button> -->
              </div>

              <div
                class="text-center flex flex-col gap-1 justify-center"
                style="height: 45%"
              >
                <p
                  class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0"
                >
                  {{ course.title }}
                </p>
                <p class="text-xs text-gray-600 px-4 text-left truncate mb-0">
                  {{ course.sections.length }} Units
                </p>

                <p
                  class="my-3 py-2 rounded-lg border-2 border-blues text-blues text-md font-semibold flex flex-row gap-2 justify-center"
                >
                  <img
                    src="../assets/new/editblack.png"
                    alt=""
                    srcset=""
                    class="w-6 h-6 cursor-pointer"
                  /><span>Edit</span>
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="relative w-full">
      <img
        src="../assets/new/image1.png"
        alt="Upcoming Events"
        class="object-cover w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]"
      />

      <div
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-full px-4"
      >
        <p
          class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white font-bold"
        >
          Upcoming Events
        </p>
        <p
          class="text-sm sm:text-lg md:text-xl text-white mt-2 max-w-2xl mx-auto"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit
          amet consectetur adipisicing elit.
        </p>
        <button
          class="mt-4 py-2 px-6 text-sm sm:text-base font-medium rounded-lg border-2 bg-white text-blues border-blues hover:bg-blues hover:text-black"
        >
          View All Events
        </button>
      </div>
    </div>

    <!-- <div id="Courses" style="" class="section latest-courses ">
        <div class="container-default-1209px w-container">
            <div class="latest-courses-wrapper">
                <h2 style="" class="title latest-courses flex flex-col gap-4 lg:flex-row items-center justify-between">Created Courses

                     <router-link :to="{name:'AddCourse'}" class="button-secondary w-button float-right">Add Courses</router-link>
                <router-link :to="{name:'NewCourse'}" class="button-secondary w-button float-right ">Add Courses with AI</router-link>

                </h2>
                <div class="w-dyn-list">
                    <div v-if="this.courselist" role="list" class="courses-grid ">
                        <div v-for="(course, index) in this.courselist" :key="course"  style="height:528px" role="listitem" class="course-card-wrapper w-dyn-item w-80 md:w-96 relative">
                            <div :id="index" class="rounded-lg shadow-lg w-80 md:w-96" style="height:550px">
                            <router-link :to="{name:'preview',params:{courseid:course.id}}" class="absolute top-4 right-6 z-50">
                                <button class=" bg-white border px-2 py-0.5 text-sm text-black rounded-lg hover:text-blue-900">Preview</button>
                            </router-link>
                                <a href="#" class="card-image-link w-inline-block rounded-lg">
                                    <img :src="course.thumbnail" alt="" style="overflow:hidden;height:208px" class="image course w-80 md:w-96"></a>
                                <div class="card-content" style="overflow: auto;height: 363px;">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 class="text-lg 2xl:text-xl course" :title='course.title'>{{course.title.slice(0,80)}}
                                      
                                        </h3>
                                      
                                    </a>
                                    <p class="text-base">{{course.short_description.slice(0,80)}} 
                                    <span id="dots">...</span>
                                    <span id="more" class="hidden">{{course.short_description.slice(80)}}</span>
                                    </p>
                                    <a id="button" class="readmorecutton  duration-300 text-sm">Read more</a>


                                    <div class="divider course-card"></div>
                                    <div class="course-card-details-wrapper">
                                        <div class="flex gap-1 items-center justify-between ">
                                            <router-link :to="{name:'UpdateLesson',params:{courseId:course.id}}" class="border px-2 rounded flex gap-1 border-green-400 text-sm items-center py-1 hover:bg-green-100 ">
                                                <img src="../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                                                Add section
                                            </router-link>
                                            <router-link :to="{name:'EditCourse',params:{course_id:course.id}}" class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 ">
                                                <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                                Edit Course
                                            </router-link>
                                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                                                
                                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                                Delete 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no courses available yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="container-default-1209px w-container">
      <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842ac" style="" class="divider"></div>
    </div> -->

    <!-- <div class="container-default-1209px w-container">
      <div class="divider"></div>
    </div> -->

    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import axios from "axios";
import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  components: {
    Navigation,
    Footer,
    FlashMessage,
    confirmPopup,
  },
  data() {
    return {
      courselist: "",
      instructorInfo: "",
      messages: {
        text: "",
        type: "null",
      },
      titlepass: "",
      popupwindow: false,
      idpass: "",
    };
  },

  computed: mapGetters(["userInfo"]),

  async created() {
    await axios
      .get(
        `${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.instructorInfo = response.data;
        console.log(this.instructorInfo, "info");
      });
    await axios
      .get(
        `${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`
      )
      .then((response) => {
        if (response.data.message == "No courses details") {
          console.log("Null");
        } else {
          this.courselist = response.data.data;
          this.courselist.forEach((element) => {
            element.displayprice = new Intl.NumberFormat("hi-IN", {
              style: "currency",
              currency: "INR",
              maximumFractionDigits: 0,
            }).format(element.price);
          });
        }
      });
  },
  methods: {
    pushRoute(name, id) {
      if(name == "EditCourse"){
        this.$router.push({ name: name, params:{ course_id: id } });
      }
      else{ 
        this.$router.push({ name: name, params:{ courseId: id } });
      }
    },

    async deletecourse(data) {
      await axios
        .delete(
          `${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          this.res = response;
          if (this.res.statusText == "No Content") {
            this.messages = {
              text: "Deleted successfully",
              type: "success",
            };
            console.log("successfull");
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });
      this.popupwindow = false;

      setTimeout(() => {
        this.$router.go(0);
        this.messages = {
          text: "",
          type: "null",
        };
      }, 2000);
    },
  },
  mounted() {
    // redirectToDashboard(courses) {
    //     this.$router.push({
    //         name: "InstructorStudents",
    //         params: {
    //             course_id: courses.id,
    //             instructor: courses.instructor,
    //             course_name: courses.title
    //         }
    //     })
    // },
    // setTimeout(() => {
    //   var dots = document.getElementById("dots");
    //   var moreText = document.getElementById("more");
    //   var button = document.querySelector("#button");

    //   button.addEventListener("click", function () {
    //     if (dots.classList.contains("hidden")) {
    //       // Show the dots
    //       dots.classList.remove("hidden");

    //       // Hide the more text
    //       moreText.classList.add("hidden");

    //       // change text of the button
    //       button.innerHTML = "Read more";
    //     } else {
    //       // Hide the dots
    //       dots.classList.add("hidden");

    //       // hide the more text
    //       moreText.classList.remove("hidden");

    //       // change text of the button
    //       button.innerHTML = "Read less";
    //     }
    //   });
    // }, 3000);

    $(document).ready(function () {
      setTimeout(function () {
        var button = $(".readmorecutton");
        button.on("click", function (e) {
          var readmorecutton = $(e.target);
          var target = $(e.target).parents(".card");
          var dots = target.find("#dots");
          var more = target.find("#more");
          if (dots.hasClass("hidden")) {
            dots.removeClass("hidden");
            more.addClass("hidden");
            readmorecutton.text("Read more");
          } else {
            dots.addClass("hidden");
            more.removeClass("hidden");
            readmorecutton.text("Read less");
          }
        });
      }, 1000);
    });
  },
};
</script>

<style scoped>
.prime {
  color: #333333 !important;
}

.blues {
  color: #0f172a !important;
}

.bluesbg {
  background-color: #0f172a !important;
}

/* .width{
  height: 100% !important;
} */

.endss {
  display: flex !important;
  justify-items: end !important;
  justify-content: end !important;
  align-items: flex-end !important;
}

.relativep {
  position: relative !important;
}

.absolutes {
  position: absolute !important;
  top: 8% !important;
  right: 70% !important;
  z-index: 100 !important;
}

.contents {
  width: 40% !important;
}

.heightc {
  height: 500px !important;
}

.heightfull {
  height: 450px !important;
}
</style>
