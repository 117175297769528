<template lang="">
  <div class="mainContentForStudent flex bg-gray-50">
    <leftNavigationStudent actived="course_step" v-if="!isLessonTab" />

    <div
      class="widthclass rounded-3xl bg-white shadow-lg pt-6 mt-3 ml-10"
      v-if="isLessonTab"
    >
      <div class="px-2 py-4 flex flex-col gap-1 heightside">
        <div v-for="section in sectionInfo.data" :key="section">
          <div
            class="flex items-center rounded-2xl px-10 py-3 gap-5 justify-between cursor-pointer"
            @click="courseSectionToggle(section.id)"
          >
            <p class="font-bold text-sm pt-3">{{ section.title }}</p>
            <!-- <img
                  src="@/assets/new/down.png"
                  v-if="activeCourseMenu == section.id"
                  style="height: 8px !important"
                  alt=""
                />
                <img
                  src="@/assets/new/up.png"
                  v-if="activeCourseMenu != section.id"
                  style="height: 8px !important"
                  alt=""
                /> -->
            <img
              src="@/assets/new/up.png"
              style="height: 8px !important"
              alt=""
            />
          </div>

          <!-- v-if="activeCourseMenu == section.id" -->
          <div v-for="(lesson, index) in lessonInfo.results">
            <div
              class="my-2 flex flex-col gap-1"
              v-if="section.id == lesson.section_id"
            >
              <div
                class="flex items-start rounded-2xl px-12 gap-3 cursor-pointer"
                @click="setUrlTap(lesson.video_url, index, lesson)"
              >
                <!-- <img src="@/assets/new/ideo.png" class="mt-2" alt="" /> -->
                <p
                  class="font-bold text-xs mt-3 py-0"
                  :class="{ textblue: activeIndex === index }"

                >
                 {{ lesson.title }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full h-full relative overflow-y-scroll" v-if="!isLessonTab">
      <div class="py-10 px-20">
        <div>
          <div class="rounded-3xl bg-white shadow-lg">
            <div class="p-10 flex items-center gap-10">
              <div class="contentwidth30">
                <img :src="courseDetailInfo[0].thumbnail" class="rounded-2xl" />
              </div>

              <div class="contentwidth flex flex-col gap-2">
                <h1 class="text-2xl font-bold">
                  {{ courseDetailInfo[0].title }}
                </h1>

                <div class="flex items-center gap-10">
                  <div class="flex items-center rounded-2xl py-3 gap-2">
                    <img src="@/assets/new/rounded.png" alt="" />
                    <p class="text-xs pt-2">
                      {{ courseDetailInfo[0].sections.length }} Units
                    </p>
                  </div>
                  <div class="flex items-center rounded-2xl py-3 gap-2">
                    <img src="@/assets/new/time.png" alt="" />
                    <p class="text-xs pt-2">18h 20m Left</p>
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div>
                    <p class="text-sm">In progress</p>
                  </div>
                  <div>
                    <p class="text-sm">45%</p>
                  </div>
                </div>

                <div class="relative w-full bg-gray-200 rounded-full h-4">
                  <div
                    class="absolute left-0 top-0 h-4 activebg rounded-full transition-all duration-300"
                    :style="{ width: progress + '%' }"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-10 mt-10 px-2">
            <h1 class="text-2xl">Course Description</h1>
            <p>{{ this.courseDetailInfo[0].short_description }}</p>
            <!-- <p class="text-base" v-html="this.courseDetailInfo[0].description"></p> -->
          </div>

          <div class="flex flex-col gap-10 mt-10 px-2">
            <h1 class="text-2xl">Course Content</h1>
            <div class="flex flex-col gap-4">
              <div v-for="section in sectionInfo.data" :key="section">
                <div
                  class="activebg flex items-center rounded-2xl px-10 py-1 gap-5 justify-between cursor-pointer"
                  @click="courseSectionToggle(section.id)"
                >
                  <p class="font-bold text-sm pt-3 text-white">
                    {{ section.title }}
                  </p>
                  <img
                    src="@/assets/new/upw.png"
                    v-if="activeCourseMenu == section.id"
                    style="height: 8px !important"
                    alt=""
                  />
                  <img
                    src="@/assets/new/downw.png"
                    v-if="activeCourseMenu != section.id"
                    style="height: 8px !important"
                    alt=""
                  />
                </div>

                <div
                  v-for="(lesson, index) in lessonInfo.results"
                  v-if="activeCourseMenu == section.id"
                >
                  <div
                    class="my-5 flex flex-col gap-4"
                    v-if="section.id == lesson.section_id"
                  >
                    <div
                      class="activebg1 flex items-center rounded-2xl px-12 gap-3 cursor-pointer"
                      @click="setUrlTap(lesson.video_url, index, lesson)"
                    >
                      <img src="@/assets/new/ideo.png" class="mt-1" alt="" />
                      <p class="font-bold text-sm mt-3 py-1 bg-[#333333]">
                        {{ lesson.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full h-full relative overflow-y-scroll" v-if="isLessonTab">
      <div class="py-10 px-20">
        <div>
          <div class="rounded-3xl bg-white shadow-lg">
            <div class="p-10 flex flex-col gap-10">
              <div class="flex items-center justify-between">
                <button
                  class="buttonbg text-xs text-white px-6 py-1.5 rounded-2xl"
                  @click="isLessonTab = !this.isLessonTab"
                >
                  Back
                </button>

                <h1 class="text-2xl">
                  {{ courseDetailInfo[0].title }}
                </h1>

                <div class="flex items-center gap-10">
                  <div
                    class="flex items-center gap-4 cursor-pointer"
                    v-if="this.activeIndex != 0"
                    @click="prevCourse()"
                  >
                    <img src="@/assets/new/left.png" class="h-3" />
                    <p class="mt-2 text-base">Previous</p>
                  </div>
                  <div
                    class="flex items-center gap-4 cursor-pointer"
                    v-if="
                      this.activeIndex != this.lessonInfo.results.length - 1
                    "
                    @click="nextCourse()"
                  >
                    <p class="mt-2 text-base">Next</p>
                    <img src="@/assets/new/right.png" class="h-3" />
                  </div>
                </div>
              </div>

              <div class="contentwidth100">
                <iframe
                  class="w-full h-full mx-auto rounded p-3 height500"
                  :src="selectedlessonData.video_url"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
                <!-- <img :src="selectedlessonData.video_url" class="rounded-2xl contentwidth100" /> -->
              </div>

              <!-- {{ selectedlessonData }} -->

              <div class="contentwidth100 flex flex-col gap-2">
                <div class="flex items-center justify-between">
                  <h1 class="text-2xl">
                    {{ selectedlessonData.title }}
                  </h1>

                  <!-- {{ selectedlessonData }} -->

                  <div class="flex items-center justify-end gap-10">
                    <button
                      class="buttonbg text-xs text-white px-6 py-1.5 rounded-2xl"
                      @click="
                        completeUnit(
                          selectedlessonData.section_id,
                          selectedlessonData.id
                        )
                      "
                    >
                      Complete
                    </button>

                    <!-- {{ selectedlessonData }} -->
                    <!-- <div class="flex items-center gap-4">
                  <img src="@/assets/new/left.png" class="h-4" />
                  <p class="mt-2 text-base">Previous</p>
                </div>
                <div class="flex items-center gap-4">
                  <p class="mt-2 text-base">Next</p>
                  <img src="@/assets/new/right.png"  class="h-4" />
                </div> -->
                  </div>
                </div>

                <div class="flex items-center gap-10">
                  <!-- <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/rounded.png" alt="" />
                  <p class="text-xs pt-2">
                    {{ courseDetailInfo[0].sections.length }} Units
                  </p>
                </div> -->
                  <div class="flex items-center rounded-2xl py-3 gap-2">
                    <img src="@/assets/new/time.png" alt="" />
                    <p class="text-xs pt-2">
                      {{ selectedlessonData.duration }} min
                    </p>
                  </div>
                </div>

                <!-- <div class="flex items-center justify-between">
                <div>
                  <p class="text-sm">In progress</p>
                </div>
                <div>
                  <p class="text-sm">45%</p>
                </div>
              </div>

              <div class="relative w-full bg-gray-200 rounded-full h-4">
                <div
                  class="absolute left-0 top-0 h-4 bg-blue-400 rounded-full transition-all duration-300"
                  :style="{ width: progress + '%' }"
                ></div>
              </div>  -->

                <!-- <v-md-preview :text="selectedlessonData.content"></v-md-preview> -->

                <div v-html="selectedlessonData.content" class="w-full">
                  
                </div>

                <iframe
                  :src="convertHttpToHttps(selectedlessonData.pdf_file)"
                  style="height: 500px !important"
                ></iframe>

                <!-- {{ selectedlessonData.pdf_file }} -->
                <!-- <template v-if="selectedlessonData.pdf_file.includes('pdf')">
                  <vue-pdf-embed :source="convertHttpToHttps(selectedlessonData.pdf_file)" class="w-full" style="height: 500px !important" />
                </template> -->

                <!-- <vue-pdf-embed :source="convertHttpToHttps(selectedlessonData.pdf_file)" class="w-full" style="height: 500px !important" /> -->

                <p class="text-sm">{{ selectedlessonData.keyworeds }}</p>

                <!-- {{ selectedlessonData.orderby }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="ml-[20%] py-10 content">
      <div>
        <div class="widthclass rounded-3xl bg-white shadow-lg">
          <div class="p-10 flex items-center gap-10">
            <div class="contentwidth30">
              <img :src="courseDetailInfo[0].thumbnail" class="rounded-2xl" />
            </div>

            <div class="contentwidth flex flex-col gap-2">
              <h1 class="text-2xl font-bold">
                {{ courseDetailInfo[0].title }}
              </h1>

              <div class="flex items-center gap-10">
                <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/rounded.png" alt="" />
                  <p class="text-xs pt-2">
                    {{ courseDetailInfo[0].sections.length }} Units
                  </p>
                </div>
                <div class="flex items-center rounded-2xl py-3 gap-2">
                  <img src="@/assets/new/time.png" alt="" />
                  <p class="text-xs pt-2">18h 20m Left</p>
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div>
                  <p class="text-sm">In progress</p>
                </div>
                <div>
                  <p class="text-sm">45%</p>
                </div>
              </div>

              <div class="relative w-full bg-gray-200 rounded-full h-4">
                <div
                  class="absolute left-0 top-0 h-4 bg-blue-400 rounded-full transition-all duration-300"
                  :style="{ width: progress + '%' }"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-10 mt-10 px-2">
          <h1 class="text-2xl">Course Description</h1>
          <p class="text-base">
            {{ this.courseDetailInfo[0].short_description }}
          </p>
        </div>

        <div class="flex flex-col gap-10 mt-10 px-2">
          <h1 class="text-2xl">Course Content</h1>
          <div class="flex flex-col gap-4">
            <div v-for="section in sectionInfo.data" :key="section">
              <div
                class="activebg flex items-center rounded-2xl px-10 py-3 gap-5 justify-between cursor-pointer"
                @click="courseSectionToggle(section.id)"
              >
                <p class="font-bold text-sm pt-3">{{ section.title }}</p>
                <img
                  src="@/assets/new/down.png"
                  v-if="activeCourseMenu == section.id"
                  style="height: 8px !important"
                  alt=""
                />
                <img
                  src="@/assets/new/up.png"
                  v-if="activeCourseMenu != section.id"
                  style="height: 8px !important"
                  alt=""
                />
              </div>

              <div
                v-for="(lesson, index) in lessonInfo.results"
                v-if="activeCourseMenu == section.id"
              >
                <div
                  class="my-5 flex flex-col gap-4"
                  v-if="section.id == lesson.section_id"
                >
                  <div
                    class="activebg1 flex items-center rounded-2xl px-12 gap-3"
                  >
                    <img src="@/assets/new/ideo.png" class="mt-1" alt="" />
                    <p class="font-bold text-sm mt-3 py-3">
                      {{ lesson.title }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="w-full h-full relative overflow-y-scroll">
      <div v-if="!isLessonTab">
        <div class="px-4 pt-3 flex flex-col gap-4 mt-10 lg:mt-0">
          <p class="text-lg lg:text-lg mb-0 font-semibold">Course Curriculum</p>
        </div>

        <div class="px-10 py-1 flex flex-col gap-4 mt-5 overflow-y-auto">
          <div v-if="sectionInfo != null">
            <div v-for="(section, index) in sectionInfo.data" :key="section">
              <div class="videos">
                <p class="px-2 py-2 text-gray-800 text-sm font-semibold mb-0">
                  {{ section.title }}
                </p>
                <div
                  v-if="Object.keys(this.lessonInfo) != 'error'"
                  class="videos-wrapper"
                >
                  <div
                    v-for="(lesson, index) in lessonInfo.results"
                    :key="index"
                  >
                    <div
                      v-if="section.id == lesson.section_id"
                      class="flex flex-col lg:flex-row lg:items-center rounded-lg justify-between bg-gray-200 mb-2"
                    >
                      <div class="flex items-center gap-1">
                        <div style="width: 8%">
                          <div class="custom-icon mx-4">
                            <div
                              class="custom-bg"
                              style="width: 50% !important"
                            ></div>
                          </div>
                        </div>
                        <h3
                          class="text-sm course-video h-full px-4 py-2 m-0 flex items-center"
                        >
                          {{ lesson.title }}
                        </h3>
                      </div>

                      <div class="flex">
                        <p
                          class="text-sm bg-primary py-1 px-4 rounded-lg mt-2 mr-4 ml-4 cursor-pointer"
                          @click="setUrlTap(lesson.video_url, index, lesson)"
                        >
                          Start
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-ful h-full" v-if="isLessonTab">
        <div
          class="hidden lg:flex flex-col chatContainer w-80 lg:w-3/12 2xl:w-1/6 bg-white overflow-y-auto shadow-lg"
        >
          <p class="text-base lg:text-md mb-0 px-3 py-2 font-semibold">
            Course Curriculum
          </p>
          <div
            class="py-3 text-base lg:text-sm bg-white mb-0 cursor-pointer flex"
          >
            <div v-if="sectionInfo != null">
              <div v-for="section in sectionInfo.data" :key="section">
                <div class="videos">
                  <p
                    class="px-2 text-base font-semibold text-gray-800 py-3 mb-0"
                  >
                    {{ section.title }}
                  </p>

                  <div
                    v-if="Object.keys(this.lessonInfo) != 'error'"
                    class="videos-wrapper w-full"
                  >
                    <div
                      v-for="(lesson, index) in lessonInfo.results"
                      :key="lesson"
                    >
                      <div
                        v-if="section.id == lesson.section_id"
                        @click="setUrl(lesson.video_url, index, lesson)"
                        class="bg-gray-100 mb-3 flex items-center gap-3 w-full"
                        style="width: 100% !important"
                      >
                        <div style="width: 8%">
                          <div class="custom-icon mx-4"></div>
                        </div>

                        <div class="w-full" style="width: 90%">
                          <h3
                            class="text-sm h-full px-4 py-2 m-0 flex items-center"
                            :class="{ 'text-blue-800': activeIndex === index }"
                          >
                            {{ lesson.title }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="videoFile" class="w-full overflow-y-scroll">
          <div class="flex justify-between mt-6">
            <div>
              <p
                class="text-xs bg-primary py-1.5 px-4 rounded-lg mt-2 mx-4 cursor-pointer"
                @click="isLessonTab = false"
              >
                Back
              </p>
            </div>
            <div class="flex items-center gap-2">
              <p
                class="text-xs bg-primary py-1.5 px-4 rounded-lg mt-2 mr-4 cursor-pointer"
                v-if="this.activeIndex != 0"
                @click="prevCourse()"
              >
                Prev
              </p>
              <p
                class="text-xs bg-primary py-1.5 px-4 rounded-lg mt-2 mr-4 cursor-pointer"
                v-if="this.activeIndex != this.lessonInfo.results.length - 1"
                @click="nextCourse()"
              >
                Next
              </p>
            </div>
          </div>

          <div v-if="this.videoLink" class="w-full p-3 h-full flex flex-col">
            <h1 class="text-2xl font-semibold text-primary mt-2 px-3">
              {{ selectedlessonData.title }}
            </h1>

            <iframe
              class="w-full h-full mx-auto rounded p-3"
              :src="this.videoLink"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>

            <v-md-preview :text="selectedlessonData.content"></v-md-preview>
          </div>

          <div v-else class="w-full h-full flex items-center justify-center">
            <h1 class="text-center text-xl py-4">Course Lesson's</h1>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import $ from "jquery";

import "@uivjs/vue-markdown-preview/markdown.css";
import MarkdownPreview from "@uivjs/vue-markdown-preview";

import VuePdfEmbed from "vue-pdf-embed";

export default {
  data() {
    return {
      messageVal: null,
      totalmessage: null,
      studentList: "",
      selectedStudent: null,
      ChatpopUp: false,
      instructorChat: [
        {
          id: "",
          display_name: "",
        },
      ],
      sectionInfo: "",
      lessonInfo: "",
      videoLink: "",
      activeIndex: null,
      isLessonTab: false,
      selectedlessonData: null,
      courseDetailInfo: null,
      progress: 90,
      activeCourseMenu: null,
      MarkdownPreview,
    };
  },

  components: {
    leftNavigationStudent,
    VuePdfEmbed,
  },

  props: ["course_id", "slug", "instructor_id", "course_name"],

  computed: {
    ...mapGetters(["userInfo"]),
  },

  async created() {
    this.detailsgets();

    await axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${this.course_id}`)
      .then((response) => {
        this.courseDetailInfo = response.data.data;
      });
  },

  async mounted() {
    // alert(this.lessonInfo.results[0]);
    // console.log(this.lessonInfo.results[0].video_url, "set url:::");
    // this.videoLink = this.lessonInfo.results[0].video_url;
    // console.log(this.course_id,this.slug)

    setInterval(function () {
      var headerHeight = $(".header[data-collapse]").outerHeight();
      var userInputHeight = $(".userInput").outerHeight();
      var headline = $(".headline").outerHeight();
      var remainHeight = headerHeight + userInputHeight + headline + 10;

      if ($(".mainContentForStudent").length > 0)
        $(".mainContentForStudent").css(
          "height",
          "calc(100vh - " + $(".header[data-collapse]").outerHeight() + "px)"
        );

      if ($(".chatStudent").length > 0)
        $(".chatStudent").css("height", "calc(100vh - " + remainHeight + "px)");
    }, 0);

    await axios
      .get(`${process.env.VUE_APP_API}/coursesdata/${this.course_id}/`)
      .then((response) => {
        this.instructorChat[0].id = response.data.user_id;
      });
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?id=${parseInt(this.course_id)}`)
      .then((response) => {
        this.instructorChat[0].display_name = response.data.data[0].instructor;
      });
    this.getstudentdetails();
    this.messageInfo();
    this.getCompletedLesson();
  },

  methods: {

    convertHttpToHttps(url) {
      if (url.startsWith("http://")) {
        return url.replace("http://", "https://");
      }
      return url; // If it's already https or any other scheme, return as is
    },

    async getCompletedLesson() {
      // http://127.0.0.1:8000/completed-lesson-tracking/1/1/

      await axios
        .get(
          `${process.env.VUE_APP_API}/completed-lesson-tracking/${this.userInfo.user.id}/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          if (response.data.status == 200) {
            console.log(response.data.data, "data:::");
          } else {
            console.log("Something went wrong::");
          }
          // this.lessonInfo = response.data;
          // if (Object.keys(this.lessonInfo) == "error") {
          //   this.lessonCount = 0;
          // } else {
          //   this.lessonCount = this.lessonInfo.count;
          // }
        });
    },

    async completeUnit(unit_id, lesson_id) {
      await axios
        .post(
          `${process.env.VUE_APP_API}/lesson-tracking/${this.userInfo.user.id}/${unit_id}/${lesson_id}/?Key=${this.userInfo.token_key}`,
          {}
        )
        .then((response) => {
          if (response) {
            console.log(response);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    courseSectionToggle(id) {
      if (this.activeCourseMenu == id) {
        this.activeCourseMenu = null;
      } else {
        this.activeCourseMenu = id;
      }
    },

    prevCourse() {
      if (this.activeIndex > 0) {
        this.activeIndex = this.activeIndex - 1;
        this.selectedlessonData = this.lessonInfo.results[this.activeIndex];
        this.videoLink = this.lessonInfo.results[this.activeIndex].video_url;
      }
    },

    nextCourse() {
      if (this.activeIndex < this.lessonInfo.results.length - 1) {
        this.activeIndex = this.activeIndex + 1;
        this.selectedlessonData = this.lessonInfo.results[this.activeIndex];
        this.videoLink = this.lessonInfo.results[this.activeIndex].video_url;
      }
    },

    setUrlTap(url, index, lesson) {
      this.isLessonTab = true;
      this.videoLink = url;
      this.activeIndex = index;
      this.selectedlessonData = lesson;
    },

    setUrl(url, index, lesson) {
      this.videoLink = url;
      this.activeIndex = index;
      this.selectedlessonData = lesson;
    },

    async detailsgets() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/section/?course_name=${this.course_id}`
        )
        .then((response) => {
          this.sectionInfo = response.data;
        });

      await axios
        .get(
          `${process.env.VUE_APP_API}/lessons/?course_name=${this.course_id}`
        )
        .then((response) => {
          this.lessonInfo = response.data;
          if (Object.keys(this.lessonInfo) == "error") {
            this.lessonCount = 0;
          } else {
            this.lessonCount = this.lessonInfo.count;
          }
        });
    },

    async Instructor_Chat(data) {
      this.selectedStudent = data;
      this.ChatpopUp = true;
      await axios
        .get(
          `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent[0].id}/?Key=${this.userInfo.token_key}`
        )
        .then((Response) => {
          this.totalmessage = Response.data.data.reverse();
          console.log(this.totalmessage, "message");
          console.table(this.totalmessage);
        });

      $(".chatArea").animate(
        {
          scrollTop: $(".chatArea")[0].scrollHeight,
        },
        1000
      );

      // let messageDisplay = this.$refs.chatArea;
      // messageDisplay.scrollTop = messageDisplay.scrollHeight;
    },

    async getstudentdetails() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`
        )
        .then((response) => {
          response.data["data"] = [
            ...new Map(
              response.data.data.map((i) => i[0]).map((m) => [m.id, m])
            ).values(),
          ].map((i) => [i]);
          this.studentList = response.data;
        });
    },

    async messageInfo(data) {
      if (data) {
        this.selectedStudent = data;
        this.ChatpopUp = true;
        await axios
          .get(
            `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent[0].id}/?Key=${this.userInfo.token_key}`
          )
          .then((Response) => {
            this.totalmessage = Response.data.data.reverse();
          });

        $(".chatArea").animate(
          {
            scrollTop: $(".chatArea")[0].scrollHeight,
          },
          1000
        );
        // let messageDisplay = this.$refs.chatArea;
        // messageDisplay.scrollTop = messageDisplay.scrollHeight;
      }
    },

    async sendmsg(data) {
      let messagesPass = {
        text: this.messageVal,
        owner: this.userInfo.user.id,
        client: data[0].id,
      };

      await axios
        .post(
          `${process.env.VUE_APP_API}/talk/?Key=${this.userInfo.token_key}`,
          messagesPass
        )
        .then((Response) => {
          Response;
        });

      this.messageInfo(this.selectedStudent);
      this.messageVal = null;

      $(".chatArea").animate(
        {
          scrollTop: $(".chatArea")[0].scrollHeight,
        },
        1000
      );

      // let messageDisplay = this.$refs.chatArea;
      // messageDisplay.scrollTop = messageDisplay.scrollHeight;
    },

    clearAllMessages() {
      this.messages = [];
    },
  },
};
</script>

<style scoped>
.contentwidth30 {
  width: 30% !important;
}

.contentwidth100 {
  width: 100% !important;
}

.contentwidth {
  width: 70% !important;
}

/* .content {
  margin-left: 25% !important;
  margin-right: 5% !important;
  height: 900px !important;
  overflow: auto !important;
} */

.activebg {
  background-color: #0085db;
}

.activebg1 {
  background-color: #e7f8ff;
}

/* .widthclass {
  width: 100% !important;
} */

h1 {
  margin-bottom: 0 !important;
}

.custom-icon {
  width: 15px !important;
  height: 15px !important;
  border: 2px solid #0085db;
  border-radius: 100%;
}

.custom-bg {
  width: 50% !important;
  background-color: #0085db !important;
}

.buttonbg {
  background-color: #0085db !important;
}

.textblue {
  color: #0085db !important;
}

.widthclass {
  width: 20% !important;
}

/* .activebg{
    background-color: #01B0F9 !important;
} */

.heightside {
  height: 100% !important;
  overflow: auto !important;
}

.height500 {
  height: 600px !important;
}
</style>
