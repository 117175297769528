<template>
  <footer data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46cda3" class="footer bluesbg">
    <div class="container-default-1209px w-container flex flex-col">
      <div class="footer-links-block">
        <div data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46cda6" class="footer-links">
          <a href="#" class="flex items-center justify-center"><img src="@/assets/new/moun.png" alt=""
             />
          </a>
          <p class="flex items-center justify-center text-white text-2xl top-5">Learning Management System</p>
          <div class="social-media-wrapper footer-fine-print top-10">
            <a href="#" class="social-media-icon-wrapper w-inline-block" target="_blank">
              <div class="social-media-icon-footer"></div>
            </a>
            <div class="spacer social-media-footer"></div>
            <a href="#" class="social-media-icon-wrapper w-inline-block" target="_blank">
              <div class="social-media-icon-footer twitter"></div>
            </a>
            <div class="spacer social-media-footer"></div>
            <a href="#" class="social-media-icon-wrapper w-inline-block" target="_blank">
              <div class="social-media-icon-footer"></div>
            </a>
            <div class="spacer social-media-footer"></div>
            <a href="#" class="social-media-icon-wrapper w-inline-block" target="_blank">
              <div class="social-media-icon-footer"></div>
            </a>
            <!-- <div class="spacer social-media-footer"></div>
              <a href="https://www.youtube.com/channel/" class="social-media-icon-wrapper w-inline-block" target="_blank">
                <img src="https://img.icons8.com/ios/25/undefined/youtube-play--v1.png"/>
              </a> -->
          </div>
        </div>
        <div data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46cda9" class="links-block _2"></div>
      </div>

      <!-- <div data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46cdf2" class="container-newsletter">
          <div class="split-content newsletter-left">
            <div class="newsletter-icon-wrapper"><img src="../../catalyft-ui-templates/images/icon-newsletter-academy-template.svg" alt="Newsletter Icon - Catalyft" class="newsletter-icon"></div>
            <div class="newsletter-content">
              <div class="title newsletter">Join our newsletter</div>
              <div>Get 3 specially curated placement opportunities every week</div>
            </div>
          </div>
          <div class="form-block newsletter w-form">
            <form id="wf-form-Footer-Newsletter" name="wf-form-Footer-Newsletter" data-name="Footer Newsletter" method="get" class="form-newsletter"><input type="email" class="input newsletter w-input" maxlength="256" name="email-4" data-name="Email 4" placeholder="Enter your email" id="email-4" required=""><input type="submit" value="Subscribe" data-wait="Please wait..." class="button-primary w-button"></form>
            <div class="success-message w-form-done">
              <div>Thank you! You are now subscribed!</div>
            </div>
            <div class="error-message w-form-fail">
              <div>Oops! Something went wrong.</div>
            </div>
          </div>
        </div> -->

    </div>
    <hr>
    <div data-w-id="5d3def44-2af0-a39e-d268-cb5e4a46ce05"
      class="footer-fine-print-wrapper flex items-center justify-center">
      <div class="fine-print text-center text-white w-full text-sm py-1">
        © 2024, Galecta Private Limited. All rights reserved |
        Powered by<a href="https://galecta.com/" class="text-white"> GALECTA</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  created() {
    this.title = process.env.VUE_APP_Title;
  },
};
</script>

<style scoped>
.footer-maggana {
  background-color: beige;
  position: relative;
  bottom: 0px;
}

.bluesbg {
  background-color: #0F172A !important;
}

.object-contain {
  width: 240px !important;
  height: 50px !important;
}
</style>
