<template lang="">
<div class="flex w-full relative" style="height:calc(100vh - 113px);">
<confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deleteEvents(idpass)" />
<FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <!-- New Vue-cal  -->
    <NavigationInstructor actived="events" /> 

    <div class="flex h-full w-full overflow-y-auto p-10" style="scrollbar-color:gray white">
        <div class="h-full w-full">
            <div class="relative" >
                <div>
                    <button @click="this.addevent=true" class="absolute right-0 top-20 md:top-1/3 mr-2 mt-2 primary-text-color py-1 px-1 shadow-lg text-white text-sm rounded-lg w-24 z-50" style="background-color: #047857; color: white !important;">Add Class</button>
                </div>
            </div>
            <vue-cal small ref="vuecal" v-model:active-view="activeView"  events-on-month-view="short" :disable-views="['years']" :events="values" :on-event-click="onEventClick" class="">
            </vue-cal>
        </div>
    </div>

    <!-- popup details -->
    <div v-if="eventInfo" class="absolute w-full h-full z-50 flex items-center bg-black bg-opacity-50 p-10">
        <div class="mx-auto border bg-white flex flex-col items-center rounded-lg relative w-full max-w-5xl h-96">
            <div class="flex w-full justify-between bg-teal-700 py-3 px-4 items-center border text-base font-semibold text-white rounded-t-lg">
                <div class="text-base capitalize w-2/3">{{this.eventInfoData.title}}</div>
                <img class='h-6 w-6 cursor-pointer' src="../../assets/closebutton.svg" Title="Close" @click="eventInfo= false" />
            </div>
            <div class="text-lg font-semibold text-teal-700 pt-4">
                    {{ eventInfoData.start && eventInfoData.start.format('DD/MM/YYYY') }}
                    ( {{ eventInfoData.start && eventInfoData.start.formatTime() }} 
                    ) 
            </div>
            <div class="flex flex-col bg-white w-full px-4 py-4 justify-center h-60">
                <div class="text-lg font-semibold text-gray-600">Event Details</div>
                <p v-html="eventInfoData.contentFull" class="text-sm text-gray-600" />
            </div>
            <div class="absolute flex gap-4 right-4 bottom-4">
                <div class="w-24 flex flex-row justify-center cursor-pointer border hover:border-teal-500 rounded bg-white hover:bg-teal-500 hover:text-white" @click="updatevalues=eventInfoData.event;popUp=true">
                    <div class="py-1 text-sm md:text-base">Edit </div>
                </div>
                <div class="w-24 flex flex-row justify-center cursor-pointer border hover:border-red-500 rounded bg-white hover:bg-red-500 hover:text-white" @click="titlepass=eventInfoData.title;popupwindow=true;idpass=eventInfoData.id">
                    <div class="px-1 md:px-2 py-1 text-sm md:text-base " >Remove </div>
                </div>
                    <!-- <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit" @click="updatevalues=eventInfoData.event;popUp=true">
                    <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete" @click="titlepass=eventInfoData.title;popupwindow=true;idpass=eventInfoData.id"> -->
            </div>
        </div>
    </div>
    <div v-if="addevent==true" class="absolute w-full h-full p-4 bg-black z-50 flex items-center bg-opacity-50">
        <div class=" w-full max-w-5xl relative mx-auto border p-8 bg-white rounded-lg">
            <div class="text-gray-600 text-2xl font-bold pb-4"></div>
            <form ref="ScheduleEvent" @submit.prevent="scheduledate" class="flex flex-col gap-2 w-full">
                <div class="flex flex-col gap-1">
                    <label for="meetingTitle">Title<span class="text-red-400">*</span></label>
                    <input id="meetingTitle" type="text" class="border border-gray-300 rounded-md" required />
                </div>
                <!-- <div class="flex flex-col gap-1">
                    <label for="meetingUrl">Meeting Url <span class="text-red-400">*</span></label>
                    <input id="meetingUrl" type="text" class="border border-gray-300 rounded-md" required />
                </div> -->
                <div class="flex flex-col gap-1">
                    <label for="meetingDate">Date <span class="text-red-400">*</span></label>
                    <input id="meetingDate" type="datetime-local" class="border border-gray-300 rounded-md" required>
                </div>
                <div class='flex flex-col gap-1'>
                    <label for="duration">
                        Session duration
                    </label>
                    <input id="duration" type="number" class="border border-gray-300 rounded-md" min="1" />
                </div>
                <div class='flex flex-col gap-1 bg-gray-300'>
                    <label for="studentLimit">
                        Learner limit
                    </label>
                    <input id="studentLimit" type="number" class="border border-gray-300 rounded-md bg-gray-300 " min="1" value="1000" readonly/>
                </div>
                <div class="flex flex-col gap-1">
                    <label for="meetingUrl">Meeting URL <span class="text-red-400">*</span></label>
                    <button type="button" id="urlGenerator" class="gradient-btn w-52" @click="generateUrl()">Generate URL</button>
                    <input id="meetingUrl" type="text" class="hidden border py-1 border-gray-400 rounded"  v-model="generatedUrl" required />
                </div>
                <div>
                    <button type="submit" class="gradient-btn">Submit</button>
                </div>
            </form>
            <div @click="this.addevent=false;" class='flex absolute top-8 right-8 select-none rounded-lg border hover:text-red-400 items-center hover:bg-gray-100'>
                <div class="px-1 md:px-2 py-1 text-sm md:text-base">Close </div>
                <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
            </div>
        </div>
    </div>
    <div v-if="popUp" class="absolute w-full h-full p-4 bg-black z-50 flex items-center bg-opacity-50">
        <div class='w-full max-w-5xl relative mx-auto border p-8 bg-white rounded-lg flex flex-col gap-2'>
            <div class="text-gray-600 text-2xl font-bold pb-4">Edit Event</div>
            <div @click="popUp=false" class='flex absolute right-8 select-none rounded-lg border top-8 hover:text-red-400 items-center hover:bg-gray-100 z-50'>
                <div class="px-1 md:px-2 py-1 text-sm md:text-base">Close </div>
                <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg"/>
            </div>

            <div class="flex flex-col gap-1">
                <label for="meetingTitle">Meeting Title <span class="text-red-400">*</span></label>
                <input id="meetingTitle" type="text" class="border border-gray-300 rounded-md" v-model="updatevalues.title" required />
            </div>
            <!-- <div class="flex flex-col gap-1">
                <label for="meetingUrl">Meeting Url <span class="text-red-400">*</span></label>
                <input id="meetingUrl" type="text" class="border border-gray-300 rounded-md" v-model="updatevalues.url" required />
            </div> -->
            <div class="flex flex-col gap-1">
                <label for="meetingDate">Meeting Date <span class="text-red-400">*</span></label>
                <input id="meetingDate" type="datetime-local" class="border border-gray-300 rounded-md" v-model="updatevalues.date" required>
            </div>
            <div class='flex flex-col gap-1'>
                <label for="duration">
                    Session duration
                </label>
                <input id="duration" type="number" class="border border-gray-300 rounded-md" min="1" v-model="updatevalues.duration" />
            </div>
            <div class='flex flex-col gap-1'>
                <label for="studentLimit">
                    Student Limit
                </label>
                <input id="studentLimit" type="number" class="border border-gray-300 rounded-md" min="1" v-model="updatevalues.count" />
            </div>
            <div class="flex flex-col gap-1">
                <label for="meetingUrl">Meeting Url <span class="text-red-400">*</span></label>
                <button type="button" id="Editurl" class="gradient-btn w-52" @click="generateUrlEdit()">Generate Url</button>
                <input id="meetingUrledit" type="text" class="hidden border py-1 border-gray-400 rounded" v-model="updatevalues.url" required />
            </div>
            <div>
                <button @click="editEvents()" type="submit" class="gradient-btn mt-2">Updated</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";
import confirmPopup from "@/components/confirmPopup.vue";
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import FlashMessage from "@/components/flashMessage.vue";
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
    data() {
        return {
            courseInfo: null,
            calendarInfo: null,
            scheduleDetails: null,
            popUp: false,
            updatevalues: null,
            messages: {
                text: "",
                type: 'null',
            },
            popupwindow: false,
            titlepass: "",
            idpass: "",
            addevent: false,
            activeView: 'month',
            eventInfo: false,
            eventInfoData: "",
            values: [],
            generatedUrl: "",
        }
    },

    computed: {
        ...mapGetters(["userInfo"]),
    },

    props: ["course_id", "course_name"],

    components: {
        NavigationInstructor,
        FlashMessage,
        confirmPopup,
        VueCal
    },

    async mounted() {
        await this.getinfos()
    },

    methods: {

        onEventClick(event, e) {
            this.eventInfo = true
            this.eventInfoData = event
        },

        async getinfos() {
            let course_ids = parseInt(this.course_id)

            await axios.get(`${process.env.VUE_APP_API}/courses/?id=${course_ids}`)
                .then(Response => {
                    this.courseInfo = Response.data.data
                })

            await axios.get(`${process.env.VUE_APP_API}/calendar/view/?user=${this.userInfo.Instructor_id}&course_data=${this.course_id}&Key=${this.userInfo.token_key}`)
                .then(Response => {
                    if (Object.keys(Response.data) == "error") {
                        this.values = []
                        console.log("Null")
                    }
                    else{
                        
                        this.calendarInfo = Response.data.data
                        this.values = []
                        this.calendarInfo.forEach(element => {
                        // adding 30mits to the date
                        let startdate = new Date(element.date)
                        let enddate = new Date(startdate)
                        enddate = new Date(enddate.setMinutes(startdate.getMinutes() + 30))
                        enddate = [ enddate.getFullYear(),
                                    enddate.getMonth()+1,
                                    enddate.getDate()].join('-')+' '+
                                    [enddate.getHours(),
                                    enddate.getMinutes(),
                                    enddate.getSeconds()].join(':');
                        // console.log(enddate,enddate.length,'end time before')
                        // enddate = "2022-9-22 12:31:0"
                        // console.log(enddate.length,'end time after')
                        // reasigning the data to vue-cal event
                        let tempvar = {
                            start: element.date,
                            end: enddate,
                            title: element.title,
                            class: 'Progressive',
                            contentFull: `<div class="w-full flex-col gap-1"> 
                                            <div class="flex flex-wrap gap-2">
                                                <span class="text-base">Link :</span>
                                                <span >${element.url}</span>
                                            </div> 
                                         <div class="flex gap-2"><span class="text-base">Duration :</span><span>${element.duration}</span></div>
                                           <div class="flex gap-2"><span class="text-base">Student limit :</span> <span>${element.count}</span></div>
                                            <div class="flex gap-2"><span class="text-base">Student Booked :</span> <span>${ element.studentlist==null ? 0 :element.studentlist.length}</span></div> 
                                            </div>`,
                            id: element.id,
                            event: element
                        }
                        this.values.push(tempvar) // pushing the database event to the vue-cal
                    });
                    console.log(this.values)
                    }
              
                })
        },

        async deleteEvents(id) {
            await axios.delete(`${process.env.VUE_APP_API}/calendarupdated/${id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    response
                    if (response.statusText == 'No Content') {
                        this.messages = {
                            text: "Event Deleted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            this.popupwindow = false
            this.eventInfo = false
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.getinfos()
            }, 1500)
        },

        async editEvents() {
            let updatedatas = {
                "title": this.updatevalues.title,
                "organization_id": this.updatevalues.organization_id,
                "user": this.updatevalues.user,
                "status": this.updatevalues.status,
                "url": this.updatevalues.url,
                "date": this.updatevalues.date,
                "count": this.updatevalues.count,
                "duration": this.updatevalues.duration,
                "studentlist": null,
                "course_data": parseInt(this.course_id)
            }
            this.popUp = false

            await axios.put(`${process.env.VUE_APP_API}/calendarupdated/${this.updatevalues.id}/?Key=${this.userInfo.token_key}`, updatedatas)
                .then(response => {
                    response
                    if (response.statusText == 'OK') {
                        this.messages = {
                            text: "Event Updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            this.updatevalues = null
            this.eventInfo = false
            setTimeout(async () => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                await this.getinfos()
            }, 1500)
        },
        generateUrl(){

            
            let title = document.getElementById("meetingTitle").value
            let durations = document.getElementById("duration").value
            let timedetails = document.getElementById("meetingDate").value
            timedetails = timedetails.replaceAll('T', ' ')
            let inputdatas = {
                            "topic":title,
                            "start_time":timedetails,
                            "agenda":title,
                            "duration":durations
                        }
            console.log(inputdatas)

            axios.post(`${process.env.VUE_APP_API}/zoom/?Key=${this.userInfo.token_key}`, inputdatas)
            .then(response=>{
                // console.log(response)
                this.generatedUrl = response.data.data.zoom_join_url
                // console.log(this.generatedUrl)
                document.getElementById("meetingUrl").classList.remove("hidden")
                document.getElementById("urlGenerator").classList.add("hidden")
            })
        },

        generateUrlEdit(){
            let title = this.updatevalues.title
            let durations = this.updatevalues.duration
            let timedetails = this.updatevalues.date
            timedetails = timedetails.replaceAll('T', ' ')
            let inputdatas = {
                            "topic":title,
                            "start_time":timedetails,
                            "agenda":title,
                            "duration":durations
                        }
            console.log(inputdatas)

            axios.post(`${process.env.VUE_APP_API}/zoom/?Key=${this.userInfo.token_key}`, inputdatas)
            .then(response=>{
                // console.log(response)
                this.updatevalues.url = response.data.data.zoom_join_url
                // console.log(this.generatedUrl)
                document.getElementById("meetingUrledit").classList.remove("hidden")
                document.getElementById("Editurl").classList.add("hidden")
            })
        },

        async scheduledate(el) {
            let Urls = el.target.elements.meetingUrl.value
            let timedetails = el.target.elements.meetingDate.value
            let studentlimit = el.target.elements.studentLimit.value
            let durations = el.target.elements.duration.value
            let title = el.target.elements.meetingTitle.value
            timedetails = timedetails.replaceAll('T', ' ')

            // "organization_id": this.courseInfo[0].organization_id,

            let inputdatas = {
                "title": title,
                "organization_id": this.userInfo.organizes_id,
                "user": this.userInfo.user.id,
                "status": true,
                "url": Urls,
                "date": timedetails,
                "count": studentlimit,
                "duration": durations,
                "studentlist": null,
                "course_data": parseInt(this.course_id)
            }

            console.log(inputdatas, "data first:::::");

            await axios.post(`${process.env.VUE_APP_API}/calendar_new/?Key=${this.userInfo.token_key}`, inputdatas)
                .then(response => {
                    this.scheduleDetails = response.data
                })
            if (this.scheduleDetails.message == "your Calendar added successfully") {
                this.messages = {
                    text: "Event Added successfully",
                    type: 'success',
                }
            } else if (this.scheduleDetails == "is not a staff user") {
                this.messages = {
                    text: " Event Faild",
                    type: 'error',
                }
            }
            this.$refs.ScheduleEvent.reset();
            await this.getinfos()
            this.addevent = false
            setTimeout(() => {
                this.generatedUrl = ""
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)
        },
    },
}
</script>

<style scoped>

.vuecal__event {
    color: #115E59;
    background-color: #F5F5F5;
    border: 1px dashed #ba0606;
    overflow: hidden;
}
.vuecal__cell-content{
     height: 180px;
}
.vuecal__event.Progressive {
    background-color: #0F766E;
    border-radius: 6px;
    border: 1px solid rgb(248, 247, 247);
    color: #fff;
}
.vuecal--short-events .vuecal__event-title {
    font-size: 0.8em;
    text-align: center;
}
.vuecal__cell-events{
    height: 180px;
    overflow: scroll;
    scrollbar-color: white transparent;
    cursor: pointer;
    
}

.vuecal__cell-content::-webkit-scrollbar {
    width: 8px;
}

.vuecal__cell-content::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 15px;
}

.vuecal__cell-content::-webkit-scrollbar-track {
    background: #fff;
}
    
</style>
