<template>
  <div v-if="isAuthenticated && userInfo.user.permission == 'student'" data-collapse="medium" data-animation="default"
    data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo"
    data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200"
    style="background-color: #0F172A; padding: 10px 0px">


    <div class="container-default-1209px w-container" style="background-color: #0F172A">
      <div class="header-wrapper">
        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf" class="split-content header-left">
          <router-link :to="{ name: 'Home' }" class="brand w-nav-brand w--current flex items-center">
            <img src="@/assets/new/moun.png" alt="" class="w-40 py-4 object-fit" />
            <!-- <img
              src="@/assets/logo/galecta-wlogo.png"
              alt=""
              class="w-60 object-contain bg-white p-2 rounded-md"
            /> -->
            <!-- <p class="pt-2 text-white">SOUNDARYA EDUCATIONAL TRUST</p> -->
          </router-link>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <!-- <router-link :to="{name:'Home'}" class="text-white w--current">Home</router-link> -->
            <!-- <a href="index.html" aria-current="page" class="text-white w--current">Home</a> -->
            <!-- <a href="#" class="text-white">Companies</a> -->
            <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8"
              class="header-dropdown w-dropdown">
              <!-- <div class="header-dropdown-toggle w-dropdown-toggle"> -->
              <!-- <div class="">
                <router-link :to="{ name: 'Mycourse' }" class="text-white"
                  >My Courses</router-link
                >
              </div> -->
            </div>
          </nav>
        </div>

        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right flex items-center">

          <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
            <!-- <a
              class="text-white"
              title="support.onlinejain"
              href="https://support.onlinejain.com"
            >
              Support
            </a> -->
          </div>

          <div v-if="this.popUp"
            class="fixed z-50 bottom-0 right-0 w-full md:w-5/6 lg:w-3/6 h-auto bg-red flex items-center justify-center border">
            <div class="bg-white flex flex-col w-full h-full">
              <form ref="mailref" @submit.prevent="sendmail" class="">
                <section class="bg-blue-800 text-white flex">
                  <p class="w-5/6 px-2 py-1 mb-0">New Message</p>
                  <div class="w-1/6 flex gap-1 justify-end px-4 py-1">
                    <div @click="this.popUp = false" class="absolute border cursor-pointer flex rounded-lg">
                      <img class="h-6 w-6" src="../assets/closebutton.svg" />
                    </div>
                  </div>
                </section>
                <section v-if="this.minimize" class=" ">
                  <div class="mt-2 border-gray-200 px-2 py-2">
                    <label for="to" class="w-1/6"></label>
                    <input input="email" required class="border-b w-full pl-1" :value="this.mailid" placeholder="To"
                      disabled />
                  </div>
                  <div class="mt-2 border-gray-200 px-2">
                    <label for="subject" class="w-1/6"></label>
                    <input input="text" id="subject" required class="border-b w-full pl-1 py-1" placeholder="Subject" />
                  </div>
                  <div class="mt-2 sm:h-4/6 2xl:h-full px-2">
                    <label for="content" class="w-1/6"></label>
                    <textarea rows="12" cols="10" id="content" class="border border-gray-300 h-full w-full pl-1 rounded"
                      placeholder="Content"></textarea>
                  </div>
                  <div class="py-2 px-2">
                    <button type="submit" class="gradient-btn">Send</button>
                  </div>
                </section>
              </form>
            </div>
          </div>

          <!-- <router-link
            :to="{ name: 'UserProfile' }"
            class="w-auto md:w-56 px-0 z-50 flex justify-center"
          >
            <div
              v-if="isAuthenticated && profileInfo != null"
              class="flex items-center cursor-pointer gap-2"
            >
              <div class="flex items-center">
                <div v-if="profileInfo.data.profile == ''">
                  <div
                    class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold pb-0"
                  >
                    {{ profileInfo.data.user.slice(0, 1) }}
                  </div>
                </div>
                <div v-else>
                  <img
                    :src="profileInfo.data.profile"
                    alt=""
                    class="w-10 h-10 rounded-full border-2 border-white"
                  />
                </div>
              </div>
              <div
                class="font-normal md:font-semibold text-black lg:text-black capitalize text-xs md:text-base hidden md:flex"
              >
                {{ profileInfo.data.user }}
              </div>
            </div>
          </router-link>  -->
          <div class="mr-10 flex items-center gap-10">
            <div>
              <router-link :to="{ name: 'Mycourse' }" class="text-sm text-white hover:text-white font-medium">My
                Courses</router-link>
            </div>
            <div>
              <router-link :to="{ name: 'Community' }"
                class="text-sm text-white hover:text-white font-medium">Community</router-link>
            </div>
            <div>
              <router-link :to="{ name: 'StudentCourseCurriculum' }"
                class="text-sm text-white hover:text-white font-medium">Curriculum</router-link>
            </div>
          </div>


          <div class="flex items-center mr-10 cursor-pointer relative">

            <img src="@/assets/notification.png" width="24" height="24" @click="MenuToggle('notification')" />

            <div class="absolute right-0 px-6 py-3 top-12 z-50 bg-white rounded-2xl shadow-lg" style="width: 250px;"
              v-if="toggleMenuName == 'notification'">
              <div>
                <p class="font-bold text-base">Notifications</p>
              </div>
              <div class="flex flex-col gap-2">
                <div class="flex flex-col border pt-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div>
                <div class="flex flex-col border pt-2 px-4 rounded-lg">
                  <p class="font-semibold text-xs">New course added for you!</p>
                  <p class="font-semibold text-xs">11/02/2025</p>
                </div>
              </div>
            </div>

            <!-- <div v-if="profileInfo.data.profile == ''">
                  <div
                    class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold pb-0"
                  >
                    {{ profileInfo.data.user.slice(0, 1) }}
                  </div>
                </div>
                <div v-else>
                  <img
                    :src="profileInfo.data.profile"
                    alt=""
                    class="w-10 h-10 rounded-full border-2 border-white"
                  />
                </div> -->
          </div>



          <div class="relative">
            <div @click="MenuToggle('profile')" v-if="isAuthenticated && profileInfo != null"
              class="flex items-center cursor-pointer gap-2">


              <div class="flex items-center">
                <!-- <p class="text-white"> {{ profileInfo.data.profile }}</p> -->
                <div v-if="profileInfo.data.profile != ''">

                  <img :src="profileInfo.data.profile" alt="" class="w-10 h-10 rounded-full border-2 border-white" />
                  <!-- <div
                    class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white text-black font-semibold pb-0"
                  >
                    {{ profileInfo.data.user.slice(0, 1) }}
                  </div> -->
                </div>
                <div v-else>
                  <img src="@/assets/new/image.png" alt="" class="w-10 h-10 rounded-full border-2 border-white" />
                </div>
              </div>
              <!-- <div
                class="font-normal md:font-semibold text-black lg:text-black capitalize text-xs md:text-base hidden md:flex"
              >
                {{ profileInfo.data.user }}
              </div> -->
            </div>

            <div class="absolute right-0 top-14 z-50 bg-white rounded-2xl shadow-lg" style="width: 180px;"
              v-if="toggleMenuName == 'profile'">
              <div class="flex items-center gap-2 py-3 px-6 cursor-pointer">
                <img src="@/assets/new/profile.png" width="20" height="20" alt="">
                <router-link :to="{ name: 'UserProfile' }" class="text-sm font-medium"
                  style="color: #333333 !important;">Profile</router-link>
              </div>
              <hr />
              <!-- <div class="">
                  <router-link :to="{ name: 'Mycourse' }" class="text-sm text-gray-600 font-medium"
                    >My Courses</router-link>
                </div>
                <div>
                  <router-link :to="{ name: 'Community' }" class="text-sm text-gray-600 font-medium"
                    >Community</router-link>
                </div> 
                <div>
                  <router-link :to="{ name: 'StudentCourseCurriculum' }" class="text-sm text-gray-600 font-medium"
                    >Course Curriculum</router-link>
                </div> -->

              <div class="flex items-center gap-2 ml-1 py-1 px-6 cursor-pointer">
                <!-- <font-awesome-icon :icon="['fas', 'fa-sign-out-alt']" /> -->
                <img src="@/assets/new/logout.png" width="15" height="15" alt="">
                <p class="text-sm text-[#F87474] font-semibold mt-2" style="color: #F87474 !important;"
                  @click="LogOutModal = !LogOutModal">Logout</p>
              </div>
            </div>

          </div>



          <div class="spacer header-right"></div>
          <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
            class="hidden lg:flex button-primary header-button w-button">Login</router-link>

          <!-- <button
            v-if="isAuthenticated"
            class="hidden lg:flex button-primary header-button w-button"
            @click="LogOutModal = !LogOutModal"
          >
            Logout
          </button> -->


          <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
            <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"
      style="height: 6653.35px; display: block">
      <nav role="navigation" class="nav-menu w-nav-menu" style="
          transform: translateY(0px) translateX(0px);
          transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
        " data-nav-menu-open="">
        <a href="/" class="text-white">Home</a>
        <!-- <a href="#" class="text-white">Companies</a> -->
        <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8" class=""
          style="max-width: 1209px">
          <div class="header-dropdown-toggle w-dropdown-toggle w--nav-dropdown-toggle-open" id="w-dropdown-toggle-0"
            aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
            <router-link :to="{ name: 'Courses' }" class="text-white">Courses&nbsp;&nbsp;&nbsp;</router-link>
          </div>
        </div>
        <!-- <a
          class="text-white"
          title="goal1.academics@onlinejain.com"
          href="mailto:goal1.academics@onlinejain.com"
        >
          Support
        </a> -->
        <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
          class="text-white flex lg:hidden hover:text-white">Login</router-link>
        <button v-else class="text-white flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">
          Logout
        </button>
      </nav>
    </div>


    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />


  </div>



  <!-- instructor -->
  <div v-if="isAuthenticated && userInfo.user.permission == 'instructor'" data-collapse="medium"
    data-animation="default" data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo"
    data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200"
    style="background-color: #0F172A; padding: 10px 0px">
    <div class="container-default-1209px w-container">
      <div class="header-wrapper">
        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf" class="split-content header-left">
          <router-link :to="{ name: 'InstructorCourse' }" class="brand w-nav-brand w--current flex items-center">
            <img src="@/assets/new/moun.png" alt="" class="w-40 py-4 object-fit" />
            <!-- <img
              src="@/assets/logo/galecta-wlogo.png"
              alt=""
              class="w-60 object-contain bg-white p-2 rounded-md"
            /> -->
            <!-- <p class="pt-2 text-white">SOUNDARYA EDUCATIONAL TRUST</p> -->
          </router-link>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <!-- <router-link :to="{name:'InstructorHome'}" class="text-white w--current">Home</router-link> -->
            <!-- <a href="index.html" aria-current="page" class="text-white w--current">Home</a> -->
            <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8"
              class="header-dropdown w-dropdown">
              <!-- <div class="header-dropdown-toggle w-dropdown-toggle"> -->
              <div class="header-dropdown-toggle w-dropdown-toggle">
                <div class="text-white flex">
                  Menu<span class="dropdown-icon px-2 "></span>
                </div>
              </div>
              <nav class="dropdown-list w-dropdown-list hidden">
                <div class="menu-grid-wrapper">
                  <div class="w-layout-grid menu-grid">
                    <div>
                      <!-- <h4 class="mega-menu-title hidden-mobile">Pages</h4> -->
                      <div class="menu-2-columns">
                        <div class="mega-menu-column-1">
                          <router-link :to="{ name: 'InstructorHome' }"
                            class="mega-menu-link w--current">Home</router-link>
                          <!-- <a href="index.html" aria-current="page" class="mega-menu-link w--current">Home</a> -->
                          <!-- <router-link :to="{name:'Courses'}" class="mega-menu-link">Courses</router-link> -->
                          <router-link :to="{ name: 'AddCourse' }" class="mega-menu-link">Add Courses</router-link>
                          <router-link :to="{ name: 'NewCourse' }" class="mega-menu-link">Add Courses With
                            AI</router-link>
                          <router-link :to="{ name: 'NewCourse' }" class="mega-menu-link">Add Course
                            Curriculam</router-link>
                          <router-link :to="{ name: 'InstructorCourse' }" class="mega-menu-link">Update
                            Course</router-link>
                        </div>
                        <div class="mega-menu-column-3">
                          <router-link :to="{ name: 'Blog' }" class="mega-menu-link">Blog Post</router-link>
                          <router-link :to="{ name: 'AddBlogFrom' }" class="mega-menu-link">Add New Blog</router-link>
                          <!-- <router-link :to="{name:'WebinarListInstructor'}" class="mega-menu-link">Events list</router-link> -->
                          <router-link :to="{ name: 'WebinarAdd' }" class="mega-menu-link">Add New Events</router-link>
                          <!-- <a href="https://academytemplate.webflow.io/event/brand-identity-design-qa-with-sophie-moore" class="mega-menu-link">Individual Event</a> -->
                        </div>
                        <div class="mega-menu-column-3">
                          <!-- <a href="contact-us.html" class="mega-menu-link">Contact</a> -->
                          <router-link :to="{ name: 'About' }" class="mega-menu-link">About</router-link>
                          <router-link :to="{ name: 'contact' }" class="mega-menu-link">Contact</router-link>
                        </div>
                      </div>
                    </div>
                    <div class="mega-menu-column-4">
                      <!-- <h4 class="mega-menu-title">Utility Pages</h4>
                                        <a href="https://academytemplate.webflow.io/401" class="mega-menu-link">Password</a>
                                        <a href="utility-pages/licenses.html" class="mega-menu-link">Licenses</a>
                                        <a href="utility-pages/start-here.html" class="mega-menu-link">Start Here</a>
                                        <a href="utility-pages/changelog.html" class="mega-menu-link">Changelog</a> -->
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </nav>
        </div>


        <div class="relative top-4">
          <div @click="MenuToggle('profile')" v-if="isAuthenticated && profileInfo != null"
            class="flex items-center cursor-pointer gap-2">


            <div class="flex items-center">
              <!-- <p class="text-white"> {{ profileInfo.data.profile }}</p> -->
              <div v-if="profileInfo.data.profile != ''">

                <img :src="profileInfo.data.profile" alt="" class="w-10 h-10 rounded-full border-2 border-white" />
                <!-- <div
                    class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white text-black font-semibold pb-0"
                  >
                    {{ profileInfo.data.user.slice(0, 1) }}
                  </div> -->
              </div>
              <div v-else>
                <img src="@/assets/new/image.png" alt="" class="w-10 h-10 rounded-full border-2 border-white" />
              </div>
            </div>
            <!-- <div
                class="font-normal md:font-semibold text-black lg:text-black capitalize text-xs md:text-base hidden md:flex"
              >
                {{ profileInfo.data.user }}
              </div> -->
          </div>

          <div class="absolute right-0 top-14 z-50 bg-white rounded-2xl shadow-lg" style="width: 180px;"
            v-if="toggleMenuName == 'profile'">
            <div class="flex items-center gap-2 py-3 px-6 cursor-pointer">
              <img src="@/assets/new/profile.png" width="20" height="20" alt="">
              <router-link :to="{ name: 'instructorCourse' }" class="text-sm font-medium"
                style="color: #333333 !important;">Profile</router-link>
            </div>
            <hr />
            <!-- <div class="">
                  <router-link :to="{ name: 'Mycourse' }" class="text-sm text-gray-600 font-medium"
                    >My Courses</router-link>
                </div>
                <div>
                  <router-link :to="{ name: 'Community' }" class="text-sm text-gray-600 font-medium"
                    >Community</router-link>
                </div> 
                <div>
                  <router-link :to="{ name: 'StudentCourseCurriculum' }" class="text-sm text-gray-600 font-medium"
                    >Course Curriculum</router-link>
                </div> -->

            <div class="flex items-center gap-2 ml-1 py-1 px-6 cursor-pointer">
              <!-- <font-awesome-icon :icon="['fas', 'fa-sign-out-alt']" /> -->
              <img src="@/assets/new/logout.png" width="15" height="15" alt="">
              <p class="text-sm text-[#F87474] font-semibold mt-2" style="color: #F87474 !important;"
                @click="LogOutModal = !LogOutModal">Logout</p>
            </div>
          </div>

        </div>

        <!-- <div
          data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313"
          class="split-content header-right"
        >
          <router-link
            :to="{ name: 'instructorCourse' }"
            class="w-auto md:w-56 px-0 z-50 flex justify-center"
          >
            <div
              v-if="isAuthenticated && profileInfo != null"
              class="flex items-center cursor-pointer gap-2"
            >
              <div class="flex items-center">
                <div v-if="profileInfo.data.profile == ''">
                  <div
                    class=" rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold"
                  >
                   
                     <img
                    src="@/assets/user.webp"
                    alt=""
                    class="w-10 h-10 rounded-full"
                  />
                  </div>
                </div>
                <div v-else>
                  <img
                    :src="profileInfo.data.profile" 
                    alt=""
                    class="w-10 h-10 rounded-full border-2 border-white"
                  />
                   
                </div>
              </div>
              <div
                class="font-normal md:font-semibold text-black lg:text-black capitalize text-xs md:text-base hidden md:flex"
              >
                {{ profileInfo.data.user }}
              </div>
            </div>
          </router-link>
          <div class="spacer header-right"></div>

          <router-link
            v-if="!isAuthenticated"
            :to="{ name: 'Login' }"
            class="hidden lg:flex button-primary header-button w-button"
            >Login</router-link
          >
          <button
            v-else
            class="hidden lg:flex button-primary header-button w-button"
            @click="LogOutModal = !LogOutModal"
          >
            Logout
          </button>
          <div
            data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4"
            class="menu-button lg:hidden"
          >
            <div
              @click="dropdownMenu()"
              class="flex flex-col gap-1.5 justify-center h-full"
            >
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
            </div>
          </div>
        </div> -->


      </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"
      style="height: 6653.35px; display: block">
      <nav role="navigation" class="nav-menu w-nav-menu" style="
          transform: translateY(0px) translateX(0px);
          transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
        " data-nav-menu-open="">
        <a href="/" class="text-white">Home</a>
        <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8"
          class="header-dropdown w-dropdown w--nav-dropdown-open" style="max-width: 1209px">
          <div @click="dropdownMob()" class="header-dropdown-toggle w-dropdown-toggle w--nav-dropdown-toggle-open"
            id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false"
            role="button" tabindex="0">
            <div class="text-white">
              Pages&nbsp;&nbsp;&nbsp;<span class="dropdown-icon" style=""></span>
            </div>
          </div>
          <nav class="dropdown-list w-dropdown-list w--nav-dropdown-list-open hidden" style="" id="w-dropdown-list-mob"
            aria-labelledby="w-dropdown-toggle-0">
            <div class="menu-grid-wrapper">
              <div class="w-layout-grid menu-grid">
                <div>
                  <h4 class="mega-menu-title hidden-mobile">Pages</h4>
                  <div class="menu-2-columns">
                    <div class="mega-menu-column-1">
                      <router-link :to="{ name: 'Home' }" class="mega-menu-link">Home</router-link>
                      <router-link :to="{ name: 'About' }" class="mega-menu-link">About</router-link>
                      <router-link :to="{ name: 'InstructorCourse' }"
                        class="mega-menu-link w--current">Courses</router-link>
                      <router-link :to="{ name: 'Mycourse' }" class="mega-menu-link">Add Course</router-link>
                      <router-link :to="{ name: 'Mycourse' }" class="mega-menu-link">Add Lesson</router-link>
                    </div>
                    <div class="mega-menu-column-3">
                      <a href="/blog" class="mega-menu-link" tabindex="0">Blog</a>
                      <a href="https://academytemplate.webflow.io/post/8-great-design-trends-that-are-coming-back"
                        class="mega-menu-link" tabindex="0">Blog Post</a>
                      <a href="/events" class="mega-menu-link" tabindex="0">Events</a><a
                        href="https://academytemplate.webflow.io/event/brand-identity-design-qa-with-sophie-moore"
                        class="mega-menu-link" tabindex="0">Individual Event</a>
                      <a href="/teachers" class="mega-menu-link" tabindex="0">Teachers</a>
                    </div>
                    <div class="mega-menu-column-3">
                      <router-link :to="{ name: 'contact' }" class="mega-menu-link">Contact</router-link>
                    </div>
                  </div>
                </div>
                <!-- <div class="mega-menu-column-4">
                                <h4 class="mega-menu-title">Utility Pages</h4><a href="/utility-pages/style-guide" class="mega-menu-link" tabindex="0">Styleguide</a><a href="https://academytemplate.webflow.io/404" class="mega-menu-link" tabindex="0">404 Not Found</a><a href="https://academytemplate.webflow.io/401" class="mega-menu-link" tabindex="0">Password</a><a href="/utility-pages/licenses" class="mega-menu-link" tabindex="0">Licenses</a><a href="/utility-pages/start-here" class="mega-menu-link" tabindex="0">Start Here</a><a href="/utility-pages/changelog" class="mega-menu-link" tabindex="0">Changelog</a><a href="http://brixtemplates.com/more-templates" class="mega-menu-link special" tabindex="0">Browse More Templates</a>
                            </div> -->
              </div>
            </div>
          </nav>
        </div>
        <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
          class="text-white flex lg:hidden hover:text-white">Login</router-link>
        <button v-else class="text-white flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">
          Logout
        </button>
      </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
  </div>

  <!-- Super Admin -->
  <div v-if="isAuthenticated && userInfo.user.permission == 'super_admin'" data-collapse="medium"
    data-animation="default" data-duration="1000" data-easing="ease-out-expo" data-easing2="ease-out-expo"
    data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc" role="banner" class="header w-nav border-b border-gray-200"
    style="background-color: #105c8e; padding: 10px 0px">
    <div class="container-default-1209px w-container">
      <div class="header-wrapper">
        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf" class="split-content header-left">
          <router-link :to="{ name: 'InstructorCourse' }" class="brand w-nav-brand w--current flex items-center">
            <!-- <img
              src="@/assets/logo/galecta-wlogo.png"
              alt=""
              class="w-60 object-contain bg-white p-2 rounded-md"
            /> -->
            <img src="@/assets/logo/galecta-wlogo.png" alt="" class="w-40 py-4 object-fit" />
            <!-- <p class="pt-2 text-white">SOUNDARYA EDUCATIONAL TRUST</p> -->
          </router-link>
          <nav role="navigation" class="nav-menu w-nav-menu">
            <!-- <router-link :to="{name:'InstructorHome'}" class="text-white w--current">Home</router-link> -->
            <!-- <a href="index.html" aria-current="page" class="text-white w--current">Home</a> -->
            <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8"
              class="header-dropdown w-dropdown">
              <!-- <div class="header-dropdown-toggle w-dropdown-toggle"> -->
              <div class="header-dropdown-toggle w-dropdown-toggle">
                <div class="text-white">
                  Menu<span class="dropdown-icon px-2"></span>
                </div>
              </div>
              <nav class="dropdown-list w-dropdown-list hidden">
                <div class="menu-grid-wrapper">
                  <div class="w-layout-grid menu-grid">
                    <div>
                      <!-- <h4 class="mega-menu-title hidden-mobile">Pages</h4> -->
                      <div class="menu-2-columns">
                        <div class="mega-menu-column-1">
                          <router-link :to="{ name: 'InstructorHome' }"
                            class="mega-menu-link w--current">Home</router-link>
                          <!-- <a href="index.html" aria-current="page" class="mega-menu-link w--current">Home</a> -->
                          <!-- <router-link :to="{name:'Courses'}" class="mega-menu-link">Courses</router-link> -->
                          <router-link :to="{ name: 'AddCourse' }" class="mega-menu-link">Add Courses</router-link>
                          <router-link :to="{ name: 'InstructorCourse' }" class="mega-menu-link">Update
                            Course</router-link>
                        </div>
                        <div class="mega-menu-column-3">
                          <router-link :to="{ name: 'Blog' }" class="mega-menu-link">Blog Post</router-link>
                          <router-link :to="{ name: 'AddBlogFrom' }" class="mega-menu-link">Add New Blog</router-link>
                          <router-link :to="{ name: 'WebinarListInstructor' }" class="mega-menu-link">Events
                            list</router-link>
                          <router-link :to="{ name: 'WebinarAdd' }" class="mega-menu-link">Add New Events</router-link>
                          <!-- <a href="https://academytemplate.webflow.io/event/brand-identity-design-qa-with-sophie-moore" class="mega-menu-link">Individual Event</a> -->
                        </div>
                        <div class="mega-menu-column-3">
                          <!-- <a href="contact-us.html" class="mega-menu-link">Contact</a> -->
                          <router-link :to="{ name: 'About' }" class="mega-menu-link">About</router-link>
                          <router-link :to="{ name: 'contact' }" class="mega-menu-link">Contact</router-link>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="mega-menu-column-4">
                                        <h4 class="mega-menu-title">Utility Pages</h4>
                                        <a href="https://academytemplate.webflow.io/401" class="mega-menu-link">Password</a>
                                        <a href="utility-pages/licenses.html" class="mega-menu-link">Licenses</a>
                                        <a href="utility-pages/start-here.html" class="mega-menu-link">Start Here</a>
                                        <a href="utility-pages/changelog.html" class="mega-menu-link">Changelog</a>
                                    </div> -->
                  </div>
                </div>
              </nav>
            </div>
          </nav>
        </div>
        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
          <router-link :to="{ name: 'instructorCourse' }" class="w-auto md:w-56 px-0 z-50 flex justify-center">
            <div v-if="isAuthenticated && profileInfo != null" class="flex items-center cursor-pointer gap-2">
              <div class="flex items-center">
                <div v-if="profileInfo.data.profile == ''">
                  <div
                    class="border rounded-full w-10 h-10 self-center text-center flex items-center justify-center text-xl capitalize bg-white font-semibold">
                    {{ profileInfo.data.user.slice(0, 1) }}
                  </div>
                </div>
                <div v-else>
                  <img :src="profileInfo.data.profile" alt="" class="w-10 h-10 rounded-full border-2 border-white" />
                </div>
              </div>
              <div
                class="font-normal md:font-semibold text-black lg:text-black capitalize text-xs md:text-base hidden md:flex">
                {{ profileInfo.data.user }}
              </div>
            </div>
          </router-link>
          <div class="spacer header-right"></div>

          <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
            class="hidden lg:flex button-primary header-button w-button">Login</router-link>
          <button v-else class="hidden lg:flex button-primary header-button w-button"
            @click="LogOutModal = !LogOutModal">
            Logout
          </button>
          <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
            <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"
      style="height: 6653.35px; display: block">
      <nav role="navigation" class="nav-menu w-nav-menu" style="
          transform: translateY(0px) translateX(0px);
          transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
        " data-nav-menu-open="">
        <a href="/" class="text-white">Home</a>
        <div data-hover="false" data-delay="0" data-w-id="8d41c972-856f-446c-3022-9951bcba0af8"
          class="header-dropdown w-dropdown w--nav-dropdown-open" style="max-width: 1209px">
          <div @click="dropdownMob()" class="header-dropdown-toggle w-dropdown-toggle w--nav-dropdown-toggle-open"
            id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false"
            role="button" tabindex="0">
            <div class="text-white">
              Pages&nbsp;&nbsp;&nbsp;<span class="dropdown-icon" style=""></span>
            </div>
          </div>
          <nav class="dropdown-list w-dropdown-list w--nav-dropdown-list-open hidden" style="" id="w-dropdown-list-mob"
            aria-labelledby="w-dropdown-toggle-0">
            <div class="menu-grid-wrapper">
              <div class="w-layout-grid menu-grid">
                <div>
                  <h4 class="mega-menu-title hidden-mobile">Pages</h4>
                  <div class="menu-2-columns">
                    <div class="mega-menu-column-1">
                      <router-link :to="{ name: 'Home' }" class="mega-menu-link">Home</router-link>
                      <router-link :to="{ name: 'About' }" class="mega-menu-link">About</router-link>
                      <router-link :to="{ name: 'InstructorCourse' }"
                        class="mega-menu-link w--current">Courses</router-link>
                      <router-link :to="{ name: 'Mycourse' }" class="mega-menu-link">Add Course</router-link>
                      <router-link :to="{ name: 'Mycourse' }" class="mega-menu-link">Add Lesson</router-link>
                    </div>
                    <div class="mega-menu-column-3">
                      <a href="/blog" class="mega-menu-link" tabindex="0">Blog</a>
                      <a href="https://academytemplate.webflow.io/post/8-great-design-trends-that-are-coming-back"
                        class="mega-menu-link" tabindex="0">Blog Post</a>
                      <a href="/events" class="mega-menu-link" tabindex="0">Events</a><a
                        href="https://academytemplate.webflow.io/event/brand-identity-design-qa-with-sophie-moore"
                        class="mega-menu-link" tabindex="0">Individual Event</a>
                      <a href="/teachers" class="mega-menu-link" tabindex="0">Teachers</a>
                    </div>
                    <div class="mega-menu-column-3">
                      <router-link :to="{ name: 'contact' }" class="mega-menu-link">Contact</router-link>
                    </div>
                  </div>
                </div>
                <div class="mega-menu-column-4">
                  <h4 class="mega-menu-title">Utility Pages</h4>
                  <a href="/utility-pages/style-guide" class="mega-menu-link" tabindex="0">Styleguide</a><a
                    href="https://academytemplate.webflow.io/404" class="mega-menu-link" tabindex="0">404 Not
                    Found</a><a href="https://academytemplate.webflow.io/401" class="mega-menu-link"
                    tabindex="0">Password</a><a href="/utility-pages/licenses" class="mega-menu-link"
                    tabindex="0">Licenses</a><a href="/utility-pages/start-here" class="mega-menu-link"
                    tabindex="0">Start Here</a><a href="/utility-pages/changelog" class="mega-menu-link"
                    tabindex="0">Changelog</a><a href="http://brixtemplates.com/more-templates"
                    class="mega-menu-link special" tabindex="0">Browse More Templates</a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
          class="text-white flex lg:hidden hover:text-white">Login</router-link>
        <button v-else class="text-white flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">
          Logout
        </button>
      </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
  </div>

  <!-- without Login -->
  <div v-if="!isAuthenticated" data-collapse="medium" data-animation="default" data-duration="1000"
    data-easing="ease-out-expo" data-easing2="ease-out-expo" data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cc"
    role="banner" class="header w-nav border-b border-gray-200" style="background-color: #105c8e; padding: 10px 0px">
    <div class="container-default-1209px w-container">
      <div class="header-wrapper">
        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a28799302cf">
          <router-link :to="{ name: 'Home' }" class="brand w-nav-brand w--current flex items-center">
            <img src="@/assets/logo/galecta-wlogo.png" alt="" class="w-40 py-4 object-fit" />
            <!-- <p class="pt-2 text-white">SOUNDARYA EDUCATIONAL TRUST</p> -->
          </router-link>
          <!-- <nav role="navigation" class="nav-menu w-nav-menu">
                    <router-link :to="{name:'Home'}" class="text-white w--current">Home</router-link>
                    <router-link :to="{name:'Courses'}" class="text-white">Courses</router-link>
                </nav> -->
        </div>
        <div data-w-id="483cd0ea-0ffd-1df5-02c0-5a2879930313" class="split-content header-right">
          <div class="spacer header-right"></div>

          <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
            class="hidden lg:flex button-primary header-button w-button">Login</router-link>
          <button v-else class="hidden lg:flex button-primary header-button w-button"
            @click="LogOutModal = !LogOutModal">
            Logout
          </button>
          <div data-w-id="63a3d12c-d009-7b48-0e75-aa6a4e6df1c4" class="menu-button lg:hidden">
            <div @click="dropdownMenu()" class="flex flex-col gap-1.5 justify-center h-full">
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
              <div class="border-2 border-gray-600 mx-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="is_dropdown" class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"
      style="height: 6653.35px; display: block">
      <nav role="navigation" class="nav-menu w-nav-menu" style="
          transform: translateY(0px) translateX(0px);
          transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
        " data-nav-menu-open="">
        <!-- <a href="/" class="text-white">Home</a> -->
        <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }"
          class="text-white flex lg:hidden hover:text-white">Login</router-link>
        <button v-else class="text-white flex lg:hidden hover:text-white" @click="LogOutModal = !LogOutModal">
          Logout
        </button>
      </nav>
    </div>
    <LogOutPopUp v-if="LogOutModal" @logOut="logOut" @cancel="LogOutModal = !LogOutModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import $ from "jquery";
import LogOutPopUp from "@/components/LogOutPopUp";

export default {
  computed: {
    ...mapGetters(["isAuthenticated", "userInfo"]),
  },
  components: {
    LogOutPopUp,
  },

  props: {
    active: String,
  },

  data: () => {
    return {
      popUp: false,
      mailid: null,
      minimize: true,
      LogOutModal: false,
      info: null,
      profileInfo: null,
      isOption: false,
      is_dropdown: false,
      profileMenu: false,
      notificationMenu: false,
      toggleMenuName: null,
    };
  },

  created() {
    if (this.userInfo) {
      console.log("start");
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;
      axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    } else {
      console.log("Not Login");
    }
  },
  mounted() {
    $(".header-dropdown-toggle").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $("nav.dropdown-list").toggleClass("hidden");
    });

    $("body").on("click", function (e) {
      if (
        !$("nav.dropdown-list").hasClass("hidden") &&
        !$(e.target).closest("nav.dropdown-list").length
      )
        $("nav.dropdown-list").addClass("hidden");
    });
  },

  methods: {
    ...mapActions(["logOutUser"]),


    MenuToggle(name) {
      if (this.toggleMenuName == name) {
        this.toggleMenuName = null;
      }
      else {
        this.toggleMenuName = name;
      }
    },

    notificationToggle() {
      if (this.notificationMenu) {
        this.notificationMenu = false;
      }
      else {
        this.notificationMenu = true;
      }
    },

    profileMenuToggle() {
      if (this.profileMenu) {
        this.profileMenu = false;
      }
      else {
        this.profileMenu = true;
      }
    },

    dropdownMob() {
      document.getElementById("w-dropdown-list-mob").classList.toggle("hidden");
    },

    dropdownMenu() {
      this.is_dropdown = !this.is_dropdown;
    },
    async logOut() {
      await this.logOutUser();
      this.LogOutModal = false;
      this.$router.push({
        name: "Login",
      });
    },
    async sendmail(event) {
      const HeaderVal = {
        subject: event.target.elements.subject.value,
        time: "",
        content_1: event.target.elements.content.value,
        content_2: "",
        content_3: "",
      };
      console.log("this.userInfo", this.userInfo.user.id);
      // await axios.post(`${process.env.VUE_APP_API}/instructor_send_personal/${this.mailid.id}/?Key=${this.userInfo.token_key}`, HeaderVal)
      //     .then(response => {
      //         if (response.data.message == 'successfully Email send') {
      //             this.messages = {
      //                 text: "Mail Sent successfully",
      //                 type: 'success',
      //             }
      //         } else {
      //             this.messages = {
      //                 text: " Event Faild",
      //                 type: 'error',
      //             }
      //         }
      //     })
      this.$refs.mailref.reset();
      this.popUp = false;
      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
      }, 1500);
    },

    dropDown() {
      this.isOption = !this.isOption;
      document
        .getElementsByClassName("downArrow")[0]
        .classList.toggle("rotateImg");
    },
  },
};
</script>

<style></style>
