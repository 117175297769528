<template lang="">
    <div v-if="assignmentInfo" class="flex" style="height:calc(100vh - 113px);">
        <NavigationInstructor actived="assignments" />
        <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
        <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false"
            @confirmClick="deleteAssignment(idpass)" />
        <div class="w-full h-full">

            <!-- Breadcrumb -->
            <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'super_admin'" class="text-gray-500 text-sm">
                    <router-link :to='{name :"organizationCourse",params:{slug_id:this.userInfo.slug_id}}'>
                        Dashboard
                    </router-link> >
                </li>
                <li v-else class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name :'InstructorStudents'}">
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Assignments

                </li>
            </ol>
        </div> -->

            <div class="flex flex-col w-full h-full gap-1 px-1 lg:px-10 py-1 relative  lg:mt-0 overflow-y-auto ">
                
                <div class="flex items-center mt-5 justify-between py-3">
                    <div
                        class="w-full flex justify-center lg:justify-start py-1 px-1 text-gray-900 bg-white border-gray-300 text-xl capitalize">
                        <p class="mb-0"> {{course_name}} </p>
                    </div>
                    <div>
                            <div class="flex items-center justify-center w-80 cursor-pointer "
                                @click="this.assignmentPopup=!this.assignmentPopup">
                                <!-- <img
                                    class='h-5 w-5 md:h-8 md:w-8 ' src="../../assets/Addbutton.svg" /> -->
                                <p class="px-1 md:px-2 py-1 text-sm md:text-base mb-0 primary-btn">Add Assignment </p>
                            </div>
                        </div>
                </div>
              

                <p class="px-1 py-1 text-sm md:text-xl text-gray-700 rounded-l select-none"></p>

                <main
                    class="flex flex-wrap justify-start items-start gap-4 w-full mx-auto " style="height:97%">
                    <div class="h-auto flex gap-4 flex-wrap py-2">
                        <div :id="'main'+assignment.id" v-for="assignment in assignmentInfo.data" :keys="assignment"
                            @mouseenter="editShowfn('main'+assignment.id)"
                            class="maincontent border w-80 p-1 px-2 md:p-4  flex flex-col gap-2 bg-white rounded-3xl shadow-lg relative">

                            <div class="">
                                <!-- <p class="text-xs text-red-400 px-1" style="height:12%">Due Date</p>
                                <div style="height:88%">
                                    <p class="text-4xl font-bold lg:text-4xl 2xl:text-5xl text-gray-400">
                                        {{assignment.display_date.substring(0,2)}}
                                    </p>
                                    <p class="text-base md:text-xl text-gray-400">
                                        {{assignment.display_date.substring(2)}}
                                    </p>
                                </div> -->
                                <!-- <div class="absolute top-1 right-1 block lg:hidden">
                                    <div class="flex items-center gap-4">
                                        <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer"
                                            title="Edit Assignment"
                                            @click="this.assignmentEditPopup=!this.assignmentEditPopup;this.editvalues=assignment;">
                                        <font-awesome-icon
                                            class="text-base text-red-400 self-center h-6 w-6 cursor-pointer"
                                            :icon="['fas','trash-alt']"
                                            @click="this.idpass=assignment.id;this.titlepass=assignment.title;this.popupwindow=true" />
                                    </div>
                                </div> -->
                            </div>

                            <div class="flex flex-col justify-between" style="height:100%">

                                <router-link :to="{name:'AssignmentSubmitted',params:{assignment_id : assignment.id}}"
                                    class="flex flex-col gap-3 py-10 px-5">
                                    <p class="text-sm lg:text-2xl font-semibold text-gray-900 text-justify mb-0">
                                        {{assignment.title.slice(0,125)}}
                                    </p>
                                    <p class="text-gray-900 ">Due Date : {{assignment.display_date}}</p>
                                    <p class="text-gray-900 font-bold text-sm">Description:</p>
                                    <p class="text-sm text-gray-500 mb-0">
                                        {{assignment.description.slice(0,300)}}</p>
                                </router-link> 


                                <div>
                                    <div
                                        class=" bg-white flex-col justify-center items-center w-full h-full">
                                        <!-- <router-link :to="{name:'AssignmentSubmitted',params:{assignment_id : assignment.id}}"
                                            class="h-1/2  flex px-4 items-center justify-center">
                                            <p class="text-lg text-gray-600 text-center hover:text-indigo-900">
                                                {{assignment.title}}</p>
                                        </router-link> -->
                                        <div class="flex gap-6 h-1/2 w-full items-start justify-end">
                                            <img src="@/assets/edit.svg" alt="" class="h-8 w-8 cursor-pointer"
                                                title="Edit assignment"
                                                @click="this.assignmentEditPopup=!this.assignmentEditPopup;this.editvalues=assignment;">
                                            <img src="@/assets/trash.svg" alt="" class="h-8 w-8 cursor-pointer"
                                                title="Delete assignment"
                                                @click="this.idpass=assignment.id;this.titlepass=assignment.title;this.popupwindow=true">
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <!-- <div :id="'edit'+assignment.id" @mouseleave="editShowoff('edit'+assignment.id)"
                                class="absolute hidden bg-white flex-col justify-center items-center w-full h-full  left-0 top-0 ">
                                <router-link :to="{name:'AssignmentSubmitted',params:{assignment_id : assignment.id}}"
                                    class="h-1/2  flex px-4 items-center justify-center">
                                    <p class="text-lg text-gray-600 text-center hover:text-indigo-900">
                                        {{assignment.title}}</p>
                                </router-link>
                                <div class="flex gap-6 h-1/2  w-full items-start justify-center">
                                    <img src="../../assets/edit.svg" alt="" class="h-8 w-8 cursor-pointer"
                                        title="Edit assignment"
                                        @click="this.assignmentEditPopup=!this.assignmentEditPopup;this.editvalues=assignment;">
                                    <img src="../../assets/trash.svg" alt="" class="h-8 w-8 cursor-pointer"
                                        title="Delete assignment"
                                        @click="this.idpass=assignment.id;this.titlepass=assignment.title;this.popupwindow=true">
                                </div>
                            </div> -->
                        </div>

                        <!-- <div>
                            <div class=" flex items-center justify-center w-80 maincontent cursor-pointer gradient-btn"
                                @click="this.assignmentPopup=!this.assignmentPopup">
                                <p class="px-1 md:px-2 py-1 text-sm md:text-base mb-0">Add Assignment </p><img
                                    class='h-5 w-5 md:h-8 md:w-8 ' src="../../assets/Addbutton.svg" />
                            </div>
                        </div> -->
                       
                    </div>
                </main>

                <!-- Add assignment PopuP -->
                <div v-if='this.assignmentPopup'
                    class="fixed w-full bg-black bg-opacity-75 px-2 md:px-4 lg:px-10 py-4 h-screen top-0 left-0 flex items-center"
                    style="z-index:5000">
                    
                    <div
                        class="pop-pop flex flex-col justify-center items-center mx-auto bg-white py-4 px-8 relative shadow-lg rounded-lg border border-gray-100">
                        
                        <div class="flex flex-row w-full justify-between bottom-3">
                            <p class='text-sm md:text-base lg:text-xl text-blue-600' style="font-weight: 600">Submit Assignment</p>
                            <div class=" cursor-pointer bg-white hover:bg-gray-100"
                                @click="this.assignmentPopup=false;this.assignmentEditPopup=false">
                                <div class=" hover:text-red-500 left-0 right-5 ">
                                    <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                                </div>
                            </div>
                        </div>

                        <div class="w-full mt-2 flex flex-col p-6 relative border border-gray-100 rounded-2xl">
                            <div class="w-full">
                                <form @submit.prevent="scheduleAssignment" ref="addassignment"
                                    class="flex flex-col gap-4 text-xs md:text-sm lg:text-base h-full">
                                    <div class="flex flex-col gap-2 w-full text-xs md:text-sm lg:text-base">
                                        <div class="w-full flex flex-col gap-1">
                                            <label for="title" class="text-left">Title</label>
                                            <input id="title" type="text"
                                                class="border border-gray-100 rounded text-xs md:text-sm lg:text-base" />
                                        </div>
                                        <div class="w-full flex flex-col gap-1">
                                            <label for="duedate" class="text-left">Submit date</label>
                                            <input id="duedate" type="date"
                                                class="border border-gray-100 rounded text-xs md:text-sm lg:text-base" />
                                        </div>
                                    </div>
                                    <div class="flex flex-col w-full gap-1">
                                        <label for="description" class="text-left">Description</label>
                                        <textarea id="description" rows="3" cols="10"
                                            class="border border-gray-100 h-full rounded text-xs md:text-sm lg:text-base" />
                                    </div>
                                    <div class="flex flex-col w-full">
                                        <label for="attachments" class="text-left">Attachments *</label>
                                        <input id="files" type="file"
                                            class="border bg-white border-gray-100 py-3 w-full rounded text-xs md:text-sm lg:text-base"
                                            @change="inputchange" required />
                                    </div>
                                    <div class="flex justify-end pb-4 mt-3">
                                        <button type="submit"
                                            class="text-black border border-black rounded-lg text-sm px-10 py-2">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                
                <!-- Edit assignment PopuP -->

                <div v-if='this.assignmentEditPopup'
                    class="fixed w-full bg-black bg-opacity-75 px-2 md:px-4 lg:px-10 py-4 h-screen top-0 left-0 flex items-center"
                    style="z-index:5000">
                    <div
                        class="h-auto flex flex-col justify-center items-center mx-auto bg-white py-4 px-8 relative shadow-lg rounded-lg border border-gray-100">
                        <p class='text-sm md:text-base text-gray-900'>Update Assignments</p>
                        <div class="flex absolute top-6 right-8 cursor-pointer border rounded bg-white hover:bg-gray-100 z-50"
                            @click="this.assignmentPopup=false;this.assignmentEditPopup=false">
                            <p class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close </p>
                            <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                        </div>
                        <div class='w-full'>
                            <form @submit.prevent="editAssignment(editvalues)" class='flex flex-col gap-4 h-full' style="width: 500px;">
                                <div class="flex gap-2 w-full text-xs md:text-sm lg:text-base">
                                    <div class="flex flex-col gap-2">
                                        <label for='title'>Title</label>
                                        <input id="title" type='text'
                                            class="border border-gray-300 text-xs md:text-sm lg:text-base rounded"
                                            v-model="editvalues.title" />
                                    </div>
                                   
                                </div>
                                <div class="flex flex-col gap-2">
                                        <label for="duedate">Due date</label>
                                        <input id='duedate' type="date"
                                            class="border border-gray-300 text-xs md:text-sm lg:text-base rounded"
                                            v-model="editvalues.duedate" />
                                    </div>
                                <div class="flex flex-col w-full  text-xs md:text-sm lg:text-base gap-2">
                                    <label for='description'>Description</label>
                                    <textarea id='description' rows="12" cols="10"
                                        class="p-4 border border-gray-300 h-full text-xs md:text-sm lg:text-base rounded"
                                        v-model="editvalues.description" />
                                </div>
                                <div>
                                    <button type="submit" class="gradient-btn">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <!-- <div v-if='this.assignmentEditPopup'
                    class="absolute w-full bg-gray-50 px-2 md:px-4 lg:px-10 py-4 h-full top-0 left-0 rounded">
                    <div
                        class="w-11/12 max-w-5xl h-auto flex flex-col justify-center items-center mx-auto bg-white py-4 px-8 relative shadow-lg rounded-lg border border-gray-100">
                        <p class='text-sm md:text-base text-gray-900'>Update Assignments</p>
                        <div class="flex absolute top-6 right-8 cursor-pointer border rounded bg-white hover:bg-gray-100 z-50"
                            @click="this.assignmentPopup=false;this.assignmentEditPopup=false">
                            <p class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close </p>
                            <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                        </div>
                        <div class='w-full'>
                            <form @submit.prevent="editAssignment(editvalues)" class='flex flex-col gap-4 h-full '>
                                <div class="flex gap-2 w-full text-xs md:text-sm lg:text-base">
                                    <div class="w-4/6 flex flex-col gap-2">
                                        <label for='title'>Title</label>
                                        <input id="title" type='text'
                                            class="border border-gray-300 text-xs md:text-sm lg:text-base rounded"
                                            v-model="editvalues.title" />
                                    </div>
                                    <div class="w-2/6 flex flex-col gap-2">
                                        <label for="duedate">Due date</label>
                                        <input id='duedate' type="date"
                                            class="border border-gray-300 text-xs md:text-sm lg:text-base rounded"
                                            v-model="editvalues.duedate" />
                                    </div>
                                </div>
                                <div class="flex flex-col w-full  text-xs md:text-sm lg:text-base gap-2">
                                    <label for='description'>Description</label>
                                    <textarea id='description' rows="12" cols="10"
                                        class="p-4 border border-gray-300 h-full text-xs md:text-sm lg:text-base rounded"
                                        v-model="editvalues.description" />
                                </div>
                                <div>
                                    <button type="submit" class="gradient-btn">
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> -->


            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import {
        mapGetters
    } from "vuex";

    import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
    import FlashMessage from "@/components/flashMessage.vue";
    import confirmPopup from "@/components/confirmPopup.vue";
    export default {
        data() {
            return {
                assignmentInfo: null,
                messages: {
                    text: "",
                    type: 'null',
                },
                popupwindow: false,
                titlepass: null,
                idpass: null,
                assignmentPopup: false,
                assignmentEditPopup: false,
                editvalues: null,
                editOptions: false

            }
        },

        props: ["course_id", "course_name"],

        components: {
            NavigationInstructor,
            FlashMessage,
            confirmPopup

        },

        computed: {
            ...mapGetters(["userInfo"]),
        },

        async mounted() {
            await this.getdetails()
        },

        methods: {

            editShowfn(val) {
                let classVal = 'edit' + val.slice(4, 6)
                document.getElementById(classVal).classList.toggle('hidden')

            },
            editShowoff(val) {

                document.getElementById(val).classList.toggle('hidden')

            },
            async getdetails() {
                await axios.get(`${process.env.VUE_APP_API}/Inst_Assignment/view/?instructor_id=${this.userInfo.Instructor_id}&course_id=${this.course_id}&Key=${this.userInfo.token_key}`)
                    .then(response => {
                        if (Object.keys(response.data) == "error") {
                            console.log("Null")
                            this.assignmentInfo = response.data
                        } else {
                            this.assignmentInfo = response.data
                            this.assignmentInfo.data.forEach(element => {
                                element["display_date"] = new Date(element.duedate).toLocaleDateString('en-IN', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric'
                                })
                            })
                        }
                    })
            },

            async scheduleAssignment(el) {
                let fileVal = el.target.elements.files.files[0]
                let urls = `${process.env.VUE_APP_API}/assignment/new/?Key=${this.userInfo.token_key}`;
                let formData = new FormData();
                formData.append("file", fileVal);
                formData.append("title", el.target.elements.title.value);
                formData.append("description", el.target.elements.description.value);
                formData.append("duedate", el.target.elements.duedate.value);
                formData.append("assig_status", "false");
                formData.append("instructor_id", this.userInfo.Instructor_id);
                formData.append("organizes_id", this.userInfo.organizes_id);
                formData.append("course_id", this.course_id);

                await axios.post(urls, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    console.log(response.data)
                    if (response.data.message == "your Assignment added successfully") {
                        this.messages = {
                            text: "Assignment Added successfully",
                            type: 'success',
                        }
                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                            this.$refs.addassignment.reset();
                            this.assignmentPopup = false;
                            this.getdetails()
                        }, 1500)
                       
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
                // this.$refs.addassignment.reset();
                // this.assignmentPopup = false
                // setTimeout(() => {
                //     this.messages = {
                //         text: "",
                //         type: 'null',
                //     }
                //     this.getdetails()
                // }, 1500)

            },

            async deleteAssignment(data) {
                this.popupwindow = false
                await axios.delete(`${process.env.VUE_APP_API}/assignment_deleted/${data}/?Key=${this.userInfo.token_key}`)
                    .then(response => {
                        response.data

                        if (response.data.message == "Assignment deleted successfully") {
                            this.messages = {
                                text: "Assignment Deleted successfully",
                                type: 'success',
                            }
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                        }

                    })

                setTimeout(async () => {
                    this.messages = {
                        text: "",
                        type: 'null',
                    }
                    await this.getdetails()
                }, 1500)

            },

            async editAssignment(data) {
                this.assignmentEditPopup = false
                var EditInfo = {
                    "instructor_id": data.instructor_id,
                    "organizes_id": data.organizes_id,
                    "course_id": data.course_id,
                    "title": data.title,
                    "description": data.description,
                    "duedate": data.duedate,
                    "assig_status": data.assig_status,
                    "file": data.file
                }
                await axios.put(`${process.env.VUE_APP_API}/assignment/updated/${data.id}/?Key=${this.userInfo.token_key}`, EditInfo)
                    .then(response => {
                        if (response.data.message == "Assignment updated successfully") {
                            this.messages = {
                                text: "Assignment Updated successfully",
                                type: 'success',
                            }
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                        }

                    })

                await this.getdetails()

                setTimeout(() => {
                    this.messages = {
                        text: "",
                        type: 'null',
                    }
                }, 1500)

            },

        },
    }
</script>

<style scoped>

    .primary-btn{
        background-color: #0085DB;
        color: white;
        font-size: 14px;
        border-radius: 10px !important;
    }

    p {
        margin-bottom: 0px;
    }

    #addbutton:hover {
        padding-right: 1px;
        border: 1px solid #ccc;

    }

    .maincontent {
        height: 25rem;
    }

    .pop-pop {
        height: 40rem;
        width: 35rem;
    }
</style>