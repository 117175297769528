<template>

  <div class="min-h-screen relative">

    <Navigation />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="min-h-screen w-full container-default-1209px w-container">
      
      <div> 
        <div class="flex flex-row items-center justify-between mt-4">
          <p class="my-6 blues text-4xl font-semibold">My Courses</p>
          <!-- <div class="my-6 blues text-xl font-medium">View All</div> -->
        </div>

        <div class="grid lg:grid-cols-3 gap-4 mt-5 pb-20">
          <div v-for="course in this.courseDetails" :key="course" class="heightc card1 p-6">
            <router-link :to="{ name: 'VideoRender', params: { courseid: course.id } }">
              <div class="relative flex justify-center items-center" style="height: 60%">
                <img v-if="course.thumbnail" class="object-cover object-center h-full w-full rounded-lg"
                  :src="course.thumbnail" alt="" />
                <img v-else class="object-cover object-center h-24 w-52 rounded" src="@/assets/coding.svg" alt="" />
                <!-- <button
                  class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
                >
                  {{ course.category }}
                </button> -->
              </div>

              <div class="text-center flex flex-col gap-1 justify-center" style="height: 45%">
                <p class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0 mt-2">{{ course.title }}</p>
                <p class="text-xs text-gray-600 px-4 text-left truncate mb-0">{{ course.sections.length }} Units</p>
                <div class="p-3">
                  <div class="flex justify-between">
                    <h2 class="text-sm text-left">In Progress</h2>
                    <h2 class="text-sm text-left">{{ progress }}%</h2>
                  </div>
                  <div class="relative w-full bg-gray-200 rounded-full h-4">
                    <div class="absolute left-0 top-0 h-4 bg-blue-600 rounded-full transition-all duration-300"
                      :style="{ width: progress + '%' }"></div>
                  </div>
                  <p class="text-center mt-2 text-gray-600"></p>
                </div>
                <div>
                  <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium">Continue Learning
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
  
    </div> 

    

    <!-- <div class="min-h-screen w-full container-default-1209px w-container mb-10">
      <p class="my-6">Your Courses ({{  this.courselist.length }})</p>

    
      <div
        class="grid lg:grid-cols-3 gap-4"
      >
        <div
          v-for="course in this.courseDetails"
          :key="course"
          class="h-80 card1 border"
        >
          <router-link
            :to="{ name: 'VideoRender', params: { courseid: course.id } }"
          >
            <div
              class="relative flex justify-center items-center"
              style="height: 75%"
            >
              <img
                v-if="course.thumbnail"
                class="object-cover object-center h-full w-full rounded-lg"
                :src="course.thumbnail"
                alt=""
              />
              <img
                v-else
                class="object-cover object-center h-24 w-52 rounded"
                src="@/assets/coding.svg"
                alt=""
              />
             
            </div>

            <div
              class="text-center flex flex-col gap-1 justify-center"
              style="height: 25%"
            >
              <p class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0">{{ course.title }}</p>
              <p class="text-xs text-gray-600 px-4 text-left truncate mb-0">{{ course.sections.length }} Units</p>
            </div>
          </router-link>
        </div>
      </div>
  
    </div> -->

    <!-- popUp page -->
    <div
      v-if="popUp && updateDetails != null"
      class="fixed w-full h-full bg-black bg-opacity-75 top-0"
      style="z-index: 5000"
    >
      <div
        class="w-full md:w-5/6 lg:w-4/6 xl:w-3/6 2xl:w-2/6 h-full mx-auto flex flex-col justify-center"
      >
        <div
          class="bg-white h-auto border flex flex-col gap-1 p-4 justify-center rounded-lg"
        >
          <div class="flex justify-between">
            <p class="text-gray-700 font-bold text-xl">Edit Details</p>
            <img
              src="https://img.icons8.com/cotton/54/undefined/delete-sign--v2.png"
              @click="popUp = false"
              class="absolute -top-4 right-0 md:-top-14 md:-right-14 z-50 cursor-pointer"
            />
          </div>
          <hr />
          <div class="flex gap-2">
            <p class="w-1/3">Designation</p>
            <input
              type="text"
              v-model="updateDetails.designation"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <hr />
          <div class="flex gap-2">
            <p class="w-1/3">Mobile</p>
            <input
              type="text"
              v-model="updateDetails.mobile"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <hr />
          <div class="flex gap-2">
            <p class="w-1/3">Address</p>
            <input
              type="text"
              v-model="updateDetails.address"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <hr />
          <div class="flex gap-2">
            <p class="w-1/3">Description</p>
            <input
              type="text"
              v-model="updateDetails.description"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <div class="flex gap-2">
            <p class="w-1/3">Website</p>
            <input
              type="text"
              v-model="updateDetails.website"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <div class="flex gap-2">
            <p class="w-1/3">Github</p>
            <input
              type="text"
              v-model="updateDetails.github"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <div class="flex gap-2">
            <p class="w-1/3">linked in</p>
            <input
              type="text"
              v-model="updateDetails.linkedin"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <div class="flex gap-2">
            <p class="w-1/3">Twitter</p>
            <input
              type="text"
              v-model="updateDetails.twitter"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <div class="flex gap-2">
            <p class="w-1/3">instagram</p>
            <input
              type="text"
              v-model="updateDetails.instagram"
              class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3"
            />
          </div>
          <div class="mt-2">
            <button
              @click="SubmitProfile()"
              type="submit"
              class="primary-color px-4 py-1 float-right border"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      messages: {
        text: "",
        type: "null",
      },
      selectedcontent: "Courses",
      courseDetails: null,
      boughtcourse: null,
      progress: 90,
    };
  },

  components: {
    Navigation,
    FlashMessage,
    Footer
  },

  // mounted(){
  //     this.selectedcontent = userId;
  // },

  computed: {
    ...mapGetters(["userInfo"]),
    // userId() {
    //     return this.$route.query.name;
    // },
  },

  async created() {
    await this.openProcess();
    let tempvar_courselist = [];
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => (this.courseDetails = response.data.data));
    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.boughtcourse = response.data.data;
      });
    this.boughtcourse.forEach((element) => {
      tempvar_courselist.push(element.course_id);
    });

    this.courseDetails = this.courseDetails.filter((course) => {
      if (tempvar_courselist.includes(course.id)) {
        return course;
      }
    });
  },

  methods: {
    async imageHandles() {
      this.profile = false;
      let headerprofile = new FormData();
      headerprofile.append("id", this.userInfo.user.id);
      headerprofile.append(
        "profile",
        document.querySelector("#image_uploads").files[0]
      );

      await axios
        .put(
          `${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`,
          headerprofile
        )
        .then((response) => {
          if (response.data.message == "profile Data Updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        })
        .catch((error) => {
          this.messages = {
            text: error.response.data.error,
            type: "error",
          };
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },

    async onUpload() {
      // upload file
      await axios.post("my-domain.com/file-upload", this.selectedFile);
    },

    async openProcess() {
      axios
        .get(
          `${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log(res);
          this.tnxDetails = res.data;
        });
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    },

    async ProfileEdit() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      this.popUp = true;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`
        )
        .then((response) => (this.updateDetails = response.data));
    },

    async SubmitProfile() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      let update = this.updateDetails;
      this.popUp = false;
      // console.log("updateDetails",updateDetails)

      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`,
          update
        )
        .then((response) => {
          if (response.data.message == "Profile updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },
  },
};
</script>

<style scoped>
#image_uploads {
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 4px black;
  width: 350px;
  outline: none;
}

.prime {
  color: #333333 !important;
}

.blues {
  color: #0F172A !important;
}

.bluesbg {
  background-color: #0F172A !important;
}

/* .width{
  height: 100% !important;
} */

.endss {
  display: flex !important;
  justify-items: end !important;
  justify-content: end !important;
  align-items: flex-end !important;
}

.relativep {
  position: relative !important;
}

.absolutes {
  position: absolute !important;
  top: 8% !important;
  right: 70% !important;
  z-index: 100 !important;
}

.contents {
  width: 40% !important;
}


.heightc {
  height: 500px !important;
}

.heightfull {
  height: 450px !important;
}
</style>
