<template>
    <div class="min-h-screen lg:h-screen overflow-y-auto relative">
        <!-- Breadcrumb -->
        <!-- <div>
        <ol class="flex gap-2 items-center border-b p-2">
            <li class="text-gray-500 text-sm">
                <router-link :to="{name : 'Dashboard'}">
                    Dashboard
                </router-link> >
            </li>

            <li class="text-gray-500 text-sm">

                Profile

            </li>
        </ol>
    </div> -->
        <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
        <confirmPopup v-if='popupwindow == true' :title="titlepass" @cancelClick="popupwindow = false"
            @confirmClick="deletecourse(idpass)" />
        <main id="main" v-if="profileInfo != '' && popUp == false && courseUpdate == false && thumbnailUpdate == false"
            class="min-h-screen w-full container-default-1209px w-container" style="padding:0px">

            <section class="w-full h-full">
                <main class="h-full my-4">
                    <section class="w-full flex items-center ">
                        <div
                            class="h-full bg-gray-100 bg-opacity-70 flex flex-col md:flex-row items-center w-full gap-4 px-4 rounded-lg relative">
                            <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white"
                                :class="{ 'bg-white border': this.selectedcontent == 'Courses' }"
                                @click="this.selectedcontent = 'Courses'">Courses</button>
                            <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white"
                                :class="{ 'bg-white border': this.selectedcontent == 'mail' }"
                                @click="this.selectedcontent = 'mail'">Send Mail</button>
                            <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white"
                                :class="{ 'bg-white border': this.selectedcontent == 'Profile' }"
                                @click="this.selectedcontent = 'Profile'">My Profile</button>
                            <!-- <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white" :class="{'bg-white border' : this.selectedcontent=='quiz'}" @click="this.selectedcontent='quiz'">Quizzes</button> -->
                            <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white"
                                :class="{ 'bg-white border': this.selectedcontent == 'about' }"
                                @click="this.selectedcontent = 'about'">About</button>
                            <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white"
                                :class="{ 'bg-white border': this.selectedcontent == 'coursepromt' }"
                                @click="this.selectedcontent = 'coursepromt'">Course Promt</button>
                            <button class="px-4 rounded-lg py-0.5 2xl:py-2 hover:bg-white"
                                :class="{ 'bg-white border': this.selectedcontent == 'unitpromt' }"
                                @click="this.selectedcontent = 'unitpromt'">Unit Promt</button>


                            <!-- <div @click="mailtemplate=true" class=' absolute md:top-3 right-0 top-0 md:right-10'>
                            <div id='mail' class='flex items-center float-right  '>
                                <img src="../../assets/mail.svg" alt="" class="h-8 w-8" /> <span class=''>Mail to</span>
                            </div>
                        </div> -->
                        </div>
                    </section>

                    <!-- User Profile -->
                    <section v-if="profileInfo && selectedcontent == 'about'" class="w-full h-full  py-4">
                        <div class="flex flex-col items-center gap-1">
                            <div v-if="profileInfo.data.profile == ''">
                                <div
                                    class="border rounded-full w-48 h-48 self-center text-center flex items-center justify-center text-6xl capitalize bg-white font-semibold ">
                                    {{ profileInfo.data.user.slice(0, 1) }}</div>
                            </div>
                            <!-- <img v-if="profileInfo.data.profile==''" src="https://play-lh.googleusercontent.com/x9oRbQI97H4tgr83GigLTcLHARlZBkQ4R9z3AHva-P8GeOGoqu4gCKk4MNDzEoRq_RU" alt="" class="w-48 h-48 rounded-full"> -->
                            <div v-else>
                                <img :src="profileInfo.data.profile" alt="" class="w-48 h-48 rounded-full ">
                            </div>
                            <!-- <button @click="profile= !profile" class='border px-1 rounded bg-teal-500 hover:bg-teal-700 text-white'>edit</button> -->
                            <img v-if="profile == false" src="../../assets/edit.svg" alt="" class="h-6 w-6"
                                title="Edit Course" @click="profile = true">
                            <img v-else class='h-5 w-5 md:h-6 md:w-6' src="../../assets/closebutton.svg"
                                @click="profile = false" />
                            <form @submit.prevent='imageHandles'>
                                <div v-if="profile" class='text-center flex flex-col '>
                                    <input type="file" id="image_uploads" name="image_uploads"
                                        accept=".jpg, .jpeg, .png, .webp" class=''>
                                    <button type="submit"
                                        class='border rounded mt-1 bg-teal-500 hover:bg-teal-700 text-white'>upload</button>
                                </div>
                            </form>
                            <div class="font-bold">{{ profileInfo.data.user }}</div>
                            <p class="text-sm text-gray-700">({{ profileInfo.data.designation }})</p>
                            <div class=" w-full flex items-center justify-center">
                                <div class="flex w-full h-12 justify-center ">
                                    <div class="inline-flex mt-2 gap-6 w-full justify-center items-center ">
                                        <!-- <a class="text-gray-500 hover:text-blue-600" href="profileInfo.data.github" target="blank">
                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                            </svg>
                        </a> -->
                                        <a :href="profileInfo.data.github" target="blank"
                                            :title="profileInfo.data.github">
                                            <img src="../../assets/githubprofile.svg" class="h-6 w-6 cursor-pointer"
                                                alt="">
                                        </a>
                                        <a class="ml-3 text-gray-500 hover:text-blue-400"
                                            :href="profileInfo.data.twitter" target="blank"
                                            :title="profileInfo.data.twitter">
                                            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                                <path
                                                    d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z">
                                                </path>
                                            </svg>
                                        </a>
                                        <a class="ml-3 text-gray-500 hover:text-pink-600"
                                            :href="profileInfo.data.instagram" target="blank"
                                            :title="profileInfo.data.instagram">
                                            <svg fill="none" stroke="currentColor" stroke-linecap="round"
                                                stroke-linejoin="round" stroke-width="2" class="w-6 h-6"
                                                viewBox="0 0 24 24">
                                                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01">
                                                </path>
                                            </svg>
                                        </a>
                                        <a class="ml-3 text-gray-500 hover:text-blue-500"
                                            :href="profileInfo.data.linkedin" target="blank"
                                            :title="profileInfo.data.linkedin">
                                            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round"
                                                stroke-linejoin="round" stroke-width="0" class="w-6 h-6"
                                                viewBox="0 0 24 24">
                                                <path stroke="none"
                                                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z">
                                                </path>
                                                <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="h-1/2 w-full flex justify-center py-2">
                            <div class="h-1/2 flex flex-col w-5/6">
                                <div class="flex flex-col gap-2 justify-center ">

                                    <div class="text-base ">
                                        <div class="text-gray-800">Address</div>
                                        <div class="text-gray-500 text-base">{{ profileInfo.data.address }}</div>
                                    </div>
                                    <hr>
                                    <div class=''>
                                        <router-link
                                            :to="{ name: 'password', params: { keyword: 'change', keyval: userInfo.slug_id } }"
                                            class='flex items-center gap-1 justify-start'>
                                            <img src="../../assets/passwordChange.svg" alt="" class="h-6 w-6" /> <span
                                                class='px-2 text-gray-600 hover:text-indigo-900 '>Change Password</span>
                                        </router-link>
                                    </div>
                                    <div @click="ProfileEdit" class="mt-2 relative">
                                        <button class=" gradient-btn">Edit</button>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </section>
                    <!-- Course -->
                    <section v-if="this.selectedcontent == 'Courses'" class="h-5/6 md:p-2">
                        <div class="flex flex-row items-center justify-between mt-4">
                            <!-- <p class="my-6 blues text-4xl font-semibold">Courses</p> -->
                            <!-- <div class="my-6 blues text-xl font-medium">View All</div> -->
                        </div>
                        <div class="grid lg:grid-cols-3 gap-4 mt-5 pb-20" style="scrollbar-color:gray white">
                            <section id="" v-for="courses in info" :key="courses" class=" card1 p-6">
                                <div class="" style="width:380px;height:320px">
                                    <div class="h-full flex flex-col bg-white">

                                        <div class="relative flex justify-center items-center" style="height: 60%">
                                            <img v-if="courses.thumbnail"
                                                class="object-cover object-center h-full w-full rounded-lg"
                                                :src="courses.thumbnail" alt="" />
                                            <img v-else class="object-cover object-center h-24 w-52 rounded"
                                                src="../../assets/coding.svg" alt="" />

                                            <!-- <button
                                                class="absolute right-0 top-0 text-white bg-pink-800 w-3/6 bg-opacity-70 text-base rounded-md">{{
                                                    courses.category }}</button>
                                            <router-link :to="{ name: 'preview', params: { courseid: courses.id } }"
                                                class="absolute right-0 top-6 text-center hover:text-white hover:bg-pink-900 text-white bg-pink-800 w-3/6 bg-opacity-70 text-base rounded-md">
                                                <button>Preview</button>
                                            </router-link>
                                            <div class="absolute right-0 bottom-0 bg-white w-14 h-10 flex justify-end px-2  items-end"
                                                style="border-top-left-radius: 3rem;">
                                                <img id="EditImg" src="../../assets/new/mage_edit.png" alt=""
                                                    class="w-8 pl-1 cursor-pointer" title="Edit Thumbnail"
                                                    @click="thumbnailUpdate = true; imgcourseId = courses.id">
                                            </div> -->
                                        </div>

                                        <div class="rounded" style="width:360px;height:160px">
                                            <div class="w-full h-full bg-white rounded">
                                                <section
                                                    class="border-b border-gray-300 w-full px-3 cursor-pointer py-2"
                                                    style="height:60%">
                                                    <div class="text-xs md:text-sm  2xl:text-lg text-gray-700 truncate"
                                                        style="height:40%" @click="redirectToDashboard(courses)">
                                                        {{ courses.title }}</div>
                                                    <div class="flex " style="height:60%">
                                                        <div class="flex items-center gap-2" style="width:70%">
                                                            <img v-if="courses.profile" :src="courses.profile" alt=""
                                                                class="h-5 w-5 md:w-7 md:h-7 rounded-full bg-gray-400 ">
                                                            <img v-else src="../../assets/instructorIcon.svg" alt=""
                                                                class="h-5 w-5 md:w-7 md:h-7 rounded-full bg-gray-400 ">
                                                            <div class="text-xs md:text-sm text-gray-700 ">
                                                                {{ courses.instructor }}</div>
                                                        </div>
                                                        <div class="flex gap-1 items-center justify-end"
                                                            style="width:30%">
                                                            <img id="EditImg" src="../../assets/new/basil_add-outline.png" alt="" class="h-6 w-6"
                                                                title="Add Lesson" @click="lessonEdit(courses.id)">
                                                            <img id="EditImg" src="../../assets/new/mage_edit.png" alt="" class="h-6 w-6"
                                                                title="Edit Course" @click="getdetails(courses)">
                                                            <img id="EditImg" src="../../assets/new/Vector.png" alt="" class="h-6 w-6"
                                                                title="Delete Course"
                                                                @click="titlepass = courses.title; popupwindow = true; idpass = courses.id">
                                                        </div>
                                                    </div>
                                                </section>
                                                <section class="flex justify-between items-end px-3" style="height:20%">
                                                    <!-- <div>2 Lesson</div> -->
                                                    <div class="text-xs md:text-sm text-gray-700 capitalize">
                                                        {{ courses.level }}</div>
                                                    <div class="text-xs md:text-sm text-gray-700 font-semibold">
                                                        {{ courses.displayprice }}</div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <!-- <div class="flex justify-center items-center" style="width:360px;height:320px">
                                <router-link :to="{ name: 'AddCourse' }" class=" flex justify-center items-center">
                                    <img src="../../assets/Addbutton.svg" alt="" class="h-10 w-10" title="Add Course">
                                    <div class="">Add Course</div>
                                </router-link>
                            </div>
                            <div class="flex justify-center items-center" style="width:360px;height:320px">
                                <router-link :to="{ name: 'NewCourse' }" class=" flex justify-center items-center">
                                    <img src="../../assets/Addbutton.svg" alt="" class="h-10 w-10" title="Add Course">
                                    <div class="">Add Course with AI</div>
                                </router-link>
                            </div> -->
                        </div>
                    </section>
                    <!-- Mail Send to -->
                    <div v-if="this.selectedcontent == 'mail'" class='min-h-screen w-full bg-white flex rounded p-8'>
                        <div
                            class="relative w-full mx-auto bg-gray-50 h-full flex flex-col rounded-md border border-gray-300 p-8 gap-4">
                            <!-- <div @click="this.selectedcontent='Profile'" class="absolute rounded-lg top-8 right-8 border cursor-pointer flex bg-white">
                            <div class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500" title='close'>Close
                            </div>
                            <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                        </div> -->
                            <div class="font-semibold text-base md:text-lg 2xl:text-2xl text-gray-700">Mail </div>
                            <div class='w-full '>
                                <form class=' h-full flex flex-col gap-4' @submit.prevent="Sendmail">
                                    <div class="mx-auto w-full">
                                        <div class=w-5/6> <label for="sectionTitle" class='text-xl'>
                                                Course
                                            </label>
                                            <select @change="selectTitle($event)"
                                                class="p-2 block  rounded-md border border-gray-300 w-4/6"
                                                id="sectionTitle">
                                                <option>All</option>
                                                <option v-for="section in info" :key="section" :value="section.id">
                                                    {{ section.title }}
                                                </option>
                                            </select>
                                            <span v-if="sendtoAllcourse" class="text-xs px-2 text-red-500">Are you Sure
                                                want to Send Mail to All Courses</span>
                                        </div>
                                    </div>
                                    <div id='mailpart' class="flex items-center justify-start">
                                        <div class='w-5/6'>
                                            <label for="sectionMailid" class='text-xl'>
                                                Student
                                            </label>

                                            <select class="p-2 block  rounded-md border border-gray-300 w-4/6"
                                                id="sectionMailid" @change="selectMailId">
                                                <option v-if="this.studentList.length > 0">All</option>
                                                <option v-else>None</option>
                                                <option v-for="section in studentList" :key="section"
                                                    :value="section[0].id">
                                                    {{ section[0].email }}
                                                </option>
                                            </select>
                                            <span v-if="sendto && this.studentList.length > 0"
                                                class="text-xs px-2 text-red-500">Are you Sure want to Send Mail to
                                                {{ sendto }}</span>
                                        </div>

                                    </div>
                                    <div class="flex flex-col rounded">
                                        <label for='mailSubject'>Subject</label>
                                        <input type='text' id='mailSubject'
                                            class='p-2 block  rounded-md border border-gray-300 w-4/6'>
                                    </div>
                                    <div class="flex flex-col rounded">
                                        <label for='content'>Content</label>
                                        <textarea id='content'
                                            class='p-2 block  rounded-md border border-gray-300 w-5/6 ' rows="10"
                                            cols="20"> </textarea>
                                    </div>
                                    <input type="file" id="file_uploads" name="file_uploads" class=''>
                                    <button id='mailSend' type='submit' class="gradient-btn w-44">Submit</button>
                                </form>

                                <div class="mt-5 p-2 w-full h-[10px] relative">
                                    <div v-if="total_value">
                                        <progress class="w-full" id="file" :value="current_valur" :max="total_value">
                                        </progress>
                                        <div style="text-align: end;">
                                            {{ current_valur }}/{{ total_value }}
                                        </div>

                                    </div>
                                    <!-- <div class="bg-red-500 progress-bar h-full absolute top-0 lef-0 "></div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Add Quiz -->
                    <div v-if="this.selectedcontent == 'quiz'">
                        <quiz />
                    </div>
                    <!-- coursepromt -->

                    <div v-if="this.selectedcontent == 'coursepromt'" class="px-5 lg:px-0">
                        <div class="flex flex-col gap-2 lg:gap-4 lg:flex-row lg:justify-between w-full mt-3"
                            style="overflow-x: scroll;">
                            <table class="border  w-full">

                                <thead>
                                    <tr class="">
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 300px;">Title</td>
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 300px;">Code</td>
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 300px;">Preview</td>
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 300px;">Re-Generate</td>
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 300px;">Course Preview</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-sm" v-for="(course, index) in promptListCourse" :key="index">
                                        <td class="p-2 text-lg">{{ course.title }}</td>
                                        <td class="p-2 text-lg">{{ course.code }}</td>
                                        <td class="p-2"><button class="gradient-btn"
                                                @click="openPreviewPopup(course.id)">Course Parameter View</button></td>
                                        <td class="p-2"><button class="gradient-btn"
                                                @click="tiggerGemimi(course.id)">Re-Generate</button></td>
                                        <td class="p-2"> <router-link
                                                :to="{ name: 'GeminiPreview', params: { course_id: course.id } }">
                                                <button class="gradient-btn"> Generate Course View</button>

                                            </router-link></td>
                                        <!-- <td class="p-2"> <button class="gradient-btn" @click="openCoursePreview(course.id)">  Generate Course View</button></td> -->

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- unitpromt -->
                    <div v-if="this.selectedcontent == 'unitpromt'">
                        <div class="flex flex-col gap-2 lg:gap-4 lg:flex-row lg:justify-between w-full mt-3">
                            <table class="border  w-full">
                                <thead>
                                    <tr>
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 510px;">Title</td>
                                        <td class="bg-gray-200 p-2 font-bold" style="width: 510px;">Unit Name</td>

                                        <td class="bg-gray-200 p-2 font-bold" style="width: 510px;">Edit</td>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr class="text-sm" v-for="(units, index) in unitpromptList" :key="index">
                                        <td class="p-2 text-sm">{{ units.title }}</td>
                                        <td class="p-2 text-sm">{{ units.unit }}</td>

                                        <td class="p-2">
                                            <div class=" text-teal-600 bg-white border border-teal-600 hover:bg-teal-600 hover:text-white p-0.5 px-1 md:px-2 inline-block 
                    text-xs rounded cursor-pointer" @click="editPopup(units.id)">Edit
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- edit popup start -->

                    <!-- coursepromt -->
                    <div v-if="popupforPreview"
                        class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center"
                        style="z-index: 5000">
                        <div
                            class="flex justify-center items-center bg-gray-50 mx-auto w-full lg:max-w-4xl 2xl:max-w-5xl rounded-lg relative !z-999 p-5 pt-5 text-3xl font-bold ">
                            <div class="p-8 w-full text-lg">
                                <div class="flex justify-between h-8 lg:h-9">
                                    <p class="text-lg">Edit</p>




                                    <div @click="popupforPreview = false"
                                        class="border cursor-pointer flex rounded-lg bg-white">
                                        <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                                        <img class='h-5 w-5 md:h-8 md:w-8 my-auto lg:my-0'
                                            src="@/assets/closebutton.svg" />
                                    </div>
                                </div>
                                <form action="" @submit.prevent="coursePromptUpdate" class="">
                                    <div class="flex flex-col gap-2 h-96 overflow-y-scroll">



                                        <!-- <div class="">
                <label for="category" class="flex gap-2 items-center">
                  <div>Category<span class="text-red-400">*</span></div>
                </label>
                <input
                    v-model="popupData1.category.title"
                  id="category"
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div> -->

                                        <!-- <div class="">
                <label for="batch">
                  Batch<span class="text-red-400">*</span>
                </label>
                <input
                  id="batch"
                  type="number"
                  v-model="popupData1.batch"
                  required
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div> -->
                                        <div class="">
                                            <label for="code">
                                                Code<span class="text-red-400">*</span>
                                            </label>
                                            <input id="code" type="text" v-model="popupData1.code" required
                                                class="border border-gray-300 rounded-md text-sm w-full p-2" />
                                        </div>




                                        <div>
                                            <label for="domain">Domain<span class="text-red-400">*</span></label>
                                            <input id="domain" type="text" v-model="popupData1.domain" required
                                                class="border border-gray-300 rounded-md text-sm w-full p-2" />
                                        </div>
                                        <div>
                                            <label for="title">Title<span class="text-red-400">*</span></label>
                                            <input id="title" type="text" v-model="popupData1.title" required
                                                class="border border-gray-300 rounded-md text-sm w-full p-2" />
                                        </div>




                                        <div>
                                            <label for="size">Unit size<span class="text-red-400">*</span></label>
                                            <input id="size" type="number" v-model="popupData1.unit_size" required
                                                class="border border-gray-300 rounded-md text-sm w-full p-2" />
                                        </div>


                                        <div class="flex justify-start items-center gap-2">
                                            <label for="freeCourse"> Assignments </label>
                                            <input id="Assessments" type="checkbox" class=""
                                                v-model="popupData1.Assignment" required />
                                        </div>
                                        <div>
                                            <label for="assessmentDetails">Numbar of assignment<span
                                                    class="text-red-400">*</span></label>
                                            <input id="assessmentDetails" type="number"
                                                class="border border-gray-300 rounded-md text-sm w-full p-2" required
                                                v-model="popupData1.Assignment_size" placeholder="" />
                                        </div>

                                        <div class="flex justify-start items-center gap-2">
                                            <label for="freeCourse"> Quizs </label>
                                            <input id="Quiz" type="checkbox" class="" v-model="popupData1.quiz" required
                                                checked="" />
                                        </div>

                                        <div>
                                            <label for="quizDetails">Number of quizs<span
                                                    class="text-red-400">*</span></label>
                                            <input id="quizDetails" type="number"
                                                class="border border-gray-300 rounded-md text-sm w-full p-2" required
                                                v-model="popupData1.quiz_size" placeholder="" />
                                        </div>
                                        <div>
                                            <label for="instruction"> Course instruction promt<span
                                                    class="text-red-400">*</span></label>
                                            <textarea name="" required v-model="popupData1.instruction" id="instruction"
                                                cols="20" rows="4"
                                                class="border border-gray-300 rounded-md text-sm w-full p-2">
            </textarea>
                                        </div>



                                    </div>
                                    <div class="flex justify-between w-full items-center">
                                        <div class="text-xl">
                                            <p class="text-green-400" v-if="message">{{ message }}</p>
                                            <p class="text-red-400" v-if="error_message">{{ error_message }}</p>
                                        </div>

                                        <div class="flex justify-end">
                                            <button type="submit" class="gradient-btn mt-4">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div v-if="openCoursePreviewStatus"
                        class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-2 lg:p-4 flex items-center"
                        style="z-index: 5000">
                        <div
                            class="flex justify-center items-center bg-gray-50 mx-auto w-full lg:w-3/6 2xl:w-2/5  rounded-lg relative !z-999 p-5 pt-5 text-3xl font-bold ">
                            <div class="px-2 lg:px-4 w-full text-lg" style="height:700px">
                                <div class="flex justify-end h-8 lg:h-9">
                                    <div @click="openCoursePreviewStatus = false"
                                        class="border cursor-pointer flex rounded-lg bg-white">
                                        <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                                        <img class='h-5 w-5 md:h-8 md:w-8 my-auto lg:my-0'
                                            src="@/assets/closebutton.svg" />
                                    </div>
                                </div>
                                <!-- <div class="h-96 overflow-y-auto">
            {{popupData2.course}}
         </div> -->
                                <div class=" overflow-y-auto mt-5" style="height:630px;">

                                    <markdown-preview v-if="popupData2.course" :source="popupData2.course" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- coursepromt -->

                    <!-- unitpromt -->

                    <div v-if="popUpedit"
                        class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center"
                        style="z-index:5000">
                        <form @submit.prevent="unitpromtupdate"
                            class="w-full lg:max-w-4xl 2xl:max-w-5xl mx-auto bg-gray-100 relative p-6 flex flex-col gap-4 rounded-md">
                            <div class="flex">
                                <div @click="popUpedit = false"
                                    class="absolute top-6 right-6 border cursor-pointer flex rounded-lg bg-white z-50">
                                    <p class="px-1 md:px-2 my-auto text-sm md:text-base hover:text-red-500">Close</p>
                                    <img class='h-5 w-5 md:h-8 md:w-8' src="@/assets/closebutton.svg" />
                                </div>
                                <p class="text-xl mt-5 ">Edit</p>
                            </div>


                            <div>
                                <label for="title">Title<span class="text-red-400">*</span></label>
                                <input id="title" type="text" v-model="edit_title"
                                    class="border border-gray-300 rounded-md text-sm w-full p-2" />
                            </div>

                            <div>
                                <label for="size">Unit name<span class="text-red-400">*</span></label>
                                <input id="size" type="text" v-model="edit_unit"
                                    class="border border-gray-300 rounded-md text-sm w-full p-2" />
                            </div>

                            <div>
                                <label for="instruction">Unit instruction<span class="text-red-400">*</span></label>

                                <!-- <input
                  id="instruction"
                  type="Text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                /> -->
                                <textarea name="" id="instruction" cols="20" v-model="edit_instruction" rows="1"
                                    class="border border-gray-300 rounded-md text-sm w-full p-2">
            </textarea>
                            </div>

                            <div class="flex justify-between w-full">
                                <div class="text-xl">
                                    <p class="text-green-400" v-if="message">{{ message }}</p>
                                    <p class="text-red-400" v-if="error_message">{{ error_message }}</p>
                                </div>

                                <div class="flex justify-end">
                                    <button type="submit" class="gradient-btn mt-4">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- edit popup end -->
                    <!--edit course promtsubmit popup -->

                    <div v-if="submitpopup"
                        class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center"
                        style="z-index:5000">
                        <div
                            class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl">
                            <div @click="submitpopup = false"
                                class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer">
                                &times;
                            </div>

                            <h2 class="p-4 text-lg font-semibold">Thank you for successfully</h2>

                            <button type="button" class="gradient-btn mt-4" @click="generatesubmit()">
                                Generate
                            </button>
                        </div>
                    </div>
                    <!--edit submit popup -->

                    <!--edit unit promtsubmit popup -->

                    <div v-if="unitsubmitpopup"
                        class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center"
                        style="z-index:5000">
                        <div
                            class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl">
                            <div @click="unitsubmitpopup = false"
                                class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer">
                                &times;
                            </div>

                            <h2 class="p-4 text-lg font-semibold"> Unit promt update successfully</h2>


                        </div>
                    </div>
                    <!--edit submit popup -->
                    <!-- unitpromt -->


                    <section v-if="this.selectedcontent == 'Profile'"
                        class="flex flex-col lg:flex-row relative px-3 py-4">
                        <div class=" py-2 md:py-0 w-full lg:w-4/6">
                            <div class="w-full lg:w-5/6 ">
                                <div class="text-xl font-bold pb-4">Biography</div>
                                <p class="text-base text-justify">{{ profileInfo.data.description }}</p>
                            </div>
                        </div>
                        <div class="w-full lg:w-2/6 lg:border-l px-4 capitalize">
                            <div class="text-xl font-bold pb-4 "> Info</div>
                            <div class="flex flex-col gap-4">
                                <div class="">
                                    <div class="text-lg font-semibold">Name</div>
                                    <p>{{ profileInfo.data.user }}</p>
                                </div>
                                <div>
                                    <div class="text-lg font-semibold">Job Title</div>
                                    <p>{{ profileInfo.data.designation }}</p>
                                </div>
                                <div>
                                    <div class="text-lg font-semibold">Email</div>
                                    <p>{{ profileInfo.data.Email }}</p>
                                </div>
                                <div>
                                    <div class="text-lg font-semibold">Phone</div>
                                    <p>{{ profileInfo.data.mobile }}</p>
                                </div>
                            </div>
                        </div>

                        <div id="subdetails"
                            class="absolute border -bottom-28 left-0 md:left-6 flex rounded-lg py-2 shadow-xl items-center justify-center gap-2 md:gap-6 primary-color">
                            <div class="flex gap-2">
                                <div class="rounded-full bg-white p-1">
                                    <img src="../../assets/members.svg" alt="" class="w-12 h-12 p-0.5 md:p-2">
                                </div>
                                <div class="flex flex-col justify-center items-center gap-1">
                                    <div class="text-lg md:text-3xl font-bold text-white">{{ this.TotalstudentCount }}
                                    </div>
                                    <p class="text-xs md:text-sm font-semibold text-white">Total Student</p>
                                </div>
                            </div>
                            <div class="flex gap-2">
                                <div class="bg-white rounded-full p-1">
                                    <img src="../../assets/lessons.svg" alt="" class="w-12 h-12 p-0.5 md:p-2">
                                </div>
                                <div v-if="info" class="flex flex-col justify-center items-center">
                                    <div class="text-lg md:text-3xl font-bold text-white">{{ info.length }}</div>
                                    <span class="text-xs md:text-sm font-semibold text-white">Active Course</span>
                                </div>
                                <div v-else class="flex flex-col justify-center items-center">
                                    <div class="text-lg md:text-3xl font-bold text-white">--</div>
                                    <span class="text-xs md:text-sm font-semibold text-white">Active Course</span>
                                </div>
                            </div>
                            <div class="flex gap-2">
                                <div class="bg-white rounded-full p-1">
                                    <img src="../../assets/star.svg" alt="" class="w-12 h-12 p-0.5 md:p-2">
                                </div>
                                <div class="flex flex-col justify-center items-center">
                                    <div class="text-lg md:text-3xl font-bold text-white">--</div>
                                    <span class="text-xs md:text-sm font-semibold text-white">Total review</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </section>

        </main>

        <!-- User Profile Edit -->
        <div v-if="popUp && updateDetails != null"
            class="fixed w-full h-full bg-black bg-opacity-70 top-0 py-8 flex items-center" style="z-index:5000">
            <div class="w-full max-w-5xl mx-auto flex flex-col h-auto overflow-y-auto" style="max-height:95%">
                <div class="bg-gray-50 h-auto border flex flex-col gap-2 p-4 rounded-lg relative">
                    <div class="flex justify-between ">
                        <div class=" font-semibold text-base md:text-lg 2xl:text-2xl text-gray-700">Edit Details</div>
                    </div>
                    <div @click="popUp = false"
                        class="absolute hover:bg-gray-100 top-2 right-2 border cursor-pointer flex items-center bg-white rounded-lg z-50">
                        <div class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</div>
                        <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                    </div>
                    <div class="flex flex-col md:flex-row gap-2 ">
                        <div class="w-full md:w-1/3">Designation</div>
                        <input type="text" v-model="updateDetails.designation"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>

                    <div class="flex flex-col md:flex-row gap-2 ">
                        <div class="w-1/3">Mobile</div>
                        <input type="text" v-model="updateDetails.mobile"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>

                    <div class="flex flex-col md:flex-row gap-2  ">
                        <div class="w-1/3">Address</div>
                        <input type="text" v-model="updateDetails.address"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>

                    <div class="flex flex-col md:flex-row gap-2">
                        <div class="w-1/3">Description</div>
                        <input type="text" v-model="updateDetails.description"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>
                    <div class="flex flex-col md:flex-row gap-2">
                        <div class="w-1/3"> Website</div>
                        <input type="text" v-model="updateDetails.website"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>
                    <div class="flex flex-col md:flex-row gap-2">
                        <div class="w-1/3"> Github</div>
                        <input type="text" v-model="updateDetails.github"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>
                    <div class="flex flex-col md:flex-row gap-2">
                        <div class="w-1/3"> linked in</div>
                        <input type="text" v-model="updateDetails.linkedin"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>
                    <div class="flex flex-col md:flex-row gap-2">
                        <div class="w-1/3"> Twitter</div>
                        <input type="text" v-model="updateDetails.twitter"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>
                    <div class="flex flex-col md:flex-row gap-2  ">
                        <div class="w-1/3">instagram</div>
                        <input type="text" v-model="updateDetails.instagram"
                            class="border border-gray-300 rounded-md w-full md:w-2/3">
                    </div>
                    <div class="mt-2">
                        <button @click="SubmitProfile()" type="submit" class="gradient-btn w-40">Submit</button>
                    </div>

                </div>
            </div>
        </div>

        <!-- Thumnail Update -->
        <div v-if="thumbnailUpdate"
            class="absolute top-14 w-full min-h-screen bg-white flex items-center justify-center z-50">
            <div class="center h-full flex justify-center items-center ">
                <div class="form-input ">
                    <div class="preview w-full h-full relative">
                        <img id="imgPreview" class="h-80 w-96 hidden">
                        <img v-if="submitting" src="../../assets/closebutton.svg" alt=""
                            class="w-7 h-7 absolute right-0 top-0 cursor-pointer" @click="imgreset()">
                    </div>
                    <form @submit.prevent="thumbnailUpdatefN" ref="imguploadref" class="w-full flex flex-col relative">
                        <label v-if="submitting == false" for="imgFileupload">Upload Image</label>
                        <input type="file" id="imgFileupload" accept=".jpg, .jpeg, .png, .webp" class="hidden"
                            @change="showPreview();">
                        <button v-if="submitting" type="submit"
                            class="border text-center px-4 bg-teal-600 hover:bg-teal-800 text-white font-bold rounded-lg py-2">Submit</button>
                        <img v-if="submitting == false" src="../../assets/closebutton.svg" alt=""
                            class="w-7 h-7 absolute -right-12 -top-12 cursor-pointer" @click="thumbnailUpdate = false">
                    </form>
                </div>
            </div>

        </div>
        <div v-if="popup_instructor"
            class="fixed top-0 left-0 z-50 bg-black bg-opacity-60 h-screen w-full flex items-center">
            <div class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl">
                <div @click="popup_instructor = false; this.message = '';"
                    class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer">
                    &times;
                </div>

                <h2 v-if="message" class="p-4 text-lg font-semibold capitalize">{{ message }}</h2>



                <div v-if="loader_generator" class="flex flex-col justify-center items-center">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    Generating...
                </div>
                <button v-else type="submit" class="gradient-btn mt-4"
                    @click="generatecourse(); this.loader_generator = true">
                    Generate
                </button>
            </div>
        </div>
        <div v-if="popup_regenerate"
            class="fixed top-0 left-0 z-50 bg-black bg-opacity-60 h-screen w-full flex items-center">
            <div class="relative w-auto mx-auto rounded-lg bg-white p-4 lg:p-6 text-gray-700 text-center shadow-xl">
                <!-- <div
                @click="popup_regenerate = false"
                class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer"
            >
                &times;
            </div> -->


                <h2 v-if="regenerate_status" class="p-4 text-lg font-semibold capitalize">{{ regenerate_message }}</h2>
                <h2 v-else class="p-4 text-lg font-semibold capitalize">Your code is Regenerating ...</h2>
                <div v-if="loader" class="flex flex-col justify-center items-center">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    Regenerating...
                </div>

                <button v-if="regenerate_status" type="submit" class="gradient-btn mt-4"
                    @click="popup_regenerate = false">
                    Close
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import MarkdownPreview from '@uivjs/vue-markdown-preview';
import '@uivjs/vue-markdown-preview/markdown.css';
import {
    mapGetters
} from "vuex"
// import MarkdownEditor from "@/components/MarkdownEditor.vue";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import quiz from "@/components/Quiz.vue";
export default {
    components: {

        MarkdownEditor,
        FlashMessage,
        confirmPopup,
        quiz,
        MarkdownPreview
    },

    props: ["text"],
    computed: mapGetters(["userInfo", "instructorInfo"]),

    data() {
        return {
            popUpedit: false,
            submitpopup: false,
            info: null,
            popUp: false,
            courseUpdate: false,
            popInfo: null,
            categories: null,
            unitUpdate: false,
            lessonUpdate: false,
            res: "",
            messages: {
                text: "",
                type: 'null',
            },
            titlepass: "",
            popupwindow: false,
            idpass: "",
            studentList: "",
            sendto: "",
            sendtoAllcourse: "",
            mailtemplate: false,
            profileInfo: "",
            selectedcontent: "Courses",
            TotalstudentCount: 0,
            thumbnailUpdate: false,
            submitting: false,
            imgcourseId: null,
            profile: false,
            to_lessonEdit: false,
            course_id: null,
            updateDetails: '',
            studentdataList: '',
            studentfinaldata: '',
            total_value: '',
            current_valur: 0,
            coursepromt: [],
            promptListCourse: [],
            popupforPreview: false,
            openCoursePreviewStatus: false,
            popupData1: [],
            //   popupData2: {},
            unitpromptList: [],
            unitsubmitpopup: false,
            edit_title: "",
            edit_unit: "",
            edit_instruction: "",
            unitid: "",
            course_prompt_id: '',
            course_prompt_response: [],
            message: "",
            error_message: '',
            genaraic: [],
            popup_instructor: false,
            popup_regenerate: false,
            regenerate_status: false,
            regenerate_message: "",
            loader: false,
            loader_generator: false,
        }
    },


    created() {
        this.promptList();
        this.unitprompt();
        this.coursedetailsget();
        this.getProfileInfo();
        const activeTab = this.$route.query.activeTab;
        if (activeTab) {
            this.selectedcontent = activeTab;
        };
        // this.generatecourse();

    },


    methods: {

        // openCoursePreview(id){
        //     console.log(id);
        //     this.openCoursePreviewStatus = true;

        //     axios.get(`${process.env.VUE_APP_API}/gemini/list/?Key=${this.userInfo.token_key}&courseprompt=${id}`)
        //     .then((response)=>{
        //         if(response.status == 200){
        //             console.log("id////850",response.data);
        //             this.popupData2 = response.data.data[0];
        //         }
        //         else{
        //         console.log("error on api");
        //         }
        //     })
        //     .catch((err)=>{
        //         console.log(err);
        //     })

        // },
        openPreviewPopup(id) {
            this.course_prompt_id = id;
            this.popupforPreview = true;

            axios.get(`${process.env.VUE_APP_API}/courseprompt/get/?Key=${this.userInfo.token_key}&id=${id}`)
                .then((response) => {
                    if (response.status == 200) {
                        console.log("openPreviewPopup", response.data.data)
                        this.popupData1 = response.data.data[0];
                        console.log("this.popupData1 quizzzzzz", this.popupData1)
                    }
                    else {
                        console.log('response.data.data[0]')
                        console.log("error on api");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })

        },
        coursePromptUpdate() {
            console.log("courseupdate data")
            const instruction = typeof this.popupData1.instruction === 'string'
                ? this.popupData1.instruction.trim()
                : String(this.popupData1.instruction).trim();
            const promptData = {
                batch: this.popupData1.batch,
                code: this.popupData1.code,
                title: this.popupData1.title,
                domain: this.popupData1.domain,
                unit_size: this.popupData1.unit_size,
                // instruction: [
                //     this.popupData1.instruction,
                // ],
                instruction: [
                    instruction.endsWith(',')
                        ? instruction
                        : `${instruction},`
                ],
                Assignment: this.popupData1.Assignment,
                quiz: this.popupData1.quiz,
                Assignment_size: this.popupData1.Assignment_size,
                quiz_size: this.popupData1.quiz_size,
            };
            console.log("promtData", promptData)
            axios.put(`${process.env.VUE_APP_API}/coursepromptupdate/${this.course_prompt_id}/?Key=${this.userInfo.token_key}`, promptData)
                .then((resp) => {
                    if (resp.status == 200) {
                        console.log("coursePromptUpdate", resp)
                        this.course_prompt_response = resp.data;
                        this.course_prompt_id = resp.data.data.id;
                        console.log("this.course_prompt_id", this.course_prompt_id);
                        this.message = resp.data.message;
                        setTimeout(() => {
                            // this.message="";

                            this.popupforPreview = false;
                            this.popup_instructor = true;
                            this.promptList();


                        }, 3000)
                        // this.generatecourse();


                    }
                })
                .catch((err) => {
                    console.log("error", err);
                })
        },
        tiggerGemimi(id) {
            this.popup_regenerate = true;
            this.loader = true;
            this.course_prompt_id = id;
            this.generatecourse();
        },
        async generatecourse() {
            console.log("tiggerGemimi executed")
            await axios
                .get(
                    `${process.env.VUE_APP_API}/gemini/post/${this.course_prompt_id}/?Key=${this.userInfo.token_key}`,
                )
                .then((resp) => {
                    console.log("resp generatecourse", resp)
                    if (resp.data.status == 200) {
                        this.genaraic = resp.data;
                        console.log("genaricdata", resp.data);
                        this.loader = false;
                        this.loader_generator = false;
                        this.regenerate_status = true;
                        this.regenerate_message = "Regenrated Succesfully..!";
                        setTimeout(() => {
                            this.popup_regenerate = false;
                            this.regenerate_status = false;
                        }, 2000)
                        if (this.popup_instructor) {
                            this.popup_instructor = false
                            this.message = "";

                        }
                        // this.promptList();
                    }
                })
                .catch((err) => {
                    console.log("error", err);
                });


        },
        promptList() {
            axios.get(`${process.env.VUE_APP_API}/courseprompt/get/?Key=${this.userInfo.token_key}`)
                .then((response) => {
                    if (response.status == 200) {
                        this.promptListCourse = response.data.data;
                        console.log("promptListCourse get api", this.promptListCourse)

                    }
                    else {
                        console.log("error on api");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        unitprompt() {
            axios.get(`${process.env.VUE_APP_API}/unitprompt/get/?Key=${this.userInfo.token_key}`)
                .then((response) => {
                    if (response.data.status == 200) {
                        this.unitpromptList = response.data.data;
                        console.log("unitpromt get api", this.unitpromptList)


                    }
                    else {
                        console.log("error on api");
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        editPopup(ids) {
            this.popUpedit = true;
            this.unitid = ids;
            axios
                .get(
                    `${process.env.VUE_APP_API}/unitprompt/get/?id=${this.unitid}&Key=${this.userInfo.token_key}`,
                )
                .then((resp) => {
                    if (resp.data.status == 200) {
                        this.unitpromptedit = resp.data.data;
                        console.log("unitpromptedit 778878787", this.unitpromptedit)
                        this.edit_title = this.unitpromptedit[0].title;
                        this.edit_unit = this.unitpromptedit[0].unit;
                        this.edit_instruction = this.unitpromptedit[0].instruction;

                    }
                })
                .catch((err) => {
                    console.log("error", err);
                });

        },
        unitpromtupdate() {

            let formunitData = {

                "title": this.edit_title,
                "unit": this.edit_unit,
                "instruction": this.edit_instruction,

            }

            axios
                .put(
                    `${process.env.VUE_APP_API}/unitpromptupdate/${this.unitid}/?Key=${this.userInfo.token_key}`, formunitData
                )
                .then((resp) => {
                    if (resp.data.status == 200) {
                        this.unitpromptupdate = resp.data.data;
                        console.log("unitpromptupdate 778878787", this.unitpromptupdate);
                        this.message = resp.data.message;
                        //  this.unitsubmitpopup=true;

                        setTimeout(() => {
                            this.message = "";
                            this.popUpedit = false;
                        }, 3000)

                        this.unitprompt();

                    }
                })
                .catch((err) => {
                    console.log("error", err);
                });

        },
        // editunitSubmit(){
        // this.unitsubmitpopup=true;
        // this.popUpedit=false;
        //         },
        editSubmit() {
            this.popUpedit = false;
            this.submitpopup = true;
        },

        lessonEdit(id) {

            this.$router.push({
                name: 'UpdateLesson',
                params: {
                    courseId: id
                }
            })

            // this.to_lessonEdit = true;
            // this.course_id = id
        },

        imgreset() {

            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
        },
        showPreview() {
            if (event.target.files.length > 0) {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("imgPreview");
                preview.src = src;
                preview.style.display = "block";
                this.submitting = true
            }
        },
        async ProfileEdit() {
            let details_id = this.profileInfo.data.id;
            let token = this.userInfo.token_key;
            this.popUp = true

            await axios.get(`${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`)
                .then(response => {
                    // console.log(response.data,'profile edit')
                    this.updateDetails = response.data
                })

        },
        async SubmitProfile() {
            let details_id = this.profileInfo.data.id;
            let token = this.userInfo.token_key;
            let update = this.updateDetails
            this.popUp = false
            // console.log("updateDetails",updateDetails)

            await axios.put(`${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`, update)
                .then(response => {
                    if (response.data.message == 'Profile updated successfully') {
                        this.messages = {
                            text: "Profile Uploaded successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.getProfileInfo()
            }, 3000);

        },
        async imageHandles() {
            this.profile = false;
            let headerprofile = new FormData();
            headerprofile.append("id", this.userInfo.user.id)
            headerprofile.append("profile", document.querySelector("#image_uploads").files[0])

            await axios.put(`${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`, headerprofile)
                .then(response => {

                    if (response.data.message == 'profile Data Updated successfully') {
                        this.messages = {
                            text: "Profile Uploaded successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
                .catch(error => {

                    this.messages = {
                        text: error.response.data.error,
                        type: 'error',
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.getProfileInfo()
            }, 3000);

        },
        async thumbnailUpdatefN(el) {
            let fileVal = el.target.elements["imgFileupload"].files[0]
            let urls = `${process.env.VUE_APP_API}/course_image/${this.imgcourseId}/?Key=${this.userInfo.token_key}`;
            let formData = new FormData();
            formData.append("thumbnail", fileVal);

            await axios.put(urls, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.message == "Course Image Updated successfully") {
                    this.messages = {
                        text: "Image Uploaded successfully",
                        type: 'success',
                    }
                } else {
                    this.messages = {
                        text: " Event Faild",
                        type: 'error',
                    }
                }
            })
            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
            setTimeout(async () => {
                this.messages = {
                    text: "",
                    type: 'null',
                }

                await this.coursedetailsget()
            }, 1500)

        },

        redirectToDashboard(courses) {
            this.$router.push({
                name: "InstructorStudents",
                params: {
                    course_id: courses.id,
                    instructor: courses.instructor,
                    course_name: courses.title
                }
            })
        },
        async getProfileInfo() {
            let token = this.userInfo.token_key;
            let slug_id = this.userInfo.slug_id;
            await axios.get(`${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`)
                .then(response => {
                    this.profileInfo = response.data
                })

        },

        selectMailId(el) {

            let selectmailVal = el.target.value
            console.log("selectmailVal", selectmailVal)
            if (selectmailVal == 'All') {

                this.sendto = el.target.value
            } else {
                this.sendto = ""
            }
        },

        async Sendmail(el) {
            console.log('test', el)
            let courseInfo = el.target.sectionTitle.value
            let mailInfo = el.target.sectionMailid.value
            let subjectInfo = el.target.mailSubject.value
            let contentInfo = el.target.content.value
            // console.log("contentInfo::",contentInfo)
            // console.log("mailInfo", mailInfo)
            // console.log("contentInfo",contentInfo)
            var headerprofile = new FormData();
            headerprofile.append("subject", subjectInfo)
            headerprofile.append("content_1", contentInfo)
            console.log("document.querySelector.files[0]", document.querySelector("#file_uploads").files[0])
            if (document.querySelector("#file_uploads").files[0]) {
                headerprofile.append("file", document.querySelector("#file_uploads").files[0])
            }
            var data_list = this.studentdataList
            var dummyEmail = data_list
            console.log(dummyEmail, 'dummyemail')
            var start_point = 0
            var end_point = 40

            // instructor send email to all student
            if (courseInfo == 'All' && mailInfo == "None") {
                if (dummyEmail.length > 40) {
                    var len_val = parseInt(dummyEmail.length / 40)
                    console.log(len_val)
                    this.total_value = len_val
                    for (let j = 0; j < len_val; j++) {
                        console.log("data", j)
                        var mail_val = dummyEmail.slice(start_point, end_point)
                        start_point = start_point + 40
                        end_point = end_point + 40

                        headerprofile.append("email", mail_val)
                        console.log("headerprofile", headerprofile)
                        await axios.post(`${process.env.VUE_APP_API}/batch_mail/?Key=${this.userInfo.token_key}`, headerprofile)
                            .then(response => {
                                if (response.data.status == 200) {
                                    console.log(" Email sent to All Course")
                                    this.messages = {
                                        text: " Email sent to All Course",
                                        type: 'success',
                                    }
                                    setTimeout(() => { }, 20000)
                                } else {
                                    console.log(" Event Faild")
                                    this.messages = {
                                        text: " Event Faild",
                                        type: 'error',
                                    }
                                }
                                headerprofile.delete('email');
                            })
                    }

                } else {
                    this.total_value = dummyEmail.length
                    this.current_valur = dummyEmail.length
                    headerprofile.append("email", dummyEmail)
                    await axios.post(`${process.env.VUE_APP_API}/batch_mail/?Key=${this.userInfo.token_key}`, headerprofile)
                        .then(response => {
                            if (response.data.status == 200) {
                                console.log(" Email sent to All Course")
                                this.messages = {
                                    text: " Email sent to All Course",
                                    type: 'success',
                                }
                                setTimeout(() => { }, 20000)
                            } else {
                                console.log(" Event Faild")
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                            }
                            headerprofile.delete('email');
                        })
                }
            }

            // instructor_send_all_student/ instructor send email to specific one course (or) one batch
            else if (courseInfo != 'All' && mailInfo == "All") {
                if (dummyEmail.length > 40) {
                    var len_val = parseInt(dummyEmail.length / 40)
                    console.log(len_val)
                    this.total_value = len_val

                    for (let j = 0; j < len_val; j++) {
                        console.log(j)
                        this.current_valur = j + 1
                        var mail_val = dummyEmail.slice(start_point, end_point)
                        start_point = start_point + 40
                        end_point = end_point + 40

                        headerprofile.append("email", mail_val)
                        await axios.post(`${process.env.VUE_APP_API}/batch_mail/?Key=${this.userInfo.token_key}`, headerprofile)
                            .then(response => {
                                if (response.data.status == 200) {
                                    console.log(" Email sent to All Course")
                                    this.messages = {
                                        text: " Email sent to All Course",
                                        type: 'success',
                                    }
                                    setTimeout(() => { }, 20000)
                                } else {
                                    console.log(" Event Faild")
                                    this.messages = {
                                        text: " Event Faild",
                                        type: 'error',
                                    }
                                }
                                headerprofile.delete('email');
                            })
                    }

                } else {
                    headerprofile.append("email", dummyEmail)
                    this.total_value = dummyEmail.length
                    this.current_valur = dummyEmail.length
                    await axios.post(`${process.env.VUE_APP_API}/batch_mail/?Key=${this.userInfo.token_key}`, headerprofile)
                        .then(response => {
                            console.log(response.data)
                            if (response.data.status == 200) {
                                console.log(" Email sent to All Course")
                                this.messages = {
                                    text: " Email sent to All Course",
                                    type: 'success',
                                }
                                setTimeout(() => { }, 20000)
                            } else {
                                console.log(" Event Faild")
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                            }
                            headerprofile.delete('email');
                        })
                }


                // instructor_send_student/<pk>/
            } else {
                this.total_value = dummyEmail.length
                this.current_valur = dummyEmail.length
                console.log(" else  sent to All Course")
                await axios.post(`${process.env.VUE_APP_API}/batch_mail/?Key=${this.userInfo.token_key}`, headerprofile)
                    // await axios.post(`${process.env.VUE_APP_API}/instructor_send_student/${courseInfo}/?Key=${this.userInfo.token_key}`, HeaderVal)
                    .then(response => {
                        if (response.data.status == 200) {
                            console.log(" Email sent to All Course")
                            this.messages = {
                                text: "Email sent to All student in Course",
                                type: 'success',
                            }
                            console.log("response.data", response.data)
                            setTimeout(() => { }, 20000)
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                        }
                    })
                // instructor_send_personal/<pk>/ 
            }

            // }

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.mailtemplate = false

            }, 1500)
        },

        async selectTitle(event) {
            // console.log(event.target.value);
            this.sendtoAllcourse = ""
            this.sendto = ""
            document.querySelector("#mailpart").classList.replace('hidden', "block")
            let course_id = event.target.value
            if (course_id != 'All') {
                console.log("selectTitleselectTitleselectTitleselectTitleselectTitleselectTitleselectTitle", `${process.env.VUE_APP_API}/all_list/${course_id}/?Key=${this.userInfo.token_key}`)
                await axios.get(`${process.env.VUE_APP_API}/all_list/${course_id}/?Key=${this.userInfo.token_key}`)
                    .then(response => {
                        this.studentList = response.data.data
                        this.studentdataList = response.data.user
                        console.log("this.studentList", response.data.user)

                    })
            } else {
                console.log("selectTitleselectTitleselectTitleselectTitleselectTitleselectTitleselectTitle", `${process.env.VUE_APP_API}/organization_get_all_mail/?Key=${this.userInfo.token_key}`)

                this.sendtoAllcourse = "All Courses"
                await axios.get(`${process.env.VUE_APP_API}/organization_get_all_mail/?Key=${this.userInfo.token_key}`)
                    .then(response => {
                        this.studentList = response.data.data
                        this.studentdataList = response.data.data
                        console.log("this.studentList", response.data.data)
                        console.log("this.studentList", response.data.data.length)
                    })
                document.querySelector("#mailpart").classList.add('hidden')
            }
        },

        async coursedetailsget() {
            await axios.get(`${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`)
                .then(response => {
                    if (Object.keys(response.data) == "error") {
                        console.log("Null")
                    } else {
                        this.info = response.data.data
                        this.info.forEach(element => {
                            element.displayprice = new Intl.NumberFormat('hi-IN', {
                                style: 'currency',
                                currency: 'INR',
                                maximumFractionDigits: 0,
                            }).format(element.price);
                        });
                    }
                    if (this.info) {
                        this.info.forEach(async el => {
                            var tempval
                            await axios.get(`${process.env.VUE_APP_API}/coursesdata/${el.id}/`)
                                .then(response => {
                                    tempval = response.data
                                    this.TotalstudentCount += tempval["total_student_count"]
                                })
                            el.duration = tempval["total_duration"]
                            el.studentCount = tempval["total_student_count"]
                            el.profile = tempval["profile"]

                        })
                    }
                })

            axios.get(`${process.env.VUE_APP_API}/category/?format=json`).then(res => {
                this.categories = res.data.data
            })
        },

        getdetails(data) {

            this.$router.push({
                name: 'EditCourse',
                params: {
                    course_id: data.id
                }
            })
            // this.popInfo = data
            // this.courseUpdate = true;
        },

        async deletecourse(data) {
            await axios.delete(`${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.res = response
                    if (this.res.statusText == 'No Content') {
                        this.messages = {
                            text: "Deleted successfully",
                            type: 'success',
                        }
                        console.log("successfull")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.$router.go(0);
            }, 2000)
        },
    }

}
</script>

<style scoped>
.lds-ring,
.lds-ring div {
    box-sizing: border-box;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
    color: #18469d;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    margin: 4px;
    border: 4px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#EditImg:hover {
    widows: 36px;
    height: 36px;
}

.form-input {
    width: 350px;
    padding: 20px;
    background: #fff;
    box-shadow: -3px -3px 7px rgba(94, 104, 121, 0.377),
        3px 3px 7px rgba(94, 104, 121, 0.377);
}

.form-input label {
    display: block;
    width: 100%;
    height: 45px;
    line-height: 50px;
    text-align: center;
    background: #0D9488;

    color: #fff;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    text-transform: Uppercase;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.form-input label:hover {
    background: #115E59;
    cursor: pointer;
}

#main {
    height: 75%;
}


#CourseList {
    width: 35%;
    height: 49%
}

#subdetails {
    width: 55%;
    height: 6rem
}

@media (min-width: 900px) and (max-width: 2400px) {
    #main {
        height: 100%;
    }

    #CourseList {
        width: 42%;
        height: 52%
    }

    #subdetails {
        width: 90%;
        height: 6rem
    }
}

@media (min-width: 900px) and (max-width: 2400px) {
    #main {
        height: 100%;
    }

    #CourseList {
        width: 34%;
        height: 52%
    }
}

@media (min-width: 800px) and (max-width: 900px) {
    #main {
        height: 100%;
    }

    #CourseList {
        width: 46%;
        height: 38%
    }

    #subdetails {
        width: 94%;
        height: 6rem
    }
}

@media (max-width: 800px) {
    #main {
        height: 100%;
    }

    #CourseList {
        width: 80%;
        height: 20rem
    }

    #subdetails {
        width: 99%;
        height: 6rem
    }
}

select {
    line-height: 1.5em;
}

.blues {
    color: #0F172A !important;
}

.bluesbg {
    background-color: #0F172A !important;
}

.heightc {
    height: 500px !important;
}
</style>
