<template lang="">
<div class="flex relative" style="height:calc(100vh - 113px);">
    <NavigationInstructor actived="studentlist" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="flex flex-col w-full h-full">
        <!-- New Structure -->
        <div class='overflow-y-scroll h-screen flex justify-center mt-6 md:mt-0 w-full' style="scrollbar-color:gray white">
            <div class="w-11/12 p-0 md:p-4 flex justify-center ">
                <div class="mt-4 inline-block shadow-2xl rounded-2xl overflow-x-auto md:overflow-y-scroll h-full w-full">
                    <table class="leading-normal w-full">
                        <thead class="thead">
                            <tr>
                                <th class=" px-2 lg:px-7 py-4 lg:py-4 items-center border-b-2 border-r-2  text-center text-lg font-semibold text-blue-500  tracking-wider">
                                    Sl.no
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-r-2 text-center text-lg font-semibold text-blue-500  tracking-wider">
                                    Name
                                </th>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-r-2 text-center text-lg font-semibold  text-blue-500  tracking-wider">
                                    Email
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-r-2 text-center text-lg font-semibold text-blue-500  tracking-wider">
                                    Last login 
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-r-2 text-center text-lg font-semibold text-blue-500  tracking-wider">
                                    Mail to
                                </th>

                            </tr>
                        </thead>
                        <tbody v-if="studentList.data">
                            <tr v-for="el,index in this.studentList.data" :key="el">
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border-b border-gray-200 bg-white text-xs lg:text-sm">
                                    <div class="flex items-center justify-center">
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {{ index + 1 }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p v-if="el[0].display_name" class="text-center text-gray-900 whitespace-no-wrap">
                                        {{el[0].display_name}}
                                    </p>
                                    <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    {{el[0].email}}
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        {{ new Date(el[0].date_joined).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }} - {{ new Date(el[0].date_joined).toLocaleTimeString('en-IN') }} 
                                        
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p @click="popUp=true;this.mailid=el[0]" class="text-center text-gray-900 whitespace-no-wrap cursor-pointer">
                                        <img src="../../assets/email.svg" class="h-4" />
                                    </p>
                                </td>

                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-if="popUp" class='fixed  z-50 bottom-0 right-0 w-full md:w-5/6 lg:w-3/6 h-auto bg-white flex items-center justify-center border'>
            <main class=" bg-white flex flex-col w-full h-full">
                <form ref="mailref" @submit.prevent="sendmail" class="">
                    <section class='bg-blue-800 text-white flex '>
                        <p class='w-5/6 px-2 py-1 mb-0'>New Message </p>
                        <div class='w-1/6 flex gap-1 justify-end px-4 py-1'>
                            <div @click="this.popUp = false" class="absolute  border cursor-pointer flex rounded-lg">
                                <img class='h-6 w-6 ' src="../../assets/closebutton.svg" />
                            </div>
                        </div>
                    </section>
                    <section v-if="this.minimize" class=' '>
                        <div class='mt-2 border-gray-200 px-2 py-2'>
                            <label for='to' class=' w-1/6'></label>
                            <input input='email' required class='border-b w-full pl-1' :value='this.mailid.display_name' placeholder='To' disabled />
                        </div>
                        <div class='mt-2  border-gray-200 px-2'>
                            <label for='subject' class=' w-1/6'></label>
                            <input input='text' id='subject' required class='border-b  w-full pl-1 py-1' placeholder='Subject' />
                        </div>
                        <div class='mt-2 sm:h-4/6 2xl:h-full px-2'>
                            <label for='content' class=' w-1/6'></label>
                            <textarea rows="12" cols="10" id='content' class="border border-gray-300 h-full w-full pl-1 rounded" placeholder='Content'></textarea>
                        </div>
                        <div class='py-2 px-2'>
                            <button type='submit' class='gradient-btn'>Send</button>
                        </div>
                    </section>
                </form>
            </main>
        </div>
    </div>

</div>
</template>

<script>
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex";

export default {
    data() {
        return {

            popUp: false,
            minimize: true,
            maximize: false,
            studentList: "",
            mailid: null,
            messages: {
                text: "",
                type: 'null',
            },
        }
    },

    components: {
        NavigationInstructor,
        FlashMessage
    },

    created() {
        this.getstudentdetails()
    },

    computed: {
        ...mapGetters(["userInfo"]),
    },

    props: ["course_id", "course_name"],

    methods: {

        async getstudentdetails() {
            await axios.get(`${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    console.log(response)
                    this.studentList = response.data
                })
        },

        async sendmail(event) {
            const HeaderVal = {
                "subject": event.target.elements.subject.value,
                "time": "",
                "content_1": event.target.elements.content.value,
                "content_2": "",
                "content_3": ""
            }
            await axios.post(`${process.env.VUE_APP_API}/instructor_send_personal/${this.mailid.id}/?Key=${this.userInfo.token_key}`, HeaderVal)
                .then(response => {
                    if (response.data.message == 'successfully Email send') {
                        this.messages = {
                            text: "Mail Sent successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.$refs.mailref.reset()
            this.popUp = false
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)
        },

        // send Mail All student in a Course
        async sendmailallStudnt() {
            await axios.post(`${process.env.VUE_APP_API}/instructor_send_student/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(Response => {
                    if (Response.message == 'successfully Email send') {
                        this.messages = {
                            text: "Mail Sent successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 1500)

        },
    },
}
</script>

<style lang="">
    
</style>
