<template>

  <div class="min-h-screen relative">

    <Navigation />

    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="min-h-screen w-full container-default-1209px w-container">
      
      <div> 
        <div class="flex flex-row items-center justify-between mt-4">
          <p class="my-6 blues text-4xl font-semibold">My Community</p>
          <!-- <div class="my-6 blues text-xl font-medium">View All</div> -->
        </div>

        <div class="grid lg:grid-cols-3 gap-4 mt-5 pb-20">
          <div v-for="course in this.courseDetails" :key="course" class="heightc card1 p-6">
            <router-link :to="{name:'chatStudent', params: {slug: this.userInfo.slug_id, course_id: course.id,instructor_id: course.instructor, course_name: course.title,}}">
              <div class="relative flex justify-center items-center" style="height: 60%">
                <img v-if="course.thumbnail" class="object-cover object-center h-full w-full rounded-lg"
                  :src="course.thumbnail" alt="" />
                <img v-else class="object-cover object-center h-24 w-52 rounded" src="@/assets/coding.svg" alt="" />
                <!-- <button
                  class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
                >
                  {{ course.category }}
                </button> -->
              </div>

              <div class="text-center flex flex-col gap-1 justify-center" style="height: 45%">
                <p class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0 mt-2">{{ course.title }}</p>
                <p class="text-xs text-gray-600 px-4 text-left truncate mb-0">{{ course.sections.length }} Members</p>
                <div class="p-3">
                  <!-- <div class="flex justify-between">
                    <h2 class="text-sm text-left">In Progress</h2>
                    <h2 class="text-sm text-left">{{ progress }}%</h2>
                  </div> -->
                  <!-- <div class="relative w-full bg-gray-200 rounded-full h-4">
                    <div class="absolute left-0 top-0 h-4 bg-blue-600 rounded-full transition-all duration-300"
                      :style="{ width: progress + '%' }"></div>
                  </div> -->
                  <p class="text-center mt-2 text-gray-600"></p>
                </div>
                <div>
                  <div class="my-3 py-2 text-sm w-full rounded-lg border-2 border-blues text-blues font-medium"> 
                    <router-link :to="{name:'chatStudent', params: {slug: this.userInfo.slug_id, course_id: course.id,instructor_id: course.instructor, course_name: course.title,}}">Open Community
                    </router-link>  
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
  
    </div> 

    <!-- <div class="min-h-screen w-full container-default-1209px w-container">
      <p class="my-6">All Communities</p>

     
      <div
        class="grid grid-cols-1 gap-y-5"
      >
        <div
          v-for="course in this.courseDetails"
          :key="course"
          class="h-20"
        >
        
          <router-link :to="{name:'chatStudent', params: {slug: this.userInfo.slug_id, course_id: course.id,instructor_id: course.instructor, course_name: course.title,}}"
          >

          

            <div
              class="flex flex-col justify-center bg-primary rounded-lg h-full" style="background-color: #105c8e;"
            > 

           
              
              <div class="flex items-center justify-between"> 
                <div class="flex flex-col gap-1">
                  <p class="text-lg px-4 mb-0 text-gray-200 font-medium">{{ course.title }}</p> 
                  <p class="text-xs px-4 mb-0 text-gray-200 font-medium">Members: {{ course.category }}</p> 
                </div>
               
                <p class="text-xs bg-white !text-primary py-1.5 px-4 rounded-lg mt-2 mr-4 cursor-pointer" style="color: black;">View Community</p>
              </div>
             




            </div> 
          </router-link> 

        

        </div>
      </div>
  
    </div> -->


  </div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";
import Navigation from "@/components/Navigation.vue";
export default {
  data() {
    return {
      profileInfo: null,
      popUp: false,
      updateDetails: null,
      selectedFile: null,
      tnxDetails: "",
      profile: false,
      messages: {
        text: "",
        type: "null",
      },
      selectedcontent: "Courses",
      courseDetails: null,
      boughtcourse: null,
    };
  },

  components: {
    Navigation,
    FlashMessage,
  },


  computed: mapGetters(["userInfo"]),

  async created() {
    await this.openProcess();
    let tempvar_courselist = [];
    await axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => (this.courseDetails = response.data.data));
    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.boughtcourse = response.data.data;
      });
    this.boughtcourse.forEach((element) => {
      tempvar_courselist.push(element.course_id);
    });

    this.courseDetails = this.courseDetails.filter((course) => {
      if (tempvar_courselist.includes(course.id)) {
        return course;
      }
    });
  },

  methods: {
    async imageHandles() {
      this.profile = false;
      let headerprofile = new FormData();
      headerprofile.append("id", this.userInfo.user.id);
      headerprofile.append(
        "profile",
        document.querySelector("#image_uploads").files[0]
      );

      await axios
        .put(
          `${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`,
          headerprofile
        )
        .then((response) => {
          if (response.data.message == "profile Data Updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        })
        .catch((error) => {
          this.messages = {
            text: error.response.data.error,
            type: "error",
          };
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0];
    },

    async onUpload() {
      // upload file
      await axios.post("my-domain.com/file-upload", this.selectedFile);
    },

    async openProcess() {
      axios
        .get(
          `${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`
        )
        .then((res) => {
          console.log(res);
          this.tnxDetails = res.data;
        });
      let token = this.userInfo.token_key;
      let slug_id = this.userInfo.slug_id;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
        )
        .then((response) => (this.profileInfo = response.data));
    },

    async ProfileEdit() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      this.popUp = true;

      await axios
        .get(
          `${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`
        )
        .then((response) => (this.updateDetails = response.data));
    },

    async SubmitProfile() {
      let details_id = this.profileInfo.data.id;
      let token = this.userInfo.token_key;
      let update = this.updateDetails;
      this.popUp = false;
      // console.log("updateDetails",updateDetails)

      await axios
        .put(
          `${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`,
          update
        )
        .then((response) => {
          if (response.data.message == "Profile updated successfully") {
            this.messages = {
              text: "Profile Uploaded successfully",
              type: "success",
            };
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error",
            };
          }
        });

      setTimeout(() => {
        this.messages = {
          text: "",
          type: "null",
        };
        this.openProcess();
      }, 3000);
    },
  },
};
</script>

<style scoped>
#image_uploads {
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 4px black;
  width: 350px;
  outline: none;
}

.prime {
  color: #333333 !important;
}

.blues {
  color: #0F172A !important;
}

.bluesbg {
  background-color: #0F172A !important;
}

/* .width{
  height: 100% !important;
} */

.endss {
  display: flex !important;
  justify-items: end !important;
  justify-content: end !important;
  align-items: flex-end !important;
}

.relativep {
  position: relative !important;
}

.absolutes {
  position: absolute !important;
  top: 8% !important;
  right: 70% !important;
  z-index: 100 !important;
}

.contents {
  width: 40% !important;
}


.heightc {
  height: 500px !important;
}

.heightfull {
  height: 450px !important;
}
</style>
