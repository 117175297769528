<template>

    <div class="widthclass rounded-3xl bg-white shadow-lg pt-10 mt-3 ml-10"> 
        <div class="px-10 py-4 flex flex-col gap-10">  
            <div class="flex flex-col gap-4"> 
                <router-link :to='{name :"coursestep"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='course_step'}" @click="pushRoute('coursestep')"> 
                    <img src="@/assets/new/grid1.png" v-if="actived=='course_step'" alt="">
                    <img src="@/assets/new/grid2.png" v-if="actived!='course_step'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='course_step'}">Courses</p>
                </router-link>
                <router-link :to='{name :"messageStudent"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='messages'}" @click="pushRoute('messageStudent')"> 
                    <img src="@/assets/new/arc2.png" v-if="actived=='messages'" alt="">
                    <img src="@/assets/new/arc1.png" v-if="actived!='messages'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='messages'}">Messages</p>
                </router-link>
                <router-link :to='{name :"assignmentStudent"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='assignment'}" @click="pushRoute('assignmentStudent')">
                    <img src="@/assets/new/notes2.png" v-if="actived=='assignment'" alt="">
                    <img src="@/assets/new/notes1.png" v-if="actived!='assignment'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='assignment'}">Assignments</p>
                </router-link>
                <router-link :to='{name :"studentEvents"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='events'}" @click="pushRoute('studentEvents')"> 
                    <img src="@/assets/new/cal2.png" v-if="actived=='events'" alt="">
                    <img src="@/assets/new/cal1.png" v-if="actived!='events'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='events'}">Live Classes</p>
                </router-link>
                <router-link :to='{name :"chatStudent"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='forums'}" @click="pushRoute('chatStudent')"> 
                    <img src="@/assets/new/mess2.png" v-if="actived=='forums'" alt="">
                    <img src="@/assets/new/mess1.png" v-if="actived!='forums'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='forums'}">Forums</p>
                </router-link>
                <router-link :to='{name :"studentQuiz"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='quiz'}" @click="pushRoute('studentQuiz')"> 
                    <img src="@/assets/new/bar2.png" v-if="actived=='quiz'" alt="">
                    <img src="@/assets/new/bar1.png" v-if="actived!='quiz'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='quiz'}">Assessments</p>
                </router-link>
                <router-link :to='{name :"Reportview"}' class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='Report'}" @click="pushRoute('Reportview')"> 
                    <img src="@/assets/new/cloud2.png" v-if="actived=='Report'" alt="">
                    <img src="@/assets/new/cloud1.png" v-if="actived!='Report'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='Report'}">Reports</p>
                </router-link>
            </div>
        </div>
    </div>

<!-- <div class="sm:flex lg:hidden left-0 fixed top-19 z-50">
    <button @click="toggleClassMenu" class="shadow-xl rounded-r-md border border-white bg-blue-700 z-50" aria-label="Open Menu" style="z-index: 289;">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8 text-white">
            <path d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
    </button>
</div>

<div class="layout text-base shadow-xl" id="clickfun">
  
    <div class="leftmenu absolute left-0 h-full flex flex-col lg:hidden justify-start items-start w-full text-gray-100 font-medium bg-black bg-opacity-75 " style="z-index: 289;width:100vw">
        <div class="w-44 md:w-52 h-full flex flex-col gap-2 md:gap-3 bg-white">
           
             <router-link :to='{name :"coursestep"}' class='cursor-pointer mx-auto'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'primary-border bg-gray-100 border-l-4' : actived=='course_step'}">
                    <img src="../../assets/assignment.svg" class="w-5 h-5">
                    <p class="mb-0">Courses</p>
                </div>
            </router-link>

            <router-link :to='{name :"messageStudent"}' class='cursor-pointer mx-auto'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='messages'}">
                    <img src="../../assets/message.svg" class="w-5 h-5">
                    <div>Messages</div>
                </div>
            </router-link>

            <router-link :to='{name :"assignmentStudent"}' class='cursor-pointer mx-auto'>
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='assignment'}">
                    <img src="../../assets/assignment.svg" class="w-5 h-5" alt="">
                    <div>Assignments</div>
                </div>
            </router-link>

            
            <router-link :to="{name:'studentEvents'}" class="mx-auto">
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='events'}">
                    <img src="../../assets/events.svg" class="w-5 h-5" alt="">
                    <div> Live classes </div>    </div>
                
            </router-link>
            <router-link :to='{name :"chatStudent"}' class="mx-auto">
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='forums'}">
                    <img src="../../assets/forums.svg" class="w-5 h-5" alt="">
                    <div>Forums</div>
                </div>
            </router-link>
            <router-link :to='{name :"studentQuiz"}' class="mx-auto">
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'primary-border bg-gray-100 border-l-4' : actived=='quiz'}">
                    <img src="../../assets/brain.svg" class="w-6 h-6" alt="">
                    <p class="mb-0">Quizrmative Assesment</p>
                </div>
            </router-link>
            <router-link :to='{name :"Reportview"}' class="mx-auto">
                <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Report'}">
                    <img src="../../assets/brain.svg" class="w-6 h-6" alt="">
                    <p class="mb-0">Assesment Reports</p>
                </div>
            </router-link>
    
        </div>
        <img src="../../assets/closebutton.svg" @click="toggleClassMenu" class="cursor-pointer absolute menu-icon w-8 h-8 right-2">
    </div>

    
    <div class="hidden w-48 2xl:w-56 largerLayout h-full lg:flex lg:flex-col justify-start items-start text-black font-medium relative gap-2 ">
        <div class="collapseDiv w-48 2xl:w-52 flex items-center bottom-0 cursor-pointer px-4 py-1 gap-2 border rounded-md border-gray-100 shadow-sm mx-auto" @click="slideFn">
            <img src="../../assets/leftArrow.svg" class="w-7 p-1 LeftArrow " />
            <p class="collapseText text-sm mb-0">Collapse sidebar</p>
        </div>

     

        <router-link :to='{name :"coursestep"}' class='cursor-pointer mx-auto'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='course_step'}">
                <img src="../../assets/assignment.svg" class="w-6 h-6">
                <p class="mb-0">Course</p>
            </div>
        </router-link>


        <router-link :to='{name :"messageStudent"}' class='cursor-pointer mx-auto'>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='messages'}">
                <img src="../../assets/message.svg" class="w-6 h-6">
                <p class="mb-0">Messages</p>
            </div>
        </router-link>

        <router-link :to='{name :"assignmentStudent"}' class='cursor-pointer mx-auto '>
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='assignment'}">
                <img src="../../assets/assignment.svg" class="w-6 h-6" alt="">
                <p class="mb-0">Assignments</p>
            </div>
        </router-link>
     

        <router-link :to="{name:'studentEvents'}" class="mx-auto">
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='events'}">
                <img src="../../assets/events.svg" class="w-6 h-6" alt="">
                <p class="mb-0"> Live classes </p>
            </div>
        </router-link>

        <router-link :to='{name :"chatStudent"}' class="mx-auto">
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='forums'}">
                <img src="../../assets/forums.svg" class="w-6 h-6" alt="">
                <p class="mb-0">Forums</p>
            </div>
        </router-link>
        <router-link :to='{name :"studentQuiz"}' class="mx-auto">
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='quiz'}">
                <img src="../../assets/brain.svg" class="w-6 h-6" alt="">
                <p class="mb-0">Formative Assesment</p>
            </div>
        </router-link>
        <router-link :to='{name :"Reportview"}' class="mx-auto">
            <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Report'}">
                <img src="../../assets/brain.svg" class="w-6 h-6" alt="">
                <p class="mb-0">Assesment Reports</p>
            </div>
        </router-link>


    </div>
</div> -->


</template>

<script>

import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
    data() {
        return {
            profileInfo: null,
        }
    },

    props: {
        actived: String
    },

    computed: {
        ...mapGetters(["isAuthenticated", "userInfo"]),
    },

    created(){
        if (this.userInfo) {
            let token = this.userInfo.token_key;
            let slug_id = this.userInfo.slug_id;
            axios
                .get(
                `${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`
                )
                .then((response) => (this.profileInfo = response.data));
            } else {
                console.log("Not Login");
            }
    },

    mounted() {
        // this.actived = "course_step";
        // var oppMenu = document.querySelector(".menu-icon");
        // oppMenu.addEventListener("click", this.toggleClassMenu, false);
    },
    methods: {

        pushRoute(name){
            this.$router.push({name: name})
        },

        slideFn() {
            console.log("Slide")

            document.querySelector(".LeftArrow").classList.toggle("rotateing");
            document.querySelector(".collapseText").classList.toggle("hidden");
            document.querySelector(".collapseDiv").classList.toggle("border-none");

            var largelayout = document.querySelector(".largerLayout")
            if (!largelayout.classList.contains("app-menu-close")) {
                largelayout.classList.add("app-menu-close");
            } else {
                largelayout.classList.remove("app-menu-close");
            }
        },
        toggleClassMenu() {
            console.log("toggle");
            var layout = document.querySelector(".layout");
            if (!layout.classList.contains("app-menu-open")) {
                layout.classList.add("app-menu-open");
            } else {
                layout.classList.remove("app-menu-open");
            }
        }
    }
}
</script>

<style scoped>


.widthclass{
    width: 20% !important;
}

.activebg{
    background-color: #0085DB !important;
}
.text-white{
    color: #fff !important;
}
.gray400{
    color: #333333;
}


</style>
