<template>
  <div class="min-h-screen relative">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="w-11/12 2xl:w-4/6 mx-auto relative  m-4 p-8 my-10 rounded-3xl shadowimp">
      <!-- <router-link :to="{name :'InstructorHome'}" class="absolute right-8 top-8">
            <div class='mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white'>
                <div class=" text-sm  text-gray-700 hover:text-teal-500 ">Back </div>
                <div class="flex items-center">
                <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png"/>
                </div>
            </div>
      </router-link>-->

      <!-- <router-link v-else :to="{name :'organizationCourse',params:{slug_id:userInfo.slug_id}}" class="absolute right-8 top-8">
        <div class='mb-1 cursor-pointer rounded-lg flex'>
            <div class="px-1 md:px-2 py-1 text-sm md:text-base text-gray-700 hover:text-red-500">Close </div>
            <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
        </div>
      </router-link>-->
      <form
        ref="addCourseRef"
        @submit.prevent="createCourse"
        class="w-full lg:p-4 mx-auto lg:w-12/12"
      >
        <h1 class="py-2 text-xl text-center text-gray-600" style="color: #01B0F9 !important;">Add Course</h1> 


        <!-- <OwnEditor /> -->


        <div class="parent mt-10">
          
          <div class="relative  mb-10">
            <label for="title" class="" style="">
              Title
              <span class="text-red-400">*</span>
            </label>
            <input
              id="title"
              type="text"
              required
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>

          <div class=" mb-10">
            <label for="category" class="flex gap-2 items-center ">
              <div>
                Category
                <span class="text-red-400">*</span>
              </div>
              <!-- <font-awesome-icon
                @click="is_CategoryPopup=true"
                class="text-base text-green-500 self-end h-6 w-6 cursor-pointer hover:text-green-800"
                :icon="['fas','plus']"
                title="Add Category"
              /> -->
            </label>
            <select
              name="category"
              required
              class="py-2 px-4 block w-full rounded-md text-sm" 
              id="category"
            >
              <option
                v-for="category in categories"
                :key="category"
                :value="category.id"
              >{{category.title}}</option>
            </select>
          </div>
          <div class="flex justify-start items-center gap-2 px-2 mb-10">
            <input id="freeCourse" type="checkbox" class />
            <label for="freeCourse" class="text-sm mt-1">Free Course</label>
          </div>

          <div class="col-start-1 col-end-4  mb-10">
            <label for="short-desc" class="">
              Short Description
              <span class="text-red-400">*</span>
            </label>

            <textarea
              id="short-desc"
              required
              maxlength="1000"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            ></textarea>
          </div>
          <div class="col-start-1 col-end-4  mb-10">
            <!-- <label for="description" class="">
              Description
              <span class="text-red-400">*</span>
            </label> -->
            <div> 
              <MarkdownEditor class="border" />
              <!-- <QuillEditor
                  style="height: 400px"
                  theme="snow"
                  toolbar="full"
                  :content="description"
                  contentType="html"
                /> -->
            </div>
            
          </div>
          <div class="col-start-1 col-end-4  mb-10">
            <label for="outcome" class="">Outcome</label>
            <textarea
              id="outcome"
              maxlength="200"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            ></textarea>
          </div>
          <div class="col-start-1 col-end-4  mb-10">
            <label for="requirement" class="">Requirement</label>
            <textarea
              id="requirement"
              maxlength="200"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            ></textarea>
          </div>
          <div class=" mb-10">
            <label for="language" class="">Language</label>
            <input
              id="language"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div class=" mb-10">
            <label for="price" class="">
              Price
              <span class="text-red-400">*</span>
            </label>
            <input
              id="price"
              type="text"
              required
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div class=" mb-10">
            <label for="level" class="">
              Level
              <span class="text-red-400">*</span>
            </label>
            <select name="level" required class="rounded-md p-2 block w-full text-sm" id="level">
              <option
                v-for="level in ['beginner', 'intermediate', 'advanced']"
                :key="level"
                :value="level"
              >{{level}}</option>
            </select>
          </div>
          <div class="mb-10">
            
            <label for="thumbnail" class="">Thumbnail</label>
            
            <!-- <div> 
            </div> -->

            <input
              id="thumbnail"
              type="file"
              class="rounded-md text-sm w-full p-2 mt-2" 
              style="position: relative !important; z-index: 2 !important; background-color: white !important;"
            />
          </div>

          <!-- <div class>
            <label for="video_file">Video File</label>
            <input
              id="video_file"
              type="file"
              @change="handleFileChange"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div> -->
          <!-- movebody
movebody
movebody
movebody
movebody
movebody
movebody
movebody
movebody
movebody
movebody
movebody -->

          <div class=" mb-10">
            <label for="video_url" class="">Video URL</label>
            <input
              id="video_url"
              type="text"
              class="border border-gray-300 rounded-md text-sm w-full p-2" 
            
            />
          </div>
          <div class="flex items-center gap-2 mb-10">
            <input type="checkbox" id="Published" />
            <label for="checkbox" class="text-sm mt-1">Published</label>
          </div>
        </div>

        <!-- <div v-if="isUploading" class="py-3">
          <p class="text-sm">Video Upload Progress</p>
          <progress :value="uploadProgress" max="100"></progress>
          <p class="text-sm">{{ uploadProgress }}%</p>
        </div> -->
        <div class="flex justify-end">   
          <button type="submit" class="mt-10 px-3 py-1 rounded-lg" style="background-color: #0F172A !important; color: white !important;">Save</button>
        </div>
       
      </form>

      <!-- <div v-if="!isUploading" class="w-full lg:p-4 mx-auto lg:w-10/12">
            <progress :value="uploadProgress" max="100"></progress>
            <p>{{ uploadProgress }}%</p>
      </div>-->
    </div>

    <!-- Category Add in Course  -->
    <div
      v-if="is_CategoryPopup"
      class="fixed top-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
      style="z-index:5001"
    >
      <div
        class="w-5/6 lg:w-2/3 h-1/3 2xl:w-1/3 2xl:h-1/5 bg-white card1 flex flex-col justify-center"
      >
        <div @click="is_CategoryPopup=false" class="absolute right-8 top-8 cursor-pointer z-50">
          <div class="mb-1 rounded-lg flex border cursor-pointer">
            <div
              class="px-1 md:px-2 py-1 text-sm md:text-base text-gray-700 hover:text-red-500"
            >Close</div>
            <img class="h-5 w-5 md:h-8 md:w-8" src="@/assets/closebutton.svg" />
          </div>
        </div>
        <h4 class="text-gray-700 text-center">Add Category</h4>
        <form class="w-11/12 mx-auto" @submit.prevent="categoryAdd">
          <label for="title">
            Title
            <span class="text-red-400">*</span>
          </label>
          <input
            id="title"
            type="text"
            required
            class="border border-gray-300 rounded-md text-sm w-full p-2"
          />
          <button type="submit" class="gradient-btn mt-4 text-xs">Add course</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CustomSelect from "@/components/buttons/CustomSelect.vue";
// import MarkdownEditor from "@/components/MarkdownEditor.vue"
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import { mapGetters } from "vuex";
import FlashMessage from "@/components/flashMessage.vue";

// import OwnEditor from "@/components/OwnEditor.vue";

export default {
  computed: mapGetters(["userInfo"]),
  components: {
    CustomSelect,
    MarkdownEditor,
    FlashMessage,
    // OwnEditor
  },
  data() {
    return {
      categories: null,
      info: null,
      msgverify: "",
      messages: {
        text: "",
        type: "null"
      },
      is_CategoryPopup: false,
      description: "",

      videoFile: null,
      chunkSize: 10 * 1024 * 1024, // 10 MB
      totalChunks: 0,
      currentChunk: 0,
      isUploading: false,
      uploadProgress: 0
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.videoFile = file;
        this.totalChunks = Math.ceil(file.size / this.chunkSize);
        console.log(this.totalChunks, " this.totalChunks:::");
      } else {
        alert("Please select a valid video file.");
      }
    },

    getFileChunk() {
      const start = this.currentChunk * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.videoFile.size);
      return this.videoFile.slice(start, end);
    },

    updateProgress(progressEvent) {
      if (progressEvent.lengthComputable) {
        const total = progressEvent.total;
        const loaded = progressEvent.loaded;
        this.uploadProgress = Math.round((loaded / total) * 100);
      }
    },

    // async submitForm() {
    //   this.isUploading = true;
    //   this.uploadProgress = 0;
    //   this.currentChunk = 0;

    //   const metadataFormData = new FormData();
    //   metadataFormData.append("courseTitle", this.courseTitle);
    //   metadataFormData.append("courseDescription", this.courseDescription);
    //   metadataFormData.append("chunk", this.currentChunk);
    //   metadataFormData.append("totalChunks", this.totalChunks);
    //   metadataFormData.append("file", this.videoFile);

    //   try {
    //     const firstChunk = this.getFileChunk();
    //     metadataFormData.append("chunkFile", firstChunk);

    //     await axios.post(
    //       "http://localhost:8000/upload-video-chunk/",
    //       metadataFormData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data"
    //         }
    //       }
    //     );

    //     this.currentChunk++;
    //     await this.uploadChunks();
    //   } catch (error) {
    //     console.error("Upload error: ", error);
    //     alert("Failed to upload the first chunk.");
    //     this.isUploading = false;
    //   }
    // },

    async uploadChunks() {
      while (this.currentChunk < this.totalChunks) {
        const chunkFormData = new FormData();
        chunkFormData.append("chunk", this.currentChunk);
        chunkFormData.append("totalChunks", this.totalChunks);
        chunkFormData.append("file", this.videoFile);
        const chunk = this.getFileChunk();
        chunkFormData.append("chunkFile", chunk);
        try {
          await axios.post(
            `${process.env.VUE_APP_API}/coursenew/?Key=${token}`,
            chunkFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              },
              onUploadProgress: progressEvent => {
                this.updateProgress(progressEvent);
              }
            }
          );
          this.currentChunk++;
        } catch (error) {
          console.error("Upload error: ", error);
          alert("Failed to upload chunk.");
          break;
        }
      }
      if (this.currentChunk >= this.totalChunks) {
        alert("Video upload completed!");
        this.isUploading = false;
      }
    },

    async createCoursenew(e) {
      this.isUploading = true;
      this.uploadProgress = 0;
      this.currentChunk = 0;

      let token = this.userInfo.token_key;

      let urls = `${process.env.VUE_APP_API}/coursenew/?Key=${token}`;
      let HeaderVal = new FormData();
      if (e.target.elements.thumbnail.files.length > 0) {
        var thumbnailVal = e.target.elements.thumbnail.files[0];
        HeaderVal.append("thumbnail", thumbnailVal);
      }

      HeaderVal.append("title", e.target.elements.title.value);
      HeaderVal.append("category", parseInt(e.target.elements.category.value));
      HeaderVal.append(
        "short_description",
        e.target.elements["short-desc"].value
      );
      HeaderVal.append("description", e.target.elements[4].value);
      HeaderVal.append("outcome", e.target.elements.outcome.value);
      HeaderVal.append("requirements", e.target.elements.requirement.value);
      HeaderVal.append("language", e.target.elements.language.value);
      HeaderVal.append("price", e.target.elements.price.value);
      HeaderVal.append("course_free", e.target.elements.freeCourse.checked);
      HeaderVal.append("level", e.target.elements.level.value);
      HeaderVal.append("video_url", e.target.elements.video_url.value);
      HeaderVal.append("is_published", e.target.elements.Published.checked);
      HeaderVal.append("instructor", this.userInfo.Instructor_id);
      HeaderVal.append("organization_id", this.userInfo.organizes_id);
      HeaderVal.append("file", this.videoFile);

      try {
        const firstChunk = this.getFileChunk();
        HeaderVal.append("chunkFile", firstChunk);

        // await axios.post('http://localhost:8000/upload-video-chunk/', metadataFormData, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //     },
        // });

        await axios
          .post(urls, HeaderVal, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            console.log(response);
            if (response.data.message == "your Course added successfully") {
              this.messages = {
                text: "Courses Added successfully",
                type: "success"
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "null"
                };
                this.$refs.addCourseRef.reset();
                this.$router.push({
                  name: "InstructorCourse"
                });
              }, 1500);
            } else {
              this.messages = {
                text: " Event Faild",
                type: "error"
              };
              setTimeout(() => {
                this.messages = {
                  text: "",
                  type: "null"
                };
              }, 2000);
            }
        });

        this.currentChunk++;
        await this.uploadChunks();

      } catch (error) {
        console.error("Upload error: ", error);
        alert("Failed to upload the first chunk.");
        this.isUploading = false;
      }
    },

    // add the new Category in Course
    categoryAdd(el) {
      let bodyVal = {
        title: el.target.elements.title.value
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/categorynew/?Key=${this.userInfo.token_key}`,
          bodyVal
        )
        .then(response => {
          console.log(response.data.message);
          if (response.data.message == "your Category added successfully") {
            this.messages = {
              text: "Category Added successfully",
              type: "success"
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null"
              };
              this.is_CategoryPopup = false;
              this.categoryget();
            }, 1500);
          } else {
            this.messages = {
              text: response.data.message,
              type: "error"
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null"
              };
            }, 2000);
          }
        });
    },



    async createCourse(e) {
      let token = this.userInfo.token_key;

      // this.description = document.querySelector(".ql-editor").innerHTML;
      // console.log(this.description);

      let urls = `${process.env.VUE_APP_API}/coursenew/?Key=${token}`;
      let HeaderVal = new FormData();
      if (e.target.elements.thumbnail.files.length > 0) {
        var thumbnailVal = e.target.elements.thumbnail.files[0];
        HeaderVal.append("thumbnail", thumbnailVal);
      }

      HeaderVal.append("title", e.target.elements.title.value);
      HeaderVal.append("category", parseInt(e.target.elements.category.value));
      HeaderVal.append(
        "short_description",
        e.target.elements["short-desc"].value
      );
      // HeaderVal.append("description", this.description);
      HeaderVal.append("description", e.target.elements[4].value);
      HeaderVal.append("outcome", e.target.elements.outcome.value);
      HeaderVal.append("requirements", e.target.elements.requirement.value);
      HeaderVal.append("language", e.target.elements.language.value);
      HeaderVal.append("price", e.target.elements.price.value);
      HeaderVal.append("course_free", e.target.elements.freeCourse.checked);
      HeaderVal.append("level", e.target.elements.level.value);
      HeaderVal.append("video_url", e.target.elements.video_url.value);
      HeaderVal.append("is_published", e.target.elements.Published.checked);
      HeaderVal.append("instructor", this.userInfo.Instructor_id);
      HeaderVal.append("organization_id", this.userInfo.organizes_id);

      await axios
        .post(urls, HeaderVal, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log(response);
          if (response.data.message == "your Course added successfully") {
            this.messages = {
              text: "Courses Added successfully",
              type: "success"
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null"
              };
              this.$refs.addCourseRef.reset();
              this.$router.push({
                name: "InstructorCourse"
              });
            }, 1500);
          } else {
            this.messages = {
              text: " Event Faild",
              type: "error"
            };
            setTimeout(() => {
              this.messages = {
                text: "",
                type: "null"
              };
            }, 2000);
          }
        });
    },
    categoryget() {
      axios
        .get(`${process.env.VUE_APP_API}/category/?format=json`)
        .then(res => {
          this.categories = res.data.data;
        });
    }
  },
  created() {
    this.categoryget();
  }
};
</script>

<style scoped> 

.shadowimp{
  box-shadow: 0px 2px 30px 0px #01B0F933 !important;
}

.movehead{
  position: relative !important; 
}

. {
  position: absolute !important; 
  top: -14px !important; 
  left:20px !important; 
  z-index: 5 !important; 
  background-color: white !important; 
  font-size: 13px !important;
}

progress {
  width: 100%;
  height: 20px;
}

select {
  line-height: 1.5em;
}

.parent {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}
</style>
