<template lang="">
<div class="flex relative" style="height:calc(100vh - 113px);">
    <leftNavigationStudent actived="Report" />
    <div class="flex flex-col w-full h-full">
        <!-- New Structure -->
        <div class='overflow-y-scroll h-screen flex justify-center mt-6 md:mt-0 w-full' style="scrollbar-color:gray white">
            <div class="w-11/12 p-0 md:p-4 flex justify-center ">
                <div class="mt-4 inline-block shadow-xl rounded-3xl overflow-x-auto md:overflow-y-scroll h-full w-full">
                    <table class="leading-normal w-full">
                        <thead >
                            <tr>
                                <th
                                class="px-2 lg:px-5 py-2 lg:py-8 items-center border-b border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                                >
                                Sl.No
                                </th>
                                <th
                                class="px-2 lg:px-5 py-2 lg:py-8 border-b border-l border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                                >
                                Quiz Name
                                </th>
                                <th
                                class="px-2 lg:px-5 py-2 lg:py-8 border-b border-l border-r border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                                >
                                Mark
                                </th>
                                <th
                                class="px-2 lg:px-5 py-2 lg:py-8 border-b border-gray-200 text-center text-lg font-medium colorprimary tracking-wider"
                                >
                                Date
                                </th>
                            </tr>
                            <!-- <tr>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 items-center border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Sl.no
                                </th>
                                <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Quiz Name
                                </th>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold  text-gray-600 uppercase tracking-wider">
                                    Mark
                                </th>
                                <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold  text-gray-600 uppercase tracking-wider">
                                    Date
                                </th>

                            </tr> -->
                        </thead>
                        <tbody v-if="reportlist.data">
                            <tr v-for="el,index in this.reportlist.data" :key="el">
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border-b border-gray-200 bg-white text-xs lg:text-sm">
                                    <div class="flex items-center justify-center">
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {{ index + 1 }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm">
                                    <p v-if="el.quiz" class="text-center text-gray-900 whitespace-no-wrap">
                                        {{el.quiz.name}}
                                    </p>
                                    <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                        empty
                                    </p>
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    {{el.quiz.quiztakers_set.score}}
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    
                                    {{ new Date(el.quiz.quiztakers_set.finished_at).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }} - {{ new Date(el.quiz.quiztakers_set.finished_at).toLocaleTimeString('en-IN') }} 
                                </td>
                                
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    empty
                                </td>
                                <td class="px-2 lg:px-5 py-4 lg:py-8 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                    empty
                                </td>
                                

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
import axios from "axios";

import {
    mapGetters
} from "vuex";

export default {
    data() {
        return {
            reportlist : ''
        }
    },

    props: ["course_id", "course_name"],
    components: {
        leftNavigationStudent,

    },

    async created() {
        await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
        .then(res=>{
            this.reportlist = res.data
            console.log(this.reportlist)
        })
    },

    computed: {
        ...mapGetters(["userInfo"]),
    },

}
</script>

<style scoped>

.bgblue{
  background-color: #01B0F9 !important;
  color: white;
}

.colorprimary{
  color: #01B0F9 !important;
}
    
</style>
