<template lang="">
<div class='flex' style="height:calc(100vh - 113px);">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <leftNavigationStudent actived='events' />
     
    <!-- <div class="absolute right-24 bottom-24 w-1/2 opacity-40">
        <img src="../../assets/eventspage.svg" alt="" class="w-full">
    </div>  -->

    <div class="w-full h-full">

        <div class="w-full mx-auto overflow-y-scroll h-full"> 

            <div class="px-10"> 
                

                <div class="grid lg:grid-cols-3 gap-4 py-10">
                    <div v-for="events in eventInfo" :keys="events" class="heightc card1 p-6">
                        <div>
                            <div class="relative flex justify-center items-center" style="height: 60%"> 

                                <div class="absolute top-4 left-6 bg-white z-20 px-4 rounded-lg flex items-center gap-2">
                                    <div  style="background-color: red; width: 10px; height: 10px; border-radius: 100%;"></div>
                                    <p class="text-xs relative top-1 font-bold">Live</p>
                                </div>

                                 <!--<img v-if="course.thumbnail" class="object-cover object-center h-full w-full rounded-lg"
                                :src="course.thumbnail" alt="" />  -->
                                <img class="rounded-xl" src="@/assets/new/events.png" style="width: 100%" alt="" />

                                <!-- <button
                                class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md"
                                >
                                {{ course.category }}
                                </button> -->

                            </div>

                            <div class="text-center flex flex-col gap-1 justify-center" style="height: 45%">
                                <p class="text-sm text-gray-600 font-semibold px-4 text-left truncate mb-0 mt-2">{{events.title}}</p>
                                <p class="text-xs text-gray-600 px-4 text-left truncate mb-0"><span class="text-gray-600" style="color: #FF2929 !important;">{{ events.date }}</span></p>
                                <p class="text-xs text-gray-600 px-4 text-left truncate mb-0 font-bold py-3">Zoom Meeting</p>
                         
                                
                                <div> 

                                <!-- <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium">Continue Learning
                                </p>  -->

                                <div v-if="events.studentlist != null"> 
                                    
                                    <div v-if="events.studentlist.indexOf(String(this.userInfo.user.id))+1 != 0 ">
                                        <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium" v-if="new Date(events.date) >= new Date()"> <a :href="events.url">Join Class </a>
                                        </p>  
                                        <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"  v-if="new Date(events.date) <= new Date()">
                                            <a :href="events.url">Join Class </a>
                                        </p>  
                                    </div>
                                    
                                    <div v-else-if="events.count-events.studentlist.length==0">
                                        <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium">Booking Closed
                                        </p>  
                                    </div>

                                    <div v-else-if="new Date(events.date) >= new Date()">
                                        <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium">Book Class
                                        </p>  
                                    </div>

                                 
                                    <div v-else>
                                        <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium">Booking Closed
                                        </p>  
                                    </div>
                                    <!--  -->

                                </div> 

                                <div v-else>
                                    <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"  v-if="new Date(events.date) <= new Date()">Booking Closed
                                        </p>  

                                        <p class="my-3 py-2 text-sm rounded-lg border-2 border-blues text-blues font-medium"  v-if="new Date(events.date) >= new Date()" @click="bookevents(events)">Book Class
                                        </p>  
                                </div> 

                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <main class="flex  flex-wrap justify-center items-center xl:justify-start xl:items-start  gap-4 py-2 px-20 mx-auto mt-5"> 

                <div id="events" v-for="events in eventInfo" :keys="events" class="border rounded w-72 p-1 px-2 md:p-4  flex flex-col gap-4 bg-white rounded-lg shadow-lg relative h-96">
                    <div class="">
                        <p class="text-4xl font-bold lg:text-4xl 2xl:text-6xl text-gray-500">
                            {{events.display_date.substring(0,2)}}
                        </p>
                        <p class="text-base md:text-xl text-gray-500">
                            {{events.display_date.substring(2)}}
                        </p>
                    </div>

                  

                    <div class="h-60">
                        <p class="text-red-400 text-sm">
                            @{{events.date.substring(11,16)}}
                        </p>
                        <p class="text-sm xl:text-base text-gray-600 text-justify ">
                            {{events.title}}
                            
                        </p>
                    </div>
                    <div>
                        
                    </div>
                  
                        <div v-if="events.studentlist != null"> 

                            <p class="text-sm pb-1">{{ events.date }}</p>

                            <div v-if="events.studentlist.indexOf(String(this.userInfo.user.id))+1 != 0 ">

                                <button class="gradient-btn" v-if="new Date(events.date) >= new Date()">
                                    <a :href="events.url" class="text-white hover:text-white" target="_blank">Join Class</a>
                                </button>
                                <button v-if="new Date(events.date) >= new Date()">
                                    <a :href="events.url"  target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;<img src="https://img.icons8.com/color/38/000000/documents.png"/></a>
                                </button>    
                                
                                <div v-if="new Date(events.date) <= new Date()" class="flex flex-row items-center gap-3">
                                    <div>       
                                        <button  class="gradient-btn">Class Ended</button>
                                    </div>
                                    <div>       
                                        <img width="32" height="32" src="https://img.icons8.com/stencil/32/record.png" alt="record"/>
                                    </div>
                                </div>
                            </div>

                            <button v-else-if="events.count-events.studentlist.length==0" class="border bg-blue-700 text-white px-2 border-blue-500 text-sm py-1">Booking Closed</button>
                            <button v-else-if="new Date(events.date) >= new Date()" @click="bookevents(events)" class="gradient-btn">Book Class</button>

                            <button v-else class="border bg-blue-700 text-white px-2 border-blue-500 text-sm py-1">Booking Closed </button>

                        </div>

                        <div v-else class='flex items-center'>
                            <button v-if="new Date(events.date) <= new Date()" class="border bg-blue-700 text-white px-2 border-blue-500 text-sm py-1">Booking Closed</button>
                            <button v-if="new Date(events.date) >= new Date()" @click="bookevents(events)" class="gradient-btn">Book Class</button>
                        </div>

                    <div v-if="events.course_data==null" class="absolute border rounded-md text-black border-blue-800 text-sm py-1 right-2 px-1">Organization</div>
                </div>
            </main> -->
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from 'vuex'
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";
export default {
    data() {
        return {
            eventInfo: "",
            messages: {
                text: "",
                type: 'null',
            },
            courseDetails: null,
            current_date: new Date().toLocaleDateString('en-IN'),
            cr_date: new Date().toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            })

        }
    },

    props: ["course_id", "slug", "instructor_id", "course_name"],

    components: {
        leftNavigationStudent,
        FlashMessage
    },

    computed: mapGetters(["userInfo"]),

    created() {
        this.geteventinfo();
        axios
            .get(`${process.env.VUE_APP_API}/courses/?format=json`)
            .then((response) => (this.courseDetails = response.data.data));
    },

    methods: {
        async geteventinfo() {
            // await axios.get(`${process.env.VUE_APP_API}/calendar/view/?user=${this.instructor_id}&course_data=${this.course_id}&Key=${this.userInfo.token_key}`)       issues  courseFilter = null values unable to be passed
            await axios.get(`${process.env.VUE_APP_API}/calendar/view/?user=${this.instructor_id}&Key=${this.userInfo.token_key}`)
                .then(response => {
                    if (Object.keys(response.data) == "error") {
                        console.log("Null")
                    } else {
                        this.eventInfo = response.data.data
                        this.eventInfo = this.eventInfo.filter(element => {
                            return element.course_data == this.course_id || element.course_data == null
                        })
                        this.eventInfo.forEach(element => {
                            element["display_date"] = new Date(element.date).toLocaleDateString('en-IN', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })
                        })
                        this.eventInfo = this.eventInfo.sort(function (el1, el2) {
                            let x = new Date(el1.date),
                                y = new Date(el2.date);
                            return y - x;
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },

        bookevents(val) {
            if (val.studentlist == null) {
                let list_el = []
                list_el.push(this.userInfo.user.id)
                val.studentlist = list_el
            } else {
                let list_el1 = Object.values(val.studentlist)
                list_el1.push(this.userInfo.user.id)
                val.studentlist = list_el1
            }
            const bookedvalues = {

                "date": val.date,
                "title": val.title,
                "status": val.status,
                "url": val.url,
                "studentlist": val.studentlist,
                "count": val.count,
                "duration": val.duration,
                "organization_id": val.organization_id,
                "user": val.user,
                "course_data": val.course_data,
            };

            axios.put(`${process.env.VUE_APP_API}/calendarupdated/${val.id}/?Key=${this.userInfo.token_key}`, bookedvalues)
                .then(response => {
                    if (response.statusText == 'OK') {
                        this.messages = {
                            text: "Booked successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }

                })

            setTimeout(() => {
                this.messages = {
                    text: " ",
                    type: 'null',
                }
                this.geteventinfo()
            }, 2000)

        }
    }

}
</script>

<style scoped>
#events:hover {
    transform: scale(1.02);
    transition: all .4s;
}
</style>
