<template lang="">
<div class="w-full flex shadow-2xl" style="height:calc(100vh - 113px);">
    <NavigationInstructor actived="assignments" />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />

    <div class="w-full h-full">
        <!-- Breadcrumb -->
        <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'super_admin'" class="text-gray-500 text-sm">
                    <router-link :to='{name :"organizationCourse",params:{slug_id:this.userInfo.slug_id}}'>
                        Dashboard
                    </router-link> >
                </li>
                <li v-else class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm ">
                    <router-link :to="{name :'InstructorStudents'}">
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to='{name :"InstructorAssignments"}'>
                        Assignments
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Submitted Assignments

                </li>
            </ol>
        </div> -->
        <div class="w-full h-full relative">
            <div class="w-full flex justify-center lg:justify-start py-1 px-1 text-gray-900 bg-white border-gray-300 text-xl capitalize">
                <p class="mb-0"> {{course_name}} </p>
            </div>
            <p class="px-4">Assignments Submitted by Students</p>
            <router-link :to='{name :"InstructorAssignments"}' class="absolute right-8 top-8">
                <div class='mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white'>
                    <div class=" text-sm  text-gray-700 hover:text-teal-500 ">Back </div>
                    <div class="flex items-center">
                        <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png" />
                    </div>
                </div>
            </router-link>
            <main class="py-2 w-full mx-auto h-auto">
                <div v-if="assignment_list !=null" class="h-full flex gap-4 flex-wrap px-4">
                    <div v-for="assignment in assignment_list" :keys="assignment" @click="detailsPopup(assignment)" class="border w-80 p-1 px-2 md:p-4  flex flex-col gap-4 bg-white rounded-lg shadow-lg relative cursor-pointer h-96">
                        <div class="h-28">
                            <p class="text-xs text-red-400 px-1" style="height:12%">Submitted @</p>
                            <div style="height:88%">
                                <h1 class="text-4xl font-bold lg:text-4xl 2xl:text-6xl text-gray-600">
                                    {{assignment.display_date.substring(0,2)}}
                                </h1>
                                <h1 class="text-base md:text-xl text-gray-600">
                                    {{assignment.display_date.substring(2)}}
                                </h1>
                            </div>
                        </div>
                        <div class="">
                            <h4 class="w-full text-base text-right capitalize py-1 pr-4 bg-gray-100 truncate rounded-md mt-0">{{assignment.user.display_name}}</h4>
                            <h1 class="text-sm xl:text-base text-gray-900 text-justify ">
                                {{assignment.title}}
                            </h1>
                            <p class="text-sm text-gray-500 py-2 mb-0 ">
                                {{assignment.student_description.slice(0,160)}}</p>
                        </div>
                        <button v-if="assignment.verify_status=='submitted'" class="absolute text-sm right-2 top-2 border px-1 rounded bg-sky-800 text-white">Submitted</button>
                        <button v-else-if="assignment.verify_status=='accepted'" class="absolute text-sm right-2 top-2 border px-1 rounded bg-teal-700 text-white"> Accepted</button>
                        <button v-else class="absolute text-sm right-2 top-2 border px-1 rounded bg-pink-600 text-white"> Rejected</button>
                    </div>
                </div>
                <div v-else class="h-full px-4">
                    <div class="flex flex-col ">
                        <h1 class="text-lg text-gray-600">No Assignments Submitted</h1>
                    </div>

                </div>
            </main>

            <!-- Details Popup -->
            <div v-if="is_detailsPopup" class="fixed  bg-black bg-opacity-75 w-full h-full top-0 right-0 flex flex-col justify-center" style="z-index:5000">
                <div class="h-auto w-full max-w-7xl bg-white p-6 overflow-y-auto rounded-md mx-auto" style="max-height:95%">
                    <div class="sticky top-0 float-right flex border rounded-lg px-1 hover:bg-gray-50 z-50" @click="is_detailsPopup=false,is_preview=false">
                        <button>Close</button>
                        <img class='h-5 w-5 md:h-7 md:w-7' src="../../assets/closebutton.svg" />
                    </div>
                    <!-- <h1> {{assignmentDetails}}</h1> -->
                    <h1 class="text-lg md:text-xl font-semibold">Assignment</h1>
                    <div class="py-4 flex flex-col gap-2 lg:gap-8">
                        <h1 class="text-base md:text-lg font-semibold capitalize">{{assignmentDetails.title}}</h1>
                        <p class="text-sm text-justify px-2">{{assignmentDetails.student_description}}</p>
                        <div class="flex flex-col-reverse gap-3">
                            <div class="flex gap-2">
                            <a :href="assignmentDetails.file" target="_blank">
                                <button class="gradient-btn">
                                Download
                                </button>
                                </a>
                            <button v-if="assignmentDetails.file.includes('.pdf')" @click="is_preview=!is_preview" class="gradient-btn" target="_blank">Preview</button>
                            </div>
                            <!-- <h1>{{assignmentDetails.file}}</h1> -->
                            <div v-if="assignmentDetails.verify_status=='submitted'" class="flex gap-4 ">
                                <div class="flex justify-arround gap-2 border rounded text-green-400 cursor-pointer hover:text-white hover:bg-green-700 w-auto py-1 px-2 items-center" @click="accept()">
                                    <font-awesome-icon class="text-sm  self-center " :icon="['fas','check-circle']" />
                                    <p class="text-sm hover:text-white hover:bg-green-700">Accept</p>
                                </div>
                                <div class="flex justify-arround gap-2 border rounded text-red-400 cursor-pointer hover:text-white hover:bg-red-600 w-auto py-1 px-2 items-center" @click="reject()">
                                    <font-awesome-icon class="text-sm self-center" :icon="['fas','times-circle']" />
                                    <p class="text-sm">Reject</p>
                                </div>
                            </div>
                            <div v-else>
                                <h1 class="text-base">Already validated</h1>
                                <p class="capitalize"> Status : <span :class="[assignmentDetails.verify_status=='rejected' ? 'text-red-500' : 'text-green-500']"> {{assignmentDetails.verify_status}} </span></p>
                            </div>
                        </div>
                    </div>
                    <div v-if="assignmentDetails.file.includes('.pdf') && is_preview" class="h-full mx-auto w-full">
                        <vue-pdf-embed :source="assignmentDetails.file" class="w-full" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import FlashMessage from "@/components/flashMessage.vue";
import VuePdfEmbed from 'vue-pdf-embed'
import axios from 'axios';
import {
    mapGetters
} from 'vuex'
export default {

    data() {
        return {
            assignment_list: "",
            is_detailsPopup: false,
            assignmentDetails: null,
            messages: {
                text: "",
                type: 'null',
            },
            is_preview: false
        }
    },

    components: {
        NavigationInstructor,
        FlashMessage,
        VuePdfEmbed
    },

    props: ["assignment_id", "course_name"],

    computed: {
        ...mapGetters(['userInfo'])
    },

    created() {

        this.getdetails();

    },

    methods: {

        accept() {

            let acceptsVal = {
                "user": this.assignmentDetails.user.id,
                "assignment_id": this.assignmentDetails.assignment_id,
                "title": this.assignmentDetails.title,
                "student_description": this.assignmentDetails.student_description,
                "submit_date": this.assignmentDetails.submit_date,
                "verify_status": 'accepted',
            }

            axios.put(`${process.env.VUE_APP_API}/student_assignment/updated/${this.assignmentDetails.id}/?Key=${this.userInfo.token_key}`, acceptsVal)
                .then(response => {
                    console.log(response)
                    if (response.data.message == "Student Assignment updated successfully") {
                        this.messages = {
                            text: "Assignment Accepted successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.is_detailsPopup = false
                this.getdetails();
            }, 1500)

        },

        reject() {

            let rejectedVal = {
                "user": this.assignmentDetails.user.id,
                "assignment_id": this.assignmentDetails.assignment_id,
                "title": this.assignmentDetails.title,
                "student_description": this.assignmentDetails.student_description,
                "submit_date": this.assignmentDetails.submit_date,
                "verify_status": 'rejected',
            }

            axios.put(`${process.env.VUE_APP_API}/student_assignment/updated/${this.assignmentDetails.id}/?Key=${this.userInfo.token_key}`, rejectedVal)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "Student Assignment updated successfully") {
                        this.messages = {
                            text: "Assignment Rejected",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.is_detailsPopup = false
                this.getdetails();
            }, 1500)
        },

        detailsPopup(value) {
            this.is_detailsPopup = true;
            this.assignmentDetails = value;
        },

        async getdetails() {
            await axios.get(`${process.env.VUE_APP_API}/assignment/list/view/?assignment_id=${this.assignment_id}&Key=${this.userInfo.token_key}`)
                .then(response => {
                    console.log(response);
                    if (response.data.message == "No details found") {
                        console.log("Null")
                        this.assignment_list = null
                    } else {
                        this.assignment_list = response.data

                        this.assignment_list.data.forEach(element => {
                            console.log(element.title)
                            element["display_date"] = new Date(element.submit_date).toLocaleDateString('en-IN', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })
                        })

                        this.assignment_list = this.assignment_list.data
                    }

                })
        },

    }

}
</script>

<style scoped>
p,
h1,
h2,
h3,
h4 {
    margin-bottom: 0px;
}
</style>
