<template>
    
  <div class="widthclass rounded-3xl bg-white shadow-lg pt-10 mt-3 ml-10">
    <div class="px-10 py-4 flex flex-col gap-10">
      <div class="flex flex-col gap-4">
        <router-link
          :to="{ name: 'InstructorStudents' }"
          class="cursor-pointer"
        
        >
          <div
            class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer"
            :class="{ activebg: actived == 'studentlist' }"
            @click="pushRoute('studentlist')"
          >
            <img
              src="@/assets/new/grid1.png"
              v-if="actived == 'studentlist'"
              alt=""
            />
            <img
              src="@/assets/new/grid2.png"
              v-if="actived != 'studentlist'"
              alt=""
            />
            <p
              class="font-bold text-sm pt-3 gray400"
              :class="{ 'text-white': actived == 'studentlist' }"
            >
              Learners
            </p>
          </div>
        </router-link>
        <router-link :to="{ name: 'InstructorMessage' }" class="cursor-pointer">
          <div
            class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer"
            :class="{ activebg: actived == 'messages' }"
            @click="pushRoute('messages')"
          >
            <img
              src="@/assets/new/arc2.png"
              v-if="actived == 'messages'"
              alt=""
            />
            <img
              src="@/assets/new/arc1.png"
              v-if="actived != 'messages'"
              alt=""
            />
            <p
              class="font-bold text-sm pt-3 gray400"
              :class="{ 'text-white': actived == 'messages' }"
            >
              Messages
            </p>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'InstructorAssignments' }"
          class="cursor-pointer"
        >
          <div
            class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer"
            :class="{ activebg: actived == 'assignments' }"
            @click="pushRoute('assignments')"
          >
            <img
              src="@/assets/new/notes2.png"
              v-if="actived == 'assignments'"
              alt=""
            />
            <img
              src="@/assets/new/notes1.png"
              v-if="actived != 'assignments'"
              alt=""
            />
            <p
              class="font-bold text-sm pt-3 gray400"
              :class="{ 'text-white': actived == 'assignments' }"
            >
              Assignments
            </p>
          </div>
        </router-link>
        <router-link :to="{ name: 'InstructorEvents' }">
          <div
            class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer"
            :class="{ activebg: actived == 'events' }"
            @click="pushRoute('events')"
          >
            <img
              src="@/assets/new/cal2.png"
              v-if="actived == 'events'"
              alt=""
            />
            <img
              src="@/assets/new/cal1.png"
              v-if="actived != 'events'"
              alt=""
            />
            <p
              class="font-bold text-sm pt-3 gray400"
              :class="{ 'text-white': actived == 'events' }"
            >
              Live Classes
            </p>
          </div>
        </router-link>
        <router-link :to="{ name: 'forum' }">
          <div
            class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer"
            :class="{ activebg: actived == 'forums' }"
            @click="pushRoute('forums')"
          >
            <img
              src="@/assets/new/mess2.png"
              v-if="actived == 'forums'"
              alt=""
            />
            <img
              src="@/assets/new/mess1.png"
              v-if="actived != 'forums'"
              alt=""
            />
            <p
              class="font-bold text-sm pt-3 gray400"
              :class="{ 'text-white': actived == 'forums' }"
            >
              Forums
            </p>
          </div>
        </router-link>
        <router-link :to="{ name: 'CourseQuizcreate' }">
          <div
            class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer"
            :class="{ activebg: actived == 'quiz' }"
            @click="pushRoute('studentQuiz')"
          >
            <img src="@/assets/new/bar2.png" v-if="actived == 'quiz'" alt="" />
            <img src="@/assets/new/bar1.png" v-if="actived != 'quiz'" alt="" />
            <p
              class="font-bold text-sm pt-3 gray400"
              :class="{ 'text-white': actived == 'quiz' }"
            >
              Quiz
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <!-- <div class="widthclass rounded-3xl bg-white shadow-lg pt-10 mt-3 ml-10"> 
        <div class="px-10 py-4 flex flex-col gap-10">  
            <div class="flex flex-col gap-4"> 
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='studentlist'}" @click="pushRoute('studentlist')"> 
                    <img src="@/assets/new/grid1.png" v-if="actived=='studentlist'" alt="">
                    <img src="@/assets/new/grid2.png" v-if="actived!='studentlist'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='studentlist'}">Courses</p>
                </div>
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='messages'}" @click="pushRoute('messageStudent')"> 
                    <img src="@/assets/new/arc2.png" v-if="actived=='messages'" alt="">
                    <img src="@/assets/new/arc1.png" v-if="actived!='messages'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='messages'}">Messages</p>
                </div>
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='assignment'}" @click="pushRoute('assignmentStudent')">
                    <img src="@/assets/new/notes2.png" v-if="actived=='assignment'" alt="">
                    <img src="@/assets/new/notes1.png" v-if="actived!='assignment'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='assignment'}">Assignments</p>
                </div>
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='events'}" @click="pushRoute('studentEvents')"> 
                    <img src="@/assets/new/cal2.png" v-if="actived=='events'" alt="">
                    <img src="@/assets/new/cal1.png" v-if="actived!='events'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='events'}">Live Classes</p>
                </div>
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='forums'}" @click="pushRoute('chatStudent')"> 
                    <img src="@/assets/new/mess2.png" v-if="actived=='forums'" alt="">
                    <img src="@/assets/new/mess1.png" v-if="actived!='forums'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='forums'}">Forums</p>
                </div>
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='quiz'}" @click="pushRoute('studentQuiz')"> 
                    <img src="@/assets/new/bar2.png" v-if="actived=='quiz'" alt="">
                    <img src="@/assets/new/bar1.png" v-if="actived!='quiz'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='quiz'}">Assessments</p>
                </div>
                <div class="flex items-center rounded-2xl px-4 py-3 gap-5 cursor-pointer" :class="{'activebg': actived=='Report'}" @click="pushRoute('Reportview')"> 
                    <img src="@/assets/new/cloud2.png" v-if="actived=='Report'" alt="">
                    <img src="@/assets/new/cloud1.png" v-if="actived!='Report'" alt="">
                    <p class="font-bold text-sm pt-3 gray400" :class="{'text-white': actived=='Report'}">Reports</p>
                </div>
            </div>

        </div>
    </div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  props: {
    actived: String,
  },
  // mounted() {
  //     var oppMenu = document.querySelector(".menu-icon");
  //     oppMenu.addEventListener("click", this.toggleClassMenu, false);
  // },
  methods: {
    pushRoute(name) {
      this.$router.push({ name: name });
    },

    // slideFn() {
    //     console.log("Slide")
    //     document.querySelector(".LeftArrow").classList.toggle("rotateimg");
    //     document.querySelector(".collapseText").classList.toggle("hidden");
    //     document.querySelector(".collapseDiv").classList.toggle("border-none");

    //     var largelayout = document.querySelector(".largerLayout")
    //     if (!largelayout.classList.contains("app-menu-close")) {
    //         largelayout.classList.add("app-menu-close");
    //     } else {
    //         largelayout.classList.remove("app-menu-close");
    //     }
    // },

    // toggleClassMenu() {
    //     console.log("toggle");
    //     var layout = document.querySelector(".layout");
    //     if (!layout.classList.contains("app-menu-open")) {
    //         layout.classList.add("app-menu-open");
    //     } else {
    //         layout.classList.remove("app-menu-open");
    //     }
    // }
  },
};
</script>

<style scoped>
.widthclass {
  width: 20% !important;
}

.activebg {
  background-color: #0085DB !important;
} 

.text-white{
    color: #fff !important;
}
.gray400{
    color: #333333;
}


</style>
