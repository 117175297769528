<template lang="">
    <div class="mainContentForInstructor flex bg-gray-50 ">
        <NavigationInstructor actived='messages' />
        <!-- <div class="absolute right-24 bottom-24 w-1/2 ">
        <img src="../../assets/chat.svg" alt="" class="w-full">
    </div> -->
        <div class="w-full h-full relative">
            <!-- <p class="w-full courseName sm:text-center lg:text-left py-1 px-2 text-gray-900 bg-gray-50 border-gray-300 text-base lg:text-lg xl:text-xl">{{course_name}}</p> -->
            <!-- Breadcrumb -->
            <!-- <div>
            <ol class="flex gap-2 items-center p-2 border-b ">
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to='{name :"sessionStudent"}'>
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Messages

                </li>
            </ol>
        </div> -->

            <div class="flex w-ful h-full px-9">
                <div class="chatContainer w-80 bg-white overflow-y-auto rounded-2xl shadow-lg">

                    <p class="px-10 py-3 mt-5 text-base text-center lg:text-md mb-0 font-semibold">Messages</p>

                    <div @click="Instructor_Chat(this.instructorChat)"
                        class="py-3 px-10 text-base lg:text-sm bg-white mb-0 hover:bg-gray-100 cursor-pointer flex">
                        <p class="w-5/6 px-2 mb-0">Instructor</p>
                    </div>

                    <main v-for="student in this.studentList.data" :keys="student" class="">
                        <p v-if="student[0].display_name" @click="messageInfo(student)"
                            class="py-3 px-10 text-base lg:text-sm bg-white mb-0 hover:bg-gray-100 cursor-pointer flex">
                            <span class="px-2 capitalize w-5/6">{{student[0].display_name}}</span>
                            <!-- <img src="../../assets/chatIcon.svg" class="h-6 w-6" /> -->
                        </p>
                    </main>
                </div>
                <div v-if="totalmessage && ChatpopUp==true" class="absolute lg:relative w-full lg:w-9/12 2xl:w-5/6">
                    <div class="shadow-lg bg-white msgContainer rounded-2xl" style="margin-top: 10px !important;">
                        <p class="headline text-gray-900 text-sm lg:text-base text-center rounded-2xl">
                            {{this.selectedStudent[0].display_name}}
                            <!-- <div id='addbutton' class='absolute select-none rounded-lg flex items-center bg-white cursor-pointer' @click="this.ChatpopUp=false;">
                            <div class="px-0.5 lg:px-1 text-xs sm:text-xs xl:text-base hover:text-red-500 rounded-full"></div><img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
                        </div> -->
                        </p>
                        <section ref="chatArea" class='chatArea chatStudent rounded-3xl w-full overflow-y-auto'>
                            <div v-for="message in totalmessage"
                                class="message bgchange rounded-3xl flex flex-col gap-1"
                                :class="[message[2] == this.userInfo.user.id ? 'senderMsg' : 'receiverMsg']">
                                <p class="text-sm lg:text-base mb-0 flex justify-between items-center">
                                    <span class='text-xs text-black'>{{ message[3]}}</span>
                                    <span class='text-xs pl-1 text-gray-600'>{{ message[1]}} </span>
                                </p>
                                <p class="text-xs md:text-xs  mb-0">{{ message[0] }} </p>
                            </div>
                            <!-- <div>
                            {{ message[3]}}
                        </div> -->
                        </section>
                        <main class="userInput w-full flex flex-col justify-center items-center  rounded-2xl">
                            <form @submit.prevent="sendmsg(this.selectedStudent)" class="w-full  rounded-2xl">
                                <div class="flex flex-col lg:flex-row items-start md:items-end p-3 gap-3">

                                    <textarea
                                        class="text-xs border-none lg:text-sm bg-white h-10 w-full lg:w-12/12 rounded-2xl outline-none hover:border-none"
                                        @keyup.enter="sendmsg(this.selectedStudent)" v-model='messageVal'
                                        placeholder="Type your text here..." />

                                    <button type="submit" class='primary text-white rounded-full'>
                                        <img src="@/assets/new/message.png" style="margin-bottom: 6px !important;" />
                                    </button>
                                </div>
                            </form>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
    import axios from "axios";
    import {
        mapGetters
    } from "vuex";
    import $ from "jquery";

    export default {
        data() {
            return {
                messageVal: null,
                totalmessage: null,
                studentList: "",
                selectedStudent: null,
                ChatpopUp: false,
                instructorChat: [{
                    id: "",
                    display_name: "",
                },],
            };
        },

        components: {
            NavigationInstructor,
        },

        props: ["course_id", "slug", "instructor_id", "course_name"],

        computed: {
            ...mapGetters(["userInfo"]),
        },

        async mounted() {
            console.log(this.course_id, this.slug)
            setInterval(function () {
                var headerHeight = $(".header[data-collapse]").outerHeight();
                var userInputHeight = $(".userInput").outerHeight();
                var headline = $(".headline").outerHeight();
                var remainHeight = headerHeight + userInputHeight + headline + 10;

                if ($(".mainContentForStudent").length > 0)
                    $(".mainContentForStudent").css(
                        "height",
                        "calc(100vh - " + $(".header[data-collapse]").outerHeight() + "px)"
                    );

                if ($(".chatStudent").length > 0)
                    $(".chatStudent").css("height", "calc(100vh - " + remainHeight + "px)");
            }, 0);

            await axios
                .get(`${process.env.VUE_APP_API}/coursesdata/${this.course_id}/`)
                .then((response) => {
                    this.instructorChat[0].id = response.data.user_id;
                });
            await axios
                .get(`${process.env.VUE_APP_API}/courses/?id=${parseInt(this.course_id)}`)
                .then((response) => {
                    this.instructorChat[0].display_name = response.data.data[0].instructor;
                });
            this.getstudentdetails();
            this.messageInfo();
        },

        methods: {
            async Instructor_Chat(data) {
                this.selectedStudent = data;
                this.ChatpopUp = true;
                await axios
                    .get(
                        `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent[0].id}/?Key=${this.userInfo.token_key}`
                    )
                    .then((Response) => {
                        this.totalmessage = Response.data.data.reverse();
                        console.log(this.totalmessage, "message");
                        console.table(this.totalmessage);
                    });

                $(".chatArea").animate({
                    scrollTop: $(".chatArea")[0].scrollHeight,
                },
                    1000
                );

                // let messageDisplay = this.$refs.chatArea;
                // messageDisplay.scrollTop = messageDisplay.scrollHeight;
            },

            async getstudentdetails() {
                await axios
                    .get(
                        `${process.env.VUE_APP_API}/all_list/${this.course_id}/?Key=${this.userInfo.token_key}`
                    )
                    .then((response) => {
                        response.data["data"] = [
                            ...new Map(
                                response.data.data.map((i) => i[0]).map((m) => [m.id, m])
                            ).values(),
                        ].map((i) => [i]);
                        this.studentList = response.data;
                    });
            },

            async messageInfo(data) {
                if (data) {
                    this.selectedStudent = data;
                    this.ChatpopUp = true;
                    await axios
                        .get(
                            `${process.env.VUE_APP_API}/one_to_one_messages/${this.userInfo.user.id}/${this.selectedStudent[0].id}/?Key=${this.userInfo.token_key}`
                        )
                        .then((Response) => {
                            this.totalmessage = Response.data.data.reverse();
                        });

                    $(".chatArea").animate({
                        scrollTop: $(".chatArea")[0].scrollHeight,
                    },
                        1000
                    );
                    // let messageDisplay = this.$refs.chatArea;
                    // messageDisplay.scrollTop = messageDisplay.scrollHeight;
                }
            },

            async sendmsg(data) {
                let messagesPass = {
                    text: this.messageVal,
                    owner: this.userInfo.user.id,
                    client: data[0].id,
                };

                await axios
                    .post(
                        `${process.env.VUE_APP_API}/talk/?Key=${this.userInfo.token_key}`,
                        messagesPass
                    )
                    .then((Response) => {
                        Response;
                    });

                this.messageInfo(this.selectedStudent);
                this.messageVal = null;

                $(".chatArea").animate({
                    scrollTop: $(".chatArea")[0].scrollHeight,
                },
                    1000
                );

                // let messageDisplay = this.$refs.chatArea;
                // messageDisplay.scrollTop = messageDisplay.scrollHeight;
            },

            clearAllMessages() {
                this.messages = [];
            },
        },
    };
</script>

<style scoped>
    .primary {
        background-color: #01B0F9 !important;
        width: 40px !important;
        height: 40px !important;
    }

    .msgContainer {
        margin: 5px 20px 0 0;
        z-index: 10;
    }

    .chatContainer {
        border-radius: 20px;
        margin: 5px 10px 0 0;
    }

    .chatList {
        position: sticky;
        top: 0;
        padding: 10px;
        background: #fff;
        z-index: 10;

    }

    textarea::placeholder {
        font-size: 14px;
    }

    .chatArea {
        position: relative;
        scrollbar-color: gray white;
        padding-bottom: 15px;
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        background-color: #aaddf326;
    }

    .headline {
        position: relative;
        padding: 10px;
        background: #fff;
        z-index: 10;
        margin-bottom: 0;
    }

    .message {
        width: fit-content;
        max-width: 70%;
        min-width: 17%;
        margin: 6px 25px;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .message p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;
    }

    .senderMsg {
        position: relative;
        align-self: end;
        background: #e9ffdb;
    }

    .receiverMsg {
        align-self: start;
        position: relative;
        background: #fff;
    }

    /* .senderMsg::before {
    content: "";
    position: absolute;
    top: 0px;
    right: -12px;
    border-top: 17px solid #e9ffdb;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    transform: rotate(-20deg);
} */

    /* .receiverMsg::before {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    border-top: 17px solid #fff;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    transform: rotate(20deg);
} */

    .chatArea::-webkit-scrollbar,
    .chatContainer::-webkit-scrollbar {
        width: 5px;
    }

    .chatArea:hover::-webkit-scrollbar-thumb,
    .chatContainer:hover::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4);
    }

    .chatArea::-webkit-scrollbar-thumb,
    .chatContainer::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }

    .chatArea::-webkit-scrollbar-track,
    .chatContainer::-webkit-scrollbar-track {
        background: transparent;
    }

    #addbutton {
        z-index: 1000;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    @media screen and (max-width: 480px) {
        textarea::placeholder {
            font-size: 12px;
        }

        .message {
            width: fit-content;
            max-width: 85%;
            min-width: 17%;
            margin: 5px 20px;
            padding: 10px 15px;
            border-radius: 5px;
        }

        .receiverMsg::before {
            content: "";
            position: absolute;
            top: 0;
            left: -10px;
            border-top: 15px solid #fff;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            transform: rotate(15deg);
        }

        .senderMsg::before {
            content: "";
            position: absolute;
            top: 0px;
            right: -10px;
            border-top: 15px solid #e9ffdb;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            transform: rotate(-15deg);
        }

        .msgContainer {
            margin: 5px 5px 0 5px;
        }

        .chatContainer {
            margin: 5px auto 0;
            width: calc(100% - 10px);
        }
    }

    @media screen and (min-width: 481px) and (max-width: 1023px) {
        .msgContainer {
            margin: 5px 5px 0 5px;
        }
    }

    .bgchange {
        background-color: #F2F4F7 !important;
        border-radius: 10px !important;
    }

    .mainContentForInstructor {
        height: 100vh;
    }
</style>