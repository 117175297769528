<template>
    <!-- <Navigation active="signup" /> -->
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="container flex flex-col-reverse md:flex-row items-center min-h-screen">
        <div class="w-full lg:w-4/5 h-72 md:h-96 lg:h-full overflow-hidden">
            <img src="../assets/new/login.png" alt="Login Image" class="max-w-full max-h-full object-contain">
        </div>
        <div class="lg:w-6/12 md:w-full  md:ml-auto w-full mt-2 md:mt-0 mx-auto contact-us px-5">
            <h2 class="title">Sign Up</h2>
            <form action="" @submit.prevent="SignUp" class="">
                <div class=" rounded-md shadow-sm flex flex-col gap-3 mb-4">
                    <div class="flex flex-col lg:flex-row gap-1 justify-between">

                        <div class="w-full">
                            <label for="first-name" class="py-2 text-xs md:text-base not-sr-only">First Name *</label>
                            <input ref="input" id="first_name" name="first_name" type="text" required
                                class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-700 focus:z-10 sm:text-sm"
                                placeholder="First Name">
                        </div>

                        <div class="w-full">
                            <label for="last-name" class=" py-2 text-xs md:text-base not-sr-only">Last Name * </label>
                            <input id="last-name" name="last_name" type="text" required
                                class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-700 focus:z-10 sm:text-sm"
                                placeholder="Last Name">
                        </div>
                    </div>
            

                    <div class="flex flex-col  items-center">
                        <div class="w-full">
                            <label for="Affiliation" class="py-2 text-xs md:text-base not-sr-only">Affiliation</label>
                            <input id="Affiliation" name="Affiliation" type="text"
                                class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-700 focus:z-10 sm:text-sm"
                                placeholder="Affiliation">
                        </div>
                    </div>
              

                    <div>
                        <label for="email-address" class=" py-2 text-xs md:text-base not-sr-only">Email address
                            *</label>
                        <input id="email-address" name="email" type="email" autocomplete="email" required
                            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-700 focus:z-10 sm:text-sm"
                            placeholder="Email address">
                    </div>
                
                    <div>
                        <div class="flex flex-col md:flex-row gap-1 justify-between relative">
                            <div class='w-full'>
                                <label for="password" class="py-2 text-xs md:text-base not-sr-only">Password *</label>
                                <div class='flex'>
                                    <input v-model="password_watch" id="password"
                                        @keyup="colorCode('coloralerts', 'password')" name="password" type="password"
                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                        autocomplete="current-password" required
                                        class=" appearance-none relative block w-full px-3 pr-8 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-700 focus:z-10 sm:text-sm"
                                        placeholder="Password">
                                    <img v-if="passwordPrimary == 'password'" src="../assets/eyeslash.svg"
                                        @click="hideShow('password')" alt="" class=" cursor-pointer inline-block z-30"
                                        style="margin-left: -30px; ">
                                    <img v-else src="../assets/eye.svg" @click="hideShow('password')" alt=""
                                        class=" cursor-pointer inline-block z-30" style="margin-left: -30px; ">
                                </div>
                            </div>

                            <div class="w-full ">
                                <label for="retype_password" class=" py-2 text-xs md:text-base not-sr-only">Retype
                                    Password *</label>
                                <div class='flex relative'>
                                    <input v-model="retype_password_watch" id="retype_password" name="retype_password"
                                        :class="[password_watch == retype_password_watch ? 'bg-gray-100' : 'bg-red-100']"
                                        type="password" autocomplete="current-password" required
                                        class="appearance-none pr-8 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-700 focus:z-10 sm:text-sm"
                                        placeholder="Retype Password">
                                    <img v-if="passwordConfirm == 'password'" src="../assets/eyeslash.svg"
                                        @click="hideShow('retype_password')" alt=""
                                        class=" cursor-pointer inline-block z-30" style="margin-left: -30px; ">
                                    <img v-else src="../assets/eye.svg" @click="hideShow('retype_password')" alt=""
                                        class=" cursor-pointer inline-block z-30" style="margin-left: -30px; ">
                                    <img id="exclamation" src="../assets/exclamationMark.svg"
                                        @click="clickss = !clickss" alt=""
                                        class="absolute -right-5 bottom-2 cursor-pointer inline-block z-30 "
                                        style="margin-right: 1px; ">
                                </div>
                            </div>
                            <div id='keysforpassword'
                                class='absolute hidden w-full bg-white z-50 border rounded-lg px-4 py-4 text-sm lg:text-base text-gray-600'>
                                <p> Must contain at least one number and one uppercase and lowercase letter, and at
                                    least 8 or more characters Your password can’t be too similar to your other personal
                                    information.</p>
                            </div>
                        </div>
                        <ul id='coloralerts' style="display:none;" class='mt-1'>
                            <div class=' flex gap-1 '>
                                <li class='w-3/12 cursor-pointer  border-b-4 inline-block border-red-600'></li>
                                <li class='w-3/12 cursor-pointer  border-b-4 border-red-600'></li>
                                <li class='w-3/12 cursor-pointer  border-b-4 border-red-600'></li>
                                <li class='w-3/12 cursor-pointer  border-b-4 border-red-600'></li>
                            </div>
                        </ul>
                    </div>
                </div>

                <button v-if="!loading" type="submit" class="w-full gradient-btn">Signup</button>
                <button v-else type="submit"
                    class=" mt-2  w-full text-white  bg-gradient-to-r from-cyan-500 to-blue-500  overflow-hidden border-0  focus:outline-none rounded text-md">
                    <img class="mx-auto  h-10" src="../assets/Pulse.gif" alt="">
                </button>
                <p v-if="msg" class="font-bold text-red-600 capitalize text-center">{{ msg }}</p>
            </form>
            <!-- <span class=" flex items-center justify-center space-x-2">
          <span class="h-px bg-gray-400 w-14"></span>
          <span class="font-normal ">or</span>
          <span class="h-px bg-gray-400 w-14"></span>
        </span> -->
            <!-- <div class="flex flex-row justify-center bg-gray-100" >
          <button type="button" class="group relative font-bold w-4/6 flex justify-center gap-3 items-center py-2 px-4 border border-transparent text-sm rounded-md text-gray-500 border-gray-400 focus:outline-none focus:ring-2 bg-gray-200 hover:bg-gray-100 ">
              <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/> Continue With Google
          </button>
        </div> -->

            <p class="text-sm text-gray-700 mt-3 items-center text-center">Already have an account?
                <router-link to="/login">Login</router-link>
            </p>
            <!-- <router-link to="/login">
        <button class="mt-12 w-full text-white border-0 bg-gradient-to-r from-red-500 via-yellow-500 to-yellow-500
                    hover:from-pink-600 hover:via-yellow-500 hover:to-yellow-500
                    py-2 px-8 focus:outline-none hover:bg-yellow-600 rounded text-lg">
            Login
        </button>
        </router-link> -->
        </div>
    </div>
    <div class="fixed top-0 h-screen w-full bg-black bg-opacity-70 flex justify-center items-center z-50"
        v-if="varificationPopup == false" style="z-index:1000">
        <div id="resendMail"
            class="relative w-full px-2 h-1/2 lg:w-1/2 lg:h-1/2 2xl:w-1/3 2xl:h-1/3 bg-white flex flex-col justify-center items-center gap-4 rounded text-base  md:text-xl">
            <p class="text-red-500">This Mail id already Signed up </p>
            <p>Mail still not verified please check your mail and Activate Your Account</p>
            <p>If you lost your Activation mail link please use <span class="text-teal-600">Resend</span> option</p>
            <button class="px-4 py-1 border bg-teal-500 text-white rounded hover:bg-teal-700"
                @click="resendActivationMail">Resend</button>
            <img src="../assets/closebutton.svg" class="w-8 h-8 absolute top-10 right-10 cursor-pointer" alt=""
                @click="varificationPopup = true" />
        </div>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import FlashMessage from "@/components/flashMessage.vue";
import axios from "axios";
import {
    mapActions
} from "vuex";
export default {
    components: {
        Navigation,
        FlashMessage,
    },
    data: () => ({
        email: "",
        password: "",
        info: "",
        msg: "",
        retype_password_watch: "",
        password_watch: "",
        loading: false,
        passwordConfirm: "password",
        passwordPrimary: "password",
        varificationPopup: true,
        messages: {
            text: "",
            type: 'null',
        },

    }),

    mounted() {
        this.$refs.input.focus()
        var PWD = document.getElementById("password");

        PWD.onfocus = function () {
            document.getElementById("coloralerts").style.display = "block";
        }

        PWD.onblur = function () {
            document.getElementById("coloralerts").style.display = "none";
        }

        document.getElementById("exclamation").addEventListener("mouseover", function () {
            document.getElementById("keysforpassword").style.display = "block";
        })
        document.getElementById("exclamation").addEventListener("mouseout", function () {
            document.getElementById("keysforpassword").style.display = "none";
        })

    },
    methods: {
        ...mapActions(["loginUser"]),
        msg_change() {
            this.msg = ""
        },
        resendActivationMail() {
            // console.log(document.querySelector("#email-address").value)
            this.varificationPopup = true
            let mailId = {
                email: document.querySelector("#email-address").value
            }
            axios.post(`${process.env.VUE_APP_API}/active_email_send/`, mailId)
                .then(response => {
                    // console.log(response.data.message)
                    if (response.data.message == "Email send") {

                        this.messages = {
                            text: "Mail Send successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                    }, 2500)

                })
                .catch(error => {
                    console.log(error)
                })

        },

        colorCode(data, inputclass) {
            document.getElementById(`${inputclass}`).value

            var PWD = document.getElementById(`${inputclass}`);
            var lowerCaseLetters = /[a-z]/g;
            var upperCaseLetters = /[A-Z]/g;
            var numbers = /[0-9]/g;
            document.getElementById(`${data}`).lastChild.childNodes.forEach(el => {
                el.style["border-color"] = 'red'
            })

            if ((PWD.value.match(lowerCaseLetters) || PWD.value.match(upperCaseLetters) || PWD.value.match(numbers) || PWD.value.length >= 8) && PWD.value.length != 0) {
                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'green'

                if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                    (PWD.value.match(upperCaseLetters) && PWD.value.length >= 8) ||
                    (PWD.value.match(numbers) && PWD.value.length >= 8)) {

                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'green'

                    if ((PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers)) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) ||
                        (PWD.value.match(lowerCaseLetters) && PWD.value.length >= 8 && PWD.value.match(upperCaseLetters)) ||
                        (PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8)

                    ) {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'green'
                        if (PWD.value.match(lowerCaseLetters) && PWD.value.match(upperCaseLetters) && PWD.value.match(numbers) && PWD.value.length >= 8) {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'green'
                        } else {
                            document.getElementById(`${data}`).lastChild.childNodes[3].style["border-color"] = 'red'
                        }
                    } else {
                        document.getElementById(`${data}`).lastChild.childNodes[2].style["border-color"] = 'red'

                    }
                } else {
                    document.getElementById(`${data}`).lastChild.childNodes[1].style["border-color"] = 'red'
                }
            } else {

                document.getElementById(`${data}`).lastChild.childNodes[0].style["border-color"] = 'red'
            }

        },

        hideShow(id) {
            const passwordField = document.querySelector(`#${id}`)
            passwordField.getAttribute('type') === 'password' ? passwordField.setAttribute('type', 'text') : passwordField.setAttribute('type', 'password')
            switch (id) {
                case "password":
                    this.passwordPrimary = passwordField.getAttribute('type')
                    break;
                case "retype_password":
                    this.passwordConfirm = passwordField.getAttribute('type')
                    break;
            }
        },

        SignUp: async function (submitEvent) {

            let is_activation
            var emailID = submitEvent.target.elements.email.value;
            var passWord = submitEvent.target.elements.password.value;
            var passWordRetype = submitEvent.target.elements.retype_password.value;
            var first_name = submitEvent.target.elements.first_name.value;
            var last_name = submitEvent.target.elements.last_name.value;

            let mailId = {
                email: emailID.toLowerCase()
            }
            // console.log(mailId)
            if (passWord == passWordRetype) {
                await axios.post(`${process.env.VUE_APP_API}/email_verification/`, mailId)
                    .then(response => {
                        if (response.data.message == "Email already verified") {
                            is_activation = true
                            // console.log("Email already verified")
                        } else if (response.data.message == "Invalid Email credentials") {
                            // console.log(" invalid Email credentials") 
                            is_activation = true

                        } else {
                            this.varificationPopup = false
                            is_activation = false
                            this.loading = false;
                            //    console.log("Email not verified")

                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else {
                this.msg = "Password mismatched!!!"
                setTimeout(this.msg_change, 3000)
                this.loading = false

            }
            if (is_activation) {
                this.loading = true

                var dataSignUp = {}
                dataSignUp['email'] = emailID.toLowerCase(),
                    dataSignUp['password'] = passWord,
                    dataSignUp['first_name'] = first_name,
                    dataSignUp['is_active'] = false,
                    dataSignUp['last_name'] = last_name

                if (passWord == passWordRetype) {

                    axios.post(`${process.env.VUE_APP_API}/register`, dataSignUp)
                        .then(async response => {
                            let data = {
                                username: emailID.toLowerCase(),
                                password: passWord,
                            }
                            await this.loginUser(data);
                            this.loading = false
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'Thanks'
                                });
                            }, 1000)

                        })
                        .catch(error => {
                            // console.log(error.response.data.email[0])
                            this.msg = error.response.data.email[0]
                            setTimeout(this.msg_change, 3000)
                            this.loading = false
                        })
                } else {
                    this.msg = "Password mismatched!!!"
                    setTimeout(this.msg_change, 3000)
                    this.loading = false

                }
            } else {
                console.log("MailID have a issue")
            }
        }

    }
}
</script>

<style></style>
