<template lang="">
<div class="flex">
    <NavigationInstructor actived="quiz" />
    <div class="w-full relative px-10 py-10">
        <h1 class="w-full py-3 font-semibold px-2 text-gray-900 bg-white border-gray-300 text-xl 2xl:text-3xl">{{course_name}}</h1>

        <!-- Breadcrumb -->
       
        <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'super_admin'" class="text-gray-500 text-sm">
                    <router-link :to='{name :"organizationCourse",params:{slug_id:this.userInfo.slug_id}}'>
                        Dashboard
                    </router-link> >
                </li>
                <li v-else class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name :'InstructorStudents'}">
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Quiz

                </li>
            </ol>
        </div> -->

        <header class="bg-gray-100 w-full mx-auto">
            <div class="w-5/6 px-4 py-2 flex gap-2">
                <button class="border bg-white px-2 py-.5 rounded" @click="quizgetinfo()" :class="[this.is_selected=='list' ? 'border border-teal-500' : 'border border-gray-200']">Quiz list</button>
                
                <button class="border bg-white px-2 py-.5 rounded" @click="this.is_selected='add'" :class="[this.is_selected=='add' ? 'border border-teal-500' : 'border border-gray-200']">Add Quiz</button>
                <!-- <button v-if="this.generalquiz.length==0" class="border bg-white px-2 py-.5 rounded" @click="this.is_selected='add'" :class="[this.is_selected=='add' ? 'border border-teal-500' : 'border border-gray-200']">Quiz Add</button> -->
                
            </div>
        </header>

        <!-- Quiz List -->
        <div v-if="this.is_selected=='list'" class="relative bg-white w-full max-w-6xl p-0 md:p-8 overflow-y-auto" style="scrollbar-color:#E5E7EB white;height:92vh">
            <div class="flex flex-col  items-center h-full w-full">
                <div v-for="quiz in this.generalquiz" :key="quiz" class=" flex flex-col bg-white w-5/6 h-36 m-1 cursor-pointer relative border-b">
                    <div class="flex flex-col md:flex-row gap-2 items-center p-1 h-40 w-full">
                        <img v-if=" quiz.quiz.image" class="object-cover object-center h-24 w-52" :src="quiz.quiz.image" alt="" />
                        <img v-else class="object-cover object-center bg-teal-400 h-24 w-52" src="../../assets/coding.svg" alt="" />
                        <div class="flex justify-between items-center w-5/6 ">
                            <div class="w-full h-24 overflow-hidden">
                                <h1 class="text-gray-700 text-lg font-bold ">{{quiz.quiz.name}}</h1>
                                <h3 class="text-left text-sm px-1 py-1 text-blue-600 font-medium">
                                    {{quiz.quiz.coursequizquestion_set.length}} Questions
                                </h3>
                                <p class='text-sm px-1 '>{{quiz.quiz.description}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="absolute top-2 right-2 flex gap-3">
                        <!-- <button class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-green-100 ">
                            <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit quiz" />
                            Edit section
                        </button> -->
                        <button class="border border-blue-500 bg-white px-6  rounded" @click="quizgetscoreinfo(quiz.quiz.name)" :class="[this.is_selected=='score' ? 'border border-teal-500' : 'border border-gray-200']">Scoreboard</button>
                        <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100" @click="titlepass=quiz.quiz.name;popupwindow=true;" >
                            <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                            Delete
                        </button>
                        
                        <div v-if="quiz.quiz.qualify_certificate ==true">
                            <label class="text-left text-sm px-5 py-1 text-green-500 font-medium"> 
                            <input type="checkbox"  @click="quiz_published(quiz.quiz.name, false)" :checked="true" > Published</label>

                        </div>
                        <div v-else>
                            
                            <label class="text-left text-sm px-1 py-1 text-red-500 font-medium">
                            <input type="checkbox"  @click="quiz_published(quiz.quiz.name, true)" :checked="false"> Unpublished</label>
                        </div>
                    </div>
                    
                </div>
            </div>
            {{this.is_selected}}
            
        </div>
        <div v-if="this.is_selected=='score'" class="relative bg-white w-full max-w-6xl p-0 md:p-8 overflow-y-auto" style="scrollbar-color:#E5E7EB white;height:92vh">
        <div class="mx-9 justify-center mb-8 flex flex-row flex-wrap gap-2 text-end">
            <div class="md:w-1/4 xl:w-1/4 flex-shrink-1 flex flex-col border-2 border-gray-200 bg-white p-3 rounded-md text-center">
              <p class="text-base font-semibold text-xs whitespace-nowrap">Learners visited </p>
              <p class="text-base text-neutral-600 font-bold py-2">{{scoredetails.count}}</p>
            </div>
            <div class="md:w-1/4 xl:w-1/4 flex flex-col border-2 border-gray-200 bg-white p-3 rounded-md text-center">
              <p class="text-base text-neutral-600 font-medium whitespace-nowrap">Learners appeared</p>
              <p class="text-base text-blue-800 font-bold"> {{scoredetails.completed_count}}</p>
            </div>
            <div class="md:w-1/4 xl:w-1/4 flex flex-col border-2 border-gray-200 bg-white p-3 rounded-md text-center">
              <p class="text-base text-neutral-600 font-medium whitespace-nowrap">Learners not attended </p>
              <p class="text-base text-blue-800 font-bold"> {{scoredetails.not_completed_count}}</p>
            </div>
            <div @click="quizgetinfo()" class="self-center mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white" data-v-fa837450="">
                <div class="text-sm text-gray-700 hover:text-teal-500" data-v-fa837450="">Back </div><div class="flex items-center" data-v-fa837450="">
                    <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png" data-v-fa837450=""></div>
                    </div>
                    <div @click="quizget(scoredetails.name)" class="self-center mb-1 cursor-pointer rounded-lg flex border justify-center w-12 gap-2 items-center py-1 bg-white hover:bg-green-100" data-v-fa837450="">
                        <div class="flex items-center " data-v-fa837450="">
                            <!-- Download report -->
                            <img class="object-cover object-center h-6 w-6 " src="../../assets/download.png" data-v-fa837450="" data-bs-toggle="tooltip" title="Download"></div>
                            </div>                    
          </div>
        
            <div class="shadow-xl rounded-md w-full overflow-y-auto " style="scrollbar-color:gray #e5e7eb">
                <table class="w-full ">
                    
                    <thead>
                        <tr>
                            <th class="px-2 lg:px-5 py-2 lg:py-4 items-center border-b-2 border-gray-500 bg-gray-200 text-center text-gray-600  tracking-wider">
                                <p class="text-base font-semibold text-xs whitespace-nowrap">S.No</p>
                                
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600  tracking-wider">
                                <p class="text-base font-semibold text-xs whitespace-nowrap">Email</p>
                                
                            </th>
                            <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold  text-gray-600  tracking-wider">
                                <p class="text-base font-semibold text-xs whitespace-nowrap">Learner</p>
                                
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 tracking-wider">
                                <p class="text-base font-semibold text-xs whitespace-nowrap">Score</p>
                                
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600  tracking-wider">
                                <p class="text-base font-semibold text-xs whitespace-nowrap">Status</p>
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="scoredetails.data">
                        <tr v-for="el,index in scoredetails.data" :key="el">
                            
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border-b border-gray-200 bg-white text-xs lg:text-sm">
                                <div class="flex items-center">
                                    <div class="ml-3">
                                        <p class="text-gray-900 whitespace-no-wrap ">
                                            {{ index + 1 }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p v-if="el.user.email" class="text-center text-gray-900 whitespace-no-wrap">
                                    {{el.user.email}}
                                </p>
                                <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                {{el.user.display_name}}
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p v-if="el.score" class="text-center text-gray-900 whitespace-no-wrap">
                                    {{el.score}}
                                </p>
                                <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                    -
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p v-if="el.completed" class="text-center text-gray-900 whitespace-no-wrap">
                                    Completed
                                </p>
                                <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                    Not Attended 
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                empty
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                empty
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                empty
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>

        

        <!-- Quiz Add -->
        <div v-if="this.is_selected=='add'">
            <Quiz :course_id="this.course_id" />
        </div>
        <!-- Quiz Add -->



        <div v-if="this.quiz_id != 0" class="bg-black">
            <QuizPreview :course_id='this.course_id' :quiz_id="this.quiz_id" @popupClose="this.quiz_id=0" />
        </div>

    </div>
</div>

<confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @click="QuizDeleted(titlepass)" />
<FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
</template>

<script>
import axios from "axios"
import Quiz from "@/components/Quiz.vue"
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import QuizPreview from "@/components/quizpreview.vue"
import confirmPopup from "@/components/confirmPopup.vue";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            not_attended: 0,
            current_coursename:'',
            scoredetails:'',
            published: '',
            popupwindow: false,
            titlepass: "",
            is_selected: 'list',
            quiz_id: 0,
            generalquiz: [],
            messages: {
                text: "",
                type: 'null',
            },            
        }
    },
    components: {
        Quiz,
        NavigationInstructor,
        QuizPreview,
        confirmPopup,
        FlashMessage,
    },
    props: ["course_id", "course_name"],
    computed: {
        ...mapGetters(["userInfo"]),

    },
    created() {
        console.log(this.course_name)
        this.quizgetinfo()
    },

  watch: {

  },    

    methods: {
        async quizgetinfo() {
            this.is_selected = 'list'
            // console.log(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
            await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if(response.data.data){
                        this.generalquiz = response.data.data
                        this.published = this.generalquiz
                        console.log(this.published)
                        // console.log("this.generalquiz::",this.generalquiz[0].quiz.qualify_certificate)
                    }

                })
        },

        async quizget(course_name) {
            // console.log("dataaaaaaaaaaaaaaaa",`${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${this.current_coursename}/?Key=${this.userInfo.token_key}`)
            await axios.get(`${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${this.current_coursename}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    const link = document.createElement('a')
                    link.href = `${process.env.VUE_APP_API}/coursequizdownloadattemptcount/${this.course_id}/${this.current_coursename}/?Key=${this.userInfo.token_key}`
                    link.setAttribute('download', 'filetest.xlsx')
                    document.body.appendChild(link)
                    link.click()             
                    // console.log("aftorlink::",link)

                })
        },



        async quiz_published(coursequizname, status) {
        console.log("before::",this.published)
        this.published = status
        console.log("before coursequizname::",coursequizname)
        let that = this
        let messagesPass = {
            "qualify_certificate" : status,
            "name" : coursequizname
        };        
        console.log(`${process.env.VUE_APP_API}/coursequizpublished/${this.course_id}/?Key=${this.userInfo.token_key}`,messagesPass)
        await axios.put(`${process.env.VUE_APP_API}/coursequizpublished/${this.course_id}/?Key=${this.userInfo.token_key}`,messagesPass)
        .then(response => {
            this.publishedquiz = response.data
            console.log("this.publishedquiz:::",this.publishedquiz)
            if(response.data.message == "This published update successfully"){
                this.quizgetinfo()
                console.log("this.published",that.published)
                if(that.published==true){
                    
                    // this.generalquiz = []
                        this.messages = {
                            text: "course quiz published successfully",
                            type: 'success',
                        }
                }
                else{
                    // this.generalquiz = []
                        this.messages = {
                            text: "course quiz Unpublished successfully",
                            type: 'error',
                        }                    

                }

                    this.popupwindow = false
                } 
        else {
                    this.messages = {
                        text: "Quiz name, description, total score and min score filed check Faild",
                        type: 'error',
                    }
                }
            })
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                
            }, 1500)

        },
        
        

        async quizgetscoreinfo(coursename) {
            this.is_selected = 'score'
            this.current_coursename = coursename
            console.log(`${process.env.VUE_APP_API}/coursequizattemptcount/${this.course_id}/${coursename}/?Key=${this.userInfo.token_key}`)
            await axios.get(`${process.env.VUE_APP_API}/coursequizattemptcount/${this.course_id}/${coursename}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    if(response.data.data){
                        this.scoredetails = response.data
                        console.log("this.generalquiz",this.scoredetails)
                        // this.published = this.generalquiz[0].quiz.qualify_certificate
                        // console.log("this.generalquiz::",this.generalquiz[0].quiz.qualify_certificate)
                    }

                })
        },        
        QuizDeleted(titlepass){
            
            // console.log(this.quizCourse)
            let messagesPass = {
            name: titlepass
            };          
            axios.put(`${process.env.VUE_APP_API}/coursequizdeleted/${this.course_id}/?Key=${this.userInfo.token_key}`,messagesPass)
            .then(response => {
                if(response.data.message == "This data deleted successfully"){
                    this.generalquiz = []
                    this.messages = {
                        text: "course Quiz Deleted successfully",
                        type: 'success',
                    }
                    this.popupwindow = false
                } else {
                    this.messages = {
                        text: " Event Faild",
                        type: 'error',
                    }
                }
                setTimeout(() => {
                this.$router.go(0);
                this.messages = {
                    text: " ",
                    type: 'null',
                }
            }, 2000)
            })
        },        


    }
}
</script>

<style scoped>

</style>
