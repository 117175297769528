<template lang="">
<div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="w-11/12 2xl:w-4/6 mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg">
        <div class="w-full lg:p-4 mx-auto lg:w-10/12">
            <div @click="$router.go(-1)" class="absolute right-8 top-8">
                <div class="mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white">
                    <div class=" text-sm  text-gray-700 hover:text-teal-500" title='back'>
                        back</div>
                    <div class="flex items-center">

                        <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png" />
                    </div>
                </div>
            </div>
            <div class="text-left w-4/5 py-2 text-3xl font-semibold text-gray-700">
                Course Update
            </div>
            <form @submit.prevent="updateDetails">
                <div class="parent flex flex-col gap-5">
                    <div class="flex flex-col gap-2">
                        <label for="title">
                            Title <span class="text-red-400">*</span>
                        </label>
                        <input v-model="courseInfo.title" type="text" id="title" required class="border border-gray-300 rounded-md w-full p-2 text-sm">
                    </div>
                    <div class="flex flex-col md:flex-row gap-5">
                        <div class="w-full md:w-1/2 flex flex-col gap-2">
                            <label for="category">
                                Category<span class="text-red-400">*</span>
                            </label>
                            <select name="category" class="rounded-md border border-gray-300 bg-gray-100 p-2 block w-full text-sm" id="category" required>
                                <option v-for="category in categories" :key="category" :value="category.id">
                                    {{category.title}}
                                </option>
                            </select>
                        </div>
                        <div class="w-full md:w-1/2 flex flex-col gap-2">
                            <label for="level">
                                Level<span class="text-red-400">*</span>
                            </label>
                            <select name="level" class="rounded-md border border-gray-300 bg-gray-100 p-2 block w-full text-sm" id="level " required>
                                <option v-for="level in ['beginner', 'intermediate', 'advanced']" :key="level" :value="level">
                                    {{level}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row gap-5 flex-wrap justify-between">
                        <div class="w-full md:w-3/12 flex flex-col gap-2">
                            <label for="language">
                                Language
                            </label>
                            <input id="language" v-model="courseInfo.language" type="text" class="border border-gray-300 rounded-md w-full p-2 text-sm">
                        </div>
                        <div class="w-full md:w-3/12 flex flex-col gap-2">
                            <label for="price">
                                Price<span class="text-red-400">*</span>
                            </label>
                            <input id="price" v-model="courseInfo.price" type="text" required class="border border-gray-300 rounded-md w-full p-2 text-sm">
                        </div>
                        <div class="w-full md:w-5/12 flex flex-col gap-2">
                            <label for="videoUrl">
                                Video URL
                            </label>
                            <input id="videoUrl" v-model="courseInfo.video_url" type="text" class="border border-gray-300 rounded-md w-full p-2 text-sm">
                        </div>
                    </div>

                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="shortDesc">
                            Short Description<span class="text-red-400">*</span>
                        </label>

                        <textarea id="shortDesc" v-model="courseInfo.short_description" required class="border border-gray-300 rounded-md w-full p-2 text-sm"></textarea>
                    </div>
                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="description">
                            Description<span class="text-red-400">*</span>
                        </label>
                        <MarkdownEditor class="rounded-md" :markvalues="courseInfo.description" />
                    </div>
                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="outcome">
                            Outcome
                        </label>
                        <textarea id="outcome" v-model="courseInfo.outcome" maxlength="200" class="border border-gray-300 rounded-md w-full p-2 text-sm"></textarea>
                    </div>
                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="requirement">
                            Requirement
                        </label>
                        <textarea id="requirement" v-model="courseInfo.requirements" maxlength="200" class="border border-gray-300 rounded-md w-full p-2 text-sm"></textarea>
                    </div>
                    <div class="flex items-center gap-2">
                        <label for="freeCourse">
                            Free Course
                        </label>
                        <input id="freeCourse" type="checkbox" v-model="courseInfo.course_free" class="">
                    </div>
                    <div class="flex items-center gap-2">
                        <label for='checkbox'>Published</label>
                        <input type="checkbox" id="Published" v-model="courseInfo.is_published">
                    </div>
                </div>
                <button type="submit" class="gradient-btn mt-4">
                    Update course
                </button>
            </form>
        </div>
    </div>

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import {
    mapGetters
} from 'vuex';
export default {
    data() {
        return {
            courseInfo: '',
            categories: '',
            messages: {
                text: "",
                type: 'null',
            },
        }
    },
    components: {
        Navigation,
        FlashMessage,
        MarkdownEditor,
    },
    props: ['course_id'],
    computed: mapGetters(["userInfo"]),
    created() {
        axios.get(`${process.env.VUE_APP_API}/courses/?id=${this.course_id}`)
            .then(response => {
                this.courseInfo = response.data.data[0]
            })

        axios.get(`${process.env.VUE_APP_API}/category/?format=json`).then(res => {
            this.categories = res.data.data
        })
    },

    methods: {
        async updateDetails(el) {

            let instructor_details
            await axios.get(`${process.env.VUE_APP_API}/coursesdata/${this.courseInfo.id}/`)
                .then(Response => {
                    instructor_details = Response.data
                })
            let updatedCourseInfo = {
                "instructor": instructor_details.instructor_id,
                "uid": this.courseInfo.uid,
                "title": el.target.elements.title.value,
                "slug": this.courseInfo.slug,
                "short_description": el.target.elements.shortDesc.value,
                "description": el.target.elements[7].value,
                "outcome": el.target.elements.outcome.value,
                "requirements": el.target.elements.requirement.value,
                "language": el.target.elements.language.value,
                "price": el.target.elements.price.value,
                "course_free": el.target.elements.freeCourse.checked,
                "level": el.target.elements.level.value,
                "video_url": el.target.elements.videoUrl.value,
                "is_published": el.target.elements.Published.checked,
                "created_at": this.courseInfo.created_at,
                "updated_at": this.courseInfo.updated_at,
                "category": parseInt(el.target.elements.category.value),
                "organization_id": this.userInfo.organizes_id
            }

            await axios.put(`${process.env.VUE_APP_API}/courseupdate/${this.courseInfo.id}/?Key=${this.userInfo.token_key}`, updatedCourseInfo)
                .then(response => {

                    this.res = response
                    if (this.res.data.message) {

                        this.messages = {
                            text: "Course Updated successfully",
                            type: 'success',
                        }
                        console.log("successful")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                        console.log("not successful")
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }

                this.$router.push({
                    name: 'InstructorCourse'
                })

            }, 2500)
        },
    }

}
</script>

<style lang="">
    
</style>
